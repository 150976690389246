import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//consultar regimen
export const axiosGetRegimes = async () => {
  let url = `${API_HOST}/api/tools/regimes`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = axios.get(url, headersConfig);

  return response;
};
