
import { createTheme  } from "@mui/material/styles";
import { esES } from '@mui/material/locale';
import "./styles/Inputs.scss";

export const theme = createTheme({
    palette: {
      primary: {
        light: '#4f6282',
        main: '#233B63',
        dark: '#182945',
        contrastText: '#fff',
  
      },
      secondary: {
        light: '#365d9e',
        main: '#043586',
        dark: '#02255d',
        contrastText: '#fff',
      },
    },
  },
  esES,
  
  );
  

