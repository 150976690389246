import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//Consultar timbres disponibles
export const axiosGetBells = async () => {
  let url = `${API_HOST}/api/store/bells`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios
    .get(url, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Comprar timbres con orden de oxxo
export const axiosBuyBellsOxxo = async (name, price) => {
  let url = `${API_HOST}/api/store/buy-bells-oxxo`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let order = {
    name: name,
    cost: price,
  };
  let response = await axios.post(url, order, headersConfig);
  return response;
};
//comprar timbres con tarjeta
export const axiosBuyBellsCard = async (name, price, idCard) => {
  let url = `${API_HOST}/api/store/buy-bells-card`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let order = {
    name: name,
    cost: price,
    id: idCard,
  };
  let response = await axios.post(url, order, headersConfig);
  return response;
};
