import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Card,
  CardHeader,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ListPaymentsShopPayments from "../Payments/ListPaymentsShopPayments";

import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  axiosBuyBellsCard,
  axiosBuyBellsOxxo,
  axiosGetBells,
} from "../../../api/axiosShop";
import { toast } from "react-toastify";
import DialogOxxoPay from "../../../layouts/DialogOxxoPay";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogBuyBells = ({
  handlerShopBellsModal,
  openShopBellModal,
  handleInfo,
}) => {
  const [selectedPayment, setSelectedPayment] = useState("e");
  const [bells, setBells] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);
  const [openDialogOxxo, setOpenDialogOxxo] = useState(false);

  const [orderBells, setOrderBells] = useState({
    nameTimbres: "Falta seleccionar un paquete",
    price: "",
    price_conekta: "",
  });

  const [orderOxxo, setOrderOxxo] = useState({
    name: "",
    oxxo_price: "",
    reference: "",
    code: "",
  });

  useEffect(() => {
    axiosGetBells()
      .then((response) => {
        setBells(response.data.data);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  useEffect(() => {
    if (orderBells.price_conekta) {
      const Orders = bells.filter((bell) => {
        if (orderBells.price_conekta == bell.conekta_price) {
          return bell.name;
        }
      });
      setOrderBells({
        ...orderBells,
        nameTimbres: Orders[0]?.name,
        price: Orders[0]?.price,
      });
    }
  }, [orderBells.price_conekta]);

  const handlePayBells = () => {
    if (!orderBells.price_conekta) {
      toast.warning(" Favor de seleccionar un paquete de tiembres.");
    } else if (selectedPayment == "oxxo") {
      setOrderLoading(true);
      axiosBuyBellsOxxo(orderBells.nameTimbres, orderBells.price_conekta)
        .then((response) => {
          if (response.data.status === false) {
            toast.warning(response.data.message);
          } else {
            let { data } = response;
            let { order } = data;
            setOrderOxxo({
              ...orderOxxo,
              name: orderBells.nameTimbres,
              oxxo_price: orderBells.price,
              reference: order.charges.data[0].payment_method.reference,
              code: order.charges.data[0].payment_method.barcode_url,
            });

            setOpenDialogOxxo(true);
            toast.success("Orden generada con exito.");
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.warning(" No se ha iniciado una sesión o registro.");
          } else {
            toast.error(
              " Error en el servidor, inténtelo nuevamente más tarde."
            );
          }
        })
        .finally(() => {
          setOrderLoading(false);
        });
    } else if (selectedPayment === "e") {
      toast.warning("Favor de seleccionar un metodo de pago.");
    } else {
      setOrderLoading(true);
      axiosBuyBellsCard(
        orderBells.nameTimbres,
        orderBells.price_conekta,
        selectedPayment
      )
        .then((response) => {
          if (response.data.status === false) {
            toast.warning(response.data.message);
          } else {
            if (response.data.data.payment_status === "paid") {
              handleInfo();
            }
            toast.success("Orden generada con exito.");
            setTimeout(() => {
              handlerShopBellsModal();
            }, 10);
          }
        })
        .catch((err) => {
          toast.error("Error en el servidor, inténtelo nuevamente más tarde.");
        })
        .finally(() => {
          setOrderLoading(false);
        });
    }
  };

  const onChange = (e) => {
    setOrderBells({ ...orderBells, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      open={openShopBellModal}
      onClose={handlerShopBellsModal}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handlerShopBellsModal}
      >
        Timbres
      </BootstrapDialogTitle>
      <DialogContent sx={{ mr: 3, ml: 3 }}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              align="center"
            >
              Adquirir Paquete de Timbres
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined" sx={{ p: 2, bgcolor: "rgba(0,0,0,.03)" }}>
              <CardHeader title={"Selecciona el Paquete"} />
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    id="filled-select-currency"
                    select
                    label="Selecciona Un Paquete de timbres"
                    value={orderBells.price_conekta ?? ""}
                    onChange={onChange}
                    name="price_conekta"
                    variant="filled"
                    fullWidth
                    required
                    size="small"
                  >
                    {bells.map((bell) => (
                      <MenuItem key={bell.id} value={bell.conekta_price}>
                        <span>{bell.name}</span>
                        (${bell.price})
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    mt={2}
                  >
                    Facturalite está obligado por ley a recaudar los impuestos
                    de las transacciones de las compras realizadas en
                    determinadas jurisdicciones fiscales.
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined" sx={{ p: 2, bgcolor: "rgba(0,0,0,.03)" }}>
              <CardHeader title={" Selecciona el metodo de pago"} />
              <Grid container>
                <Grid item xs={12}>
                  <ListPaymentsShopPayments
                    selectedPayment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handlePayBells();
              }}
              disabled={orderBells.price === "" || selectedPayment === "e"}
            >
              {!orderLoading ? (
                "Comprar Timbres"
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogOxxoPay
        setOpen={setOpenDialogOxxo}
        open={openDialogOxxo}
        order={orderOxxo}
      />
    </Dialog>
  );
};

export default DialogBuyBells;
