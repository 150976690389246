import React, {useContext} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Button,
  Grid,
  Typography,
  Link
} from '@mui/material';
import { oxxoLogo } from "../img/logoBase64";
import { useStyles } from "./DialogOxxoStyle";
import { generateOxxoPDF } from "../utils/scriptsPDF/oxxoPDF";
import { AuthContext } from '../auth/AuthContext';
import { isUserLogedApi } from '../api/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogOxxoPay = ({ setOpen, open, order, redirect = null, handleRegister = null }) => {
  const { setToken } = useContext(AuthContext);

  const handleClose = (regresar) => {
    setOpen(false);
    if (redirect) {

      // navigate('/home');
      window.location = '/slopes';

    }

    if (handleRegister && regresar !== true) {
      setToken(isUserLogedApi());
      // navigate('/slopes');
      //window.location = '/slopes';

    }
  };

  const handleoxxoPDF = () => {
    var price = order.oxxo_price;
    var reference = order.reference;
    var pack = order.name;
    var code = order.code;
    generateOxxoPDF(oxxoLogo, price, reference, pack, code);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" align='center' className={classes.header}>{"Orden de oxxo"}</DialogTitle>
        <DialogContent id="alert-dialog-slide-description">


          <Grid item xs={12} sm={12} md={12} xl={12} className={classes.caption} align="center">
            <img src={oxxoLogo} alt="OXXO" width="50%" />
            <Typography variant="body1" gutterBottom >
              Realiza tu pago y disfruta de todas las ventajas de tener
              a tu contador ideas, estés donde estés.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12} align="center">
            <Typography variant="h4" gutterBottom align="center">
              REFERENCIA DE PAGO
            </Typography>
            <div className={classes.inputoxxo}>
              <Typography variant="h6" align="center">{order.reference}</Typography>
            </div>
            <Typography variant="h6" gutterBottom align="center">
              {order.name}
              <br />
              Total:
              <strong> ${order.oxxo_price}</strong>
            </Typography>

            <img src={order.code} alt="Code_Oxxo" width="30%" />


          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <div className={classes.inputoxxo}>
              <Typography variant="h6" align="center">
                Instrucciones
              </Typography>

              <Typography variant="body2" align='justify'>
                1.- Acude a la sucursal de OXXO más cercana.
                <Link href="https://www.google.com.mx/maps/search/oxxo/" color="secondary" >Encuéntrala aquí.</Link><br />
                2.- Indica en caja que quieres realizar un pago por OXXOPAY. <br />
                3.- Dicta al cajero el número de referencia en esta ficha. <br />
                5.- Conserva tu comprabante para cualquier aclaración.<br />
                6.- Recibirás un correo cuando hayamos comprobado tu pago.<br />
              </Typography>
            </div>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary">
            Regresar
          </Button>

          {handleRegister &&
            <Button onClick={handleClose} color="primary" variant="contained">
              Aceptar
            </Button>
          }
          <Button onClick={handleoxxoPDF} variant="outlined" color="secondary">
            Guardar como PDF
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogOxxoPay
