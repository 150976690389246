import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  floatRight: {
    float: "right",
  },
});

const DialogMassSendBills = ({ btnText, originPosition }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/mass-send");
  };
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRedirect}
        className={originPosition && classes.floatRight}
      >
        {btnText}
      </Button>
    </>
  );
};

export default DialogMassSendBills;
