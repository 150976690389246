import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  axiosSendBillFacturaApi,
  axiosSendBillMultiple,
} from "../../api/axiosBills";
import { validateEmail } from "../../utils/validations";
import { AddBox, DisabledByDefault } from "@mui/icons-material";
import { axiosShowClientIDFacturaApi } from "../../api/axiosClients";

const SendBills = ({ billInfo, handlerBillSendModal, openSendModal }) => {
  const [toSend, setToSend] = useState([]);
  const [infoClient, setInfoClient] = useState([]);

  useEffect(() => {
    if (billInfo?.customer?.id) {
      axiosShowClientIDFacturaApi(billInfo?.customer?.id)
        .then((response) => {
          setInfoClient(response.data.data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [billInfo]);

  const sendBillHandler = () => {
    axiosSendBillFacturaApi(billInfo.id)
      .then((response) => {
        if (response.data.data.message) {
          toast.error(response.data.data.message);
        } else {
          toast.success("Factura enviada.");

          handlerBillSendModal();
        }
      })
      .catch((err) => {
        toast.error("Ocurrió un error al hacer la petición.");
      });
  };

  const sendBillMultiple = async () => {
    axiosSendBillMultiple(billInfo.id, toSend)
      .then((response) => {
        if (!response.data.data.message) {
          toast.success("Factura enviada.");
          handlerBillSendModal();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Ocurrió un error al hacer la petición.");
      });
  };

  useEffect(() => {
    setToSend([]);
  }, []);

  const handleAddEmail = () => {
    let inputValue = document.getElementById("add-email").value;

    if (validateEmail(inputValue)) {
      setToSend((prev) => [...prev, inputValue]);
      document.getElementById("add-email").value = "";
    } else {
      document.getElementById("add-email").focus();
    }
  };

  const handleDeleteChip = (element) => {
    const newArr = toSend.filter((e) => e !== element);

    setToSend(newArr);
  };

  return (
    <Dialog
      open={openSendModal}
      onClose={handlerBillSendModal}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" sx={{ m: 2 }}>
        {`Envio de Factura ${billInfo?.folio_number} - ${billInfo?.series}`}
      </DialogTitle>
      <DialogContent sx={{ mr: 4, ml: 4 }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Grid item xs={8}>
            <DialogContentText id="alert-dialog-description">
              Enviar la factura al cliente{" "}
              {!infoClient ? "Sin correo" : infoClient.email}
            </DialogContentText>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={sendBillHandler}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
          Enviar la factura a otro correo (puedes agregar hasta 5 correos).
        </DialogContentText>

        <Grid container spacing={1}>
          {toSend.map((e, index) => (
            <Grid item key={index}>
              <Chip
                key={index}
                label={e}
                color={"primary"}
                onDelete={() => handleDeleteChip(e)}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          <Grid container spacing={2} mt={2} alignItems={"center"}>
            <Grid item xs={10}>
              <TextField
                size="small"
                type="email"
                id="add-email"
                label="Agregar correo"
              />
            </Grid>
            <Grid item xs={2}>
              {toSend.length >= 5 ? (
                <Button disabled color="secondary">
                  <DisabledByDefault />
                </Button>
              ) : (
                <Button color="primary" onClick={handleAddEmail}>
                  <AddBox />
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={10}>
              <DialogContentText id="alert-dialog-description">
                Enviar la factura <b>{toSend.length}</b> correos
              </DialogContentText>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                disabled={toSend.length <= 0 ? true : false}
                color="primary"
                onClick={sendBillMultiple}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerBillSendModal} autoFocus>
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendBills;
