import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Box,
  Avatar,
  Pagination,
  Stack,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { axiosShowClientIDFacturaApi } from "../../api/axiosClients";
import EditClients from "./EditClients";
import { LoadingButton } from "@mui/lab";
import DeleteClients from "./DeleteClients";
import SkeletonTable from "../General/SkeletonTable";
import { toast } from "react-toastify";

const TableClients = ({
  loading,
  clients,
  totalPages,
  page,
  search,
  triggerAxiosPaginate,
}) => {
  const [clientData, setClientData] = useState({});
  const [statusEditModal, setStatusEditModal] = useState(false);
  const [statusDeleteModal, setStatusDeleteModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  // ComponentDidMount
  useEffect(() => {
    triggerAxiosPaginate(page);
  }, []);

  const handleChangePage = (event, page) => {
    event.preventDefault();
    triggerAxiosPaginate(page, search);
  };

  const handleToggleEditModal = (client) => {
    if (statusEditModal === false) {
      setLoadingEdit(true);
      axiosShowClientIDFacturaApi(client)
        .then((response) => {
          let { data: data } = response;

          setClientData(data.data);
          setLoadingEdit(false);
          setTimeout(() => {
            setStatusEditModal(true);
          }, 10);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Error en el servidor");
          }
          setLoadingEdit(false);

          return err;
        });
    }

    setStatusEditModal(false);
  };

  const handleToggleDeleteModal = () => {
    setStatusDeleteModal(!statusDeleteModal);
  };

  return (
    <Card variant="elevation">
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440, borderTop: "8px solid #233B63" }}>
          <Table
            stickyHeader
            aria-label="custom pagination table"
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">ID.</Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5">Razón Social</Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5">RFC</Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h5">Telefono</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Correo</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Acciones</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <SkeletonTable />
              ) : (
                clients?.map((client, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "110px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {client.id}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={client.legal_name}
                          alt={client.legal_name}
                          width="30"
                          sx={{
                            borderRadius: "100%",
                          }}
                        />
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <Typography
                            color="textSecondary"
                            variant="h6"
                            fontWeight="400"
                            sx={{
                              width: "140px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {client.legal_name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textSecondary"
                        variant="h6"
                        fontWeight="400"
                      >
                        {client.tax_id}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">{client.phone}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">{client.email}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="client">
                      <ButtonGroup
                        variant="contained"
                        aria-label="contained primary button group"
                      >
                        <LoadingButton
                          size="small"
                          color="primary"
                          variant="contained"
                          loading={client.id === clientData.id && loadingEdit}
                          disabled={client.tax_id === "XAXX010101000"}
                          onClick={() => {
                            setClientData(client);
                            handleToggleEditModal(client.id);
                          }}
                        >
                          <Edit />
                        </LoadingButton>
                        <Button
                          size="small"
                          color="error"
                          disabled={client.tax_id === "XAXX010101000"}
                          onClick={() => {
                            setClientData(client);
                            handleToggleDeleteModal();
                          }}
                        >
                          <Delete />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack spacing={2} style={{ float: "right" }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </Paper>
      <EditClients
        client={clientData}
        handleToggle={handleToggleEditModal}
        isOpen={statusEditModal}
        triggerAxiosPaginate={triggerAxiosPaginate}
      />

      <DeleteClients
        client={clientData}
        handleToggle={handleToggleDeleteModal}
        isOpen={statusDeleteModal}
        triggerAxiosPaginate={triggerAxiosPaginate}
      />
    </Card>
  );
};

export default TableClients;
