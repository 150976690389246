import React, { useEffect, useState } from "react";
import { useGetLists } from "../../hooks/getLists";
import { Grid, MenuItem, TextField } from "@mui/material";
import { validateEmail, validatePhoneNumber } from "../../utils/validations";

const AddClientsAbroad = ({ setClient, client }) => {
  const { getCountries } = useGetLists();
  const [countries, setCountries] = useState([]);

  const onChange = (event) => {
    if (event.target.name === "legal_name") {
      event.target.value = event.target.value.toUpperCase();
    }
    setClient({ ...client, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    getCountries({ setCountries });
  }, []);

  return (
    <form onChange={onChange} noValidate autoComplete="on">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientSocialReason"
            name="legal_name"
            label="Nombre Fiscal"
            type="text"
            variant="outlined"
            required
            value={client.legal_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            select
            margin="dense"
            id="clientCountry"
            name="country"
            label="País"
            type="text"
            variant="outlined"
            required
            value={client.country}
            onChange={onChange}
            defaultValue=""
            fullWidth
          >
            {countries?.length > 0 ? (
              countries?.map((list) => (
                <MenuItem key={list.id} value={list.code}>
                  {list.name}
                </MenuItem>
              ))
            ) : (
              <div></div>
            )}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientRfc"
            label="Número de registro"
            type="text"
            variant="outlined"
            name="tax_id"
            required
            value={client.tax_id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientEmail"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            value={client.email}
            fullWidth
            error={!validateEmail(client.email) && client.email !== ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientPhone"
            name="phone"
            label="Teléfono"
            type="tel"
            variant="outlined"
            required
            value={client.phone}
            fullWidth
            error={!validatePhoneNumber(client.phone) && client.phone !== ""}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddClientsAbroad;
