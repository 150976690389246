import { useState, useEffect } from 'react';

import { axiosGetUnitCodes } from '../api/unitCodes';


export const useFetchUnitCodes = ( search ) => {
    
    const [state, setState] = useState({
        data:[],
    });

    useEffect( () => {
        
        axiosGetUnitCodes( search ).then( response => {
            setState( { data: response.data.data} );
        });
  
    }, [ search ]);

    return state;

}
