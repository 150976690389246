import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardActions,
    IconButton,
    Typography,
    Divider,
    Avatar,
    Grid,
    Stack,
    Pagination,
    Alert,
} from '@mui/material'
import { Delete, Edit, InsertPhoto } from '@mui/icons-material';
import { isTotallyEmpty } from '../../utils/validations';
import EditBusiness from './EditBusiness';
import DeleteBusiness from './DeleteBusiness';
import { PersonalizeBusiness } from './PersonalizeBusiness';

const ListBusiness = ({
    loading,
    business,
    totalPages,
    page, 
    search,
    triggerAxiosPaginate }) => {

    const [businessData, setBusinessData] = useState({});
    const [statusEditModal, setStatusEditModal] = useState(false);
    const [statusDeleteModal, setStatusDeleteModal] = useState(false);
    const [statusPersonalizeModal, setStatusPersonalizeModal] = useState(false);

    // ComponentDidMount
    useEffect(() => {
        triggerAxiosPaginate(page);
    }, []);

    const handleChangePage = (event, page) => {
        triggerAxiosPaginate(page, search);
    }

    const handleToggleEditModal = () => {
        setStatusEditModal(!statusEditModal);
    }

    const handleToggleDeleteModal = () => {
        setStatusDeleteModal(!statusDeleteModal);
    }

    const handleTogglePersonalizeModal = () => {
        setStatusPersonalizeModal(!statusPersonalizeModal);
    }

    return (
        <>
            <Grid container alignItems="center" justify="center" justifyContent={'center'}>
                {loading ? (
                    <h1>Cargando...</h1>
                ) : (
                    isTotallyEmpty(business) ?
                        <Grid item xs={12} lg={10} sx={{ p: 2, m: 4 }}>
                            <Alert icon={false} severity="info" sx={{ justifyContent: 'center' }}>
                                <h3>
                                    Aun no tienes ninguna empresa adicional a la principal registrada.
                                </h3>
                            </Alert>
                        </Grid>
                        : business?.map((company, index) => (
                            <Grid item xs={12} lg={3} sx={{ p: 2 }} key={index}>
                                <Card sx={{ minHeight: '200px', borderTop: company.files_csd === 0 ? '8px solid #D7060B' : company.legal_check === 0 ? '8px solid #E57200' : '8px solid #233B63', }} >
                                    <CardContent>
                                        <Avatar
                                            src={company.legal_name}
                                            alt={company.legal_name}
                                            width="30" />
                                        <Typography
                                            variant="h5"
                                            color="secondary">
                                            {company.legal_name}
                                        </Typography>
                                        <Divider />
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <IconButton aria-label="Edit" onClick={() => { setBusinessData(company); handleToggleEditModal(); }} >
                                            <Edit color='info' />
                                        </IconButton>
                                        <IconButton aria-label="Delete" onClick={() => { setBusinessData(company); handleToggleDeleteModal(); }} >
                                            <Delete color="primary"/>
                                        </IconButton>
                                        <IconButton aria-label='logo' onClick={() => { setBusinessData(company); handleTogglePersonalizeModal(); }}>
                                            <InsertPhoto color="secondary"  />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                )}

            </Grid>
            <Stack spacing={2} style={{ float: 'right' }}>

                <Pagination count={totalPages} page={page}
                    onChange={handleChangePage} variant="outlined" shape="rounded" />
            </Stack>

            <EditBusiness
                business={businessData}
                handleToggle={handleToggleEditModal}
                isOpen={statusEditModal}
                triggerAxiosPaginate={triggerAxiosPaginate} />

            <DeleteBusiness
                business={businessData}
                handleToggle={handleToggleDeleteModal}
                isOpen={statusDeleteModal}
                triggerAxiosPaginate={triggerAxiosPaginate}
            />

            <PersonalizeBusiness
                business={businessData}
                setBusiness={setBusinessData}
                handleToggle={handleTogglePersonalizeModal}
                isOpen={statusPersonalizeModal}
                triggerAxiosPaginate={triggerAxiosPaginate}
            />
        </>
    )
}

export default ListBusiness