import React, { useEffect } from 'react'
import { FormControlLabel, Switch } from '@mui/material'

const SwitchReletedDocuments = ({ activeSwitch, setActiveSwitch, bill, setBill, setDocuments, setValue, setRelatedBillData }) => {
    useEffect(() => {
        if (activeSwitch === false) {
            setBill({ ...bill, related_documents: [], relation: true });
            setValue('');
            setRelatedBillData({
                relationship: '',
                documents: [],
            });
            setDocuments([]);

        }else {
            setBill({ ...bill, relation: false });
        }
    }, [activeSwitch])

    return (
        <>
            <FormControlLabel
                value={activeSwitch}
                control={<Switch color='primary' />}
                onClick={() => setActiveSwitch(!activeSwitch)}
                label="¿Esta factura lleva facturas relacionadas? "
                labelPlacement="start"
            />
        </>
    )
}

export default SwitchReletedDocuments