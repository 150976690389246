import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root:{
        background: 'red',
       backgroundColor: 'aliceblue',
    },
    margin: {
        margin: "16px"
    },
    header:{
        backgroundColor: '#0762f8',
        fontSize: '1.65rem',
        color: '#ffff',
        fontWeight: 'bolder'
        
    },
    inputoxxo: {
        
        margin: 10,
        backgroundColor: '#f5f6f8',
        padding: 25,
        border: '1px solid #ccc',
        borderRadius:'8px',

    },
    inputTransfer: {
        
        margin: 10,
        backgroundColor: '#f5f6f8',
        padding: 20,
        paddingTop: 0,
        paddingBottom: 0,
        border: '1px solid #ccc',
        borderRadius:'8px',

    },
    caption:{
        marginBottom: 15
    }
}));

export  {useStyles};