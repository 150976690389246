import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";
//Consultar usos de CFDi
export const axiosGetCfdiUses = async () => {
  let url = `${API_HOST}/api/tools/cfdiUses`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  // getting axios response on variable
  let response = await axios
    .get(url, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
