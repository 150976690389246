import axios from "axios";

import { API_HOST, TOKEN } from "../utils/constant";

//edit logo
export const axiosUploadAvatarPic = async (img) => {
  let formData = new FormData();

  formData.append("avatar", img);

  let url = `${API_HOST}/api/user/upload-avatar-pic`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
      "Content-Type": "multipart/form-data",
    },
  };

  let response = axios.post(url, formData, headersConfig);

  return response;
};
//consultar logo
export const axioGetAvatarPic = async () => {
  let url = `${API_HOST}/api/user/get-avatar-pic`;
  let jwt = localStorage.getItem(TOKEN);

  const headersConfig = {
    headers: {
      Authorization: jwt,
      responseType: "arraybuffer",
    },
  };

  let response = axios.get(url, headersConfig);

  return response;
};
//activar folio automatico
export const axiosActiveFolio = async (activeSwitch, folio_number) => {
  const url = `${API_HOST}/api/company/active-folio`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data =
    activeSwitch === true
      ? {
          next_folio_number: folio_number,
          active: true,
          key: key,
        }
      : {
          active: false,
          key: key,
        };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
//consultar planes
export const axiosGetPlans = async () => {
  let url = `${API_HOST}/api/plans`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let response = axios.get(url, headersConfig);

  return response;
};
