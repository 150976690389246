import React, { useState } from 'react'
import {
    List,
    Skeleton,
} from '@mui/material';
import SelectOxxoPayments from './SelectOxxoPayments';
import SelectCardPayments from './SelectCardPayments';

const ListPaymentsShopPayments = ({ selectedPayment, setSelectedPayment }) => {
    const [loading, setLoading] = useState(true);

    const handleChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    return (
        <>
            <List dense={true}>
                <SelectOxxoPayments handleChange={handleChange}
                    selectedPayment={selectedPayment} />

                <>
                    <SelectCardPayments handleChange={handleChange}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </>

            </List>
        </>
    )
}

export default ListPaymentsShopPayments