import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";
// const access_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiMmIyMzc4MzAyNzFkNjM3MzM0NDYzYzljN2I5NmFkMDE0ZmM4YTI5MWYzYTM0NDkyZDZjOTc4NDMzMGEzMzM5YThiZWVhNWVmMTM4ZGQ4ZWIiLCJpYXQiOiIxNjMyMzM2ODU4LjY2OTQzNiIsIm5iZiI6IjE2MzIzMzY4NTguNjY5NDQ1IiwiZXhwIjoiMTY2Mzg3Mjg1OC41MzEyNDkiLCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.reT0gxfAXlojdCKwiC2wbgiQHww3U4OhTC1X3DJRg2OMqKPKnO5aggyrNSkn0bE6voFYcgUvu-7QpKNawlos6spQ4qIEu783yAdFVIPA8iFZclUwKGGNXi-H0GwC-G2oZX_2FHltDCTQD8jMMvUlaIWF9iQvn1ki-Mnl6Bb7Gdj-nngTYg3_UEaC9leZIi1LUDoQB_ku0oTWVMfRDG-WQmAMqkGCdQGyp0SlZ7vnxLYdtyoSPAS5eJE-JSwlJOC_GR-TERmMLc8InWyXFB3hBvrMdL_C5N1iaR9f6lrTc99DFD4o2drwpKg1vkrvPXlhK2_gkeiJPRiAi39rI2FpnJngsBssor8mXo3kfbkXmA-ulSw87m-EiwDUtXnZIMzo35AIPnJMtiL-ilUQPeguNd_TJp5U8GmwMU8saG0R9vBgJo6sP6_QVaiVe9P_6ndC8V07ycxfbRSS8koJdbAZf3AAGlHBMzfJ-klu_m1yA98-XHg-VM2_NDH_qkTblBNCPHY_9SneHNqGL5JG69gk6SY09vdm82lNyzdhV0FSEyp24hYIyzbw57J10-UQdo5rIAAtBiDNuFaMbqS-hDFSgMMzwK3XCiUl5yzz-co0nYg-zpTRbkQBY6byzXbiB_P7AjSfPS5iUSDl9dPykUMsPW49BSh2G6AaobjRGKfNqns'

// Si no se hace una función se estanca y envia un JWT pasado
// Si se hace aqui como variable, se estanca con el valor de ese momento
// MUY IMPORTANTE, NO OLVIDAR!

//Consultar las claves de producto
export const axiosGetProdServCodes = async (search) => {
  let url = `${API_HOST}/api/prod-serv-codes`;
  // let url = `${API_HOST}/api/tools/prodServCode/${search}`;
  search.length === 0
    ? (url = `${API_HOST}/api/prod-serv-codes`)
    : (url = `${API_HOST}/api/prod-serv-codes/${search}`);
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  // getting axios response on variable
  let response = await axios
    .get(url, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
