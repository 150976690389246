import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import CardActiveSubscription from './CardActiveSubscription';
import CardChangePayment from './CardChangePayment';
import CardShopSubscription from './CardShopSubscription';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const DialogBuySubscritions = ({ handlerShopSubscriptionModal, openShopSubscriptionModal, info,handleInfo }) => {
    const [state, setState] = useState({
        showActive: true,
        showChange: false,
        showShop: false
    });
    const handleClickPayData = () => {
        setState({
            showActive: false,
            showChange: true,
            showShop: false,
        });
    }
    const handleClickShopData = () => {
        setState({
            showActive: false,
            showChange: false,
            showShop: true,
        });
    }

    const handleClickActiveData = () => {
        setState({
            showActive: true,
            showChange: false,
            showShop: false,
        });
    }

    let isShowchange = state.showChange === true;
    let isShowShop = state.showShop === true;


    useEffect(() => {
        if (openShopSubscriptionModal === true) {
            if (info?.subscription?.status === "active") {
                handleClickActiveData();
            } else {
                handleClickShopData();
            }
        }
    }, [openShopSubscriptionModal])

    return (
        <Dialog
            open={openShopSubscriptionModal}
            onClose={handlerShopSubscriptionModal}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            maxWidth={'sm'}
            fullWidth >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handlerShopSubscriptionModal}>Subscripciones</BootstrapDialogTitle>
            <DialogContent sx={{ mr: 3, ml: 3 }}>

                <Grid container spacing={2} justifyContent='center'>
                    {isShowchange ?
                        <CardChangePayment handleClickActiveData={handleClickActiveData} />
                        : isShowShop ?
                            <CardShopSubscription info={info} handleClickActiveData={handleClickActiveData} handleInfo={handleInfo}  handlerShopSubscriptionModal={handlerShopSubscriptionModal}/>
                            :
                            <>
                                <Grid item>
                                    <Typography variant="h5" gutterBottom component="div" align='center'>
                                        Actualmente ya cuentas con una suscripción
                                    </Typography>
                                    <CardActiveSubscription info={info} />
                                </Grid>
                                <Grid item >
                                    <Button variant='contained' onClick={() => handleClickPayData()} >Cambiar metodo de pago</Button>
                                </Grid>
                                <Grid item >
                                    <Button variant='contained' onClick={() => handleClickShopData()}> Realizar compra </Button>
                                </Grid>
                            </>
                    }
                </Grid>

            </DialogContent>
        </Dialog>
    )
}

export default DialogBuySubscritions