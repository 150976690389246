import React, { useState, useEffect } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material'
import { LoadingButton } from '@mui/lab';
import FormEditDataBusiness from './FormEditDataBusiness';
import ArchiveCSDBusiness from './ArchiveCSDBusiness';
import AvatarBusiness from './AvatarBusiness';
import { toast } from 'react-toastify';
import { axiosUpdateLegalDataFacturaApi } from '../../api/axiosCompany';

const EditBusiness = ({ business, handleToggle, isOpen, triggerAxiosPaginate }) => {

    const [loading, setLoading] = useState(false);
    const [loadingArchive, setLoadingArchive] = useState(false);
    const [businessEdit, setBusinessEdit] = useState({});
    useEffect(() => {
        setBusinessEdit({
            id: business?.id,
            name: business?.name,
            legal_name: business?.legal_name,
            id_client: business?.id_client,
            tax_id: business?.tax_id,
            tax_system: business?.tax_system,
            zip: business?.zip,
            street: business?.street,
            exterior: business?.exterior,
            website: business?.website,
            phone: business?.phone,
            support_email: business?.support_email,
            password_csd: business?.password_csd,
            files_csd: business?.files_csd,
            legal_check: business?.legal_check,
            expire_csd: business?.expire_csd,
            logotipo: business?.logotipo,
            id_facturapi: business?.id_facturapi,
            folio_check: business?.folio_check,
            folio_init: business?.folio_init ? business?.folio_init : '',
            cer_file: '',
            key_file: '',


        });
    }, [business]);


    const handleUpdateCompany = () => {
        setLoading(true);
        axiosUpdateLegalDataFacturaApi(businessEdit)
            .then(response => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        setLoading(false);
                        handleToggle();
                        triggerAxiosPaginate(1);
                        window.location.reload(true);
                    }, 10);
                } else {
                    toast.error(response.data.message);
                    setLoading(false);
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toast.error(err.response.data.message);
                } else {

                    toast.error('Error en el servidor');
                }
                setLoading(false);
                return err;
            });
    };

    const handleChange = prop => event => {
        setBusinessEdit({ ...businessEdit, [event.target.name]: event.target.value });
    };

    return (
        <>
            <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={() => handleToggle()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edición de La Empresa {business?.legal_name}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} xl={6}>
                            <ArchiveCSDBusiness
                                businessEdit={businessEdit}
                                setBusinessEdit={setBusinessEdit}
                                loadingArchive={loadingArchive}
                                setLoadingArchive={setLoadingArchive}
                                handleChange={handleChange}
                                handleToggle={handleToggle}
                                triggerAxiosPaginate={triggerAxiosPaginate} />
                            {/* <AvatarBusiness /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                            <FormEditDataBusiness
                                businessEdit={businessEdit}
                                setBusinessEdit={setBusinessEdit}
                                handleChange={handleChange} />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>

                    <LoadingButton
                        loading={loading}
                        onClick={() => handleUpdateCompany()}
                        color="primary" variant='contained'>
                        Editar
                    </LoadingButton>
                    <Button onClick={() => handleToggle()} >
                        Cancelar
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditBusiness