import React from 'react'
import {
    Box,
    TextField,
    Autocomplete,
    Tooltip,
    InputAdornment,
} from '@mui/material';
import ladas from "./../../utils/international_ladas.json";
import { Help } from '@mui/icons-material';

const AutocompleteLada = ({ formData, setFormData, d = false }) => {
    const handlerChangeLada = (e, lada = '') => {
        
        if (!e?.target?.value) {

            setFormData({ ...formData, lada: lada.phone });

        } else {

            setFormData({ ...formData, lada: e.target.value });
        }
    }
    return (
        <>
            <Autocomplete
                id="country-select-lada"
                options={ladas}
                onChange={handlerChangeLada}
                inputValue={ formData.country_code}
                getOptionLabel={(option) => option.phone}
                disableClearable
                onInputChange={(event, newInputValue, reason) => {

                    if (reason !== "reset") {
                      
                            setFormData({ ...formData, country_code: newInputValue })
                        
                    }
                }}

                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} >
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label}  +{option.phone}
                    </Box>
                )}

                renderInput={(params) => (
                    <TextField
                        required
                        {...params}
                        label={d === true ? "Lada Internacional" : "Lada Int."}
                        name={"country_code"}
                        size={'medium'}
                        onChange={(e) => handlerChangeLada(e, params)}
                        value={formData.country_code }
                        margin="dense"
                        type="number"
                        helperText={d === true && "Para MX por defecto es +52"}
                        variant="filled"
                        inputProps={{
                            ...params.inputProps,
                            maxLength: 5,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (d === false &&
                                <Tooltip title="Para MX por defecto es +52" aria-label="Info" >
                                    <InputAdornment position="start" sx={{mt:'5px !important'}}>
                                        <Help />
                                    </InputAdornment>
                                </Tooltip>
                            )
                        }}


                    />

                )}
            />
          
        </>
    )
}

export default AutocompleteLada