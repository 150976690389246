import React, { useState,} from 'react'
import {
    Typography,
    Grid,
} from '@mui/material';
import "../../utils/styles/SingInSingUp.scss";
import StepperRegister from '../../components/SignInSingUp/Register/StepperRegister';
import PaymentDataRegister from '../../components/SignInSingUp/Register/PaymentDataRegister';
import BasicDataRegister from '../../components/SignInSingUp/Register/BasicDataRegister';

const Register = () => {
    const [state, setState] = useState({
        showSignUp: true,
        showPayData: false,
        setActiveStep: 0
    });

    const handleClickPayData = () => {
        setState({
            showSignUp: !state.showSignUp,
            showPayData: !state.showPayData,
            setActiveStep: state.setActiveStep === 0 ? 1 : 0
        });
    }

    let isShowPay = state.showPayData === true;

    return (
        <>
            <Typography sx={{ fontSize: 22, textAlign: 'center' }} color="text.secondary" gutterBottom>
                Regístrate
            </Typography>
            <Grid item xs={12} sm={12} md={12} xl={12}>
                <StepperRegister setActiveStep={state.setActiveStep} />
            </Grid>

            {isShowPay ?
                (
                    <PaymentDataRegister
                        handlepay={handleClickPayData}
                        setActiveStep={state.setActiveStep}
                    />
                ) : (
                    <BasicDataRegister
                        handlepay={handleClickPayData}
                        setActiveStep={state.setActiveStep} />
                )}

        </>

    )
}

export default Register

