
import React from 'react'
import {
  Grid,
} from '@mui/material';
import BaseCard from '../../components/General/BaseCard';
import GridSelectReports from '../../components/Reports/GridSelectReports';

const ReportsPage = () => {
  return (
    <>
      <Grid container spacing={0}>
      <BaseCard title="REPORTES">
        <Grid container justifyContent={'space-between'} alignItems='center' >
        <GridSelectReports/>
        </Grid>
        </BaseCard>
        </Grid>
    </>
  )
}

export default ReportsPage