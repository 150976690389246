import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Card,
  Typography,
  CardHeader,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import { axiosGetPlans } from "../../../api/userInfo";
import { toast } from "react-toastify";
import ListPaymentsShopPayments from "../Payments/ListPaymentsShopPayments";
import {
  axiosGenerateOrderPlanCard,
  axiosGenerateRecurrentOxxoPay,
} from "../../../api/axiosConekta";
import DialogOxxoPay from "../../../layouts/DialogOxxoPay";

const CardShopSubscription = ({
  handleClickActiveData,
  handleInfo,
  handlerShopSubscriptionModal,
}) => {
  const [plans, setPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("e");
  const [orderLoading, setOrderLoading] = useState(false);
  const [openDialogOxxo, setOpenDialogOxxo] = useState(false);

  const [orderOxxo, setOrderOxxo] = useState({
    id: "",
    name: "",
    oxxo_price: "",
    reference: "",
    code: "",
  });
  useEffect(() => {
    axiosGetPlans()
      .then((response) => {
        setPlans(response.data.data);
      })
      .catch((err) => {
        toast.error("Error al cargar Planes", err);
      });
  }, []);

  const proccessPayment = () => {
    if (selectedPayment === "oxxo") {
      setOrderLoading(true);
      axiosGenerateRecurrentOxxoPay(planSelected)
        .then((response) => {
          if (response.data.status === true) {
            toast.success("El registro ha sido correcto.");
            setOrderOxxo({
              ...orderOxxo,
              reference: response.data.data.payment_sources.reference,
              code: response.data.data.payment_sources.barcode_url,
            });
            setOpenDialogOxxo(true);
          } else {
            toast.warning(response.data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error(" No se ha iniciado una sesión o registro.");
          } else {
            toast.error(
              " Error en el servidor, inténtelo nuevamente más tarde."
            );
          }
        })
        .finally(() => {
          setOrderLoading(false);
        });
    } else if (selectedPayment === "e") {
      toast.warning(" Favor de seleccionar un metodo de pago.");
    } else {
      setOrderLoading(true);
      axiosGenerateOrderPlanCard(planSelected, selectedPayment)
        .then((response) => {
          if (response.data.status === true) {
            if (response.data.data.status === "active") {
              handleInfo();
            }
            toast.success("El registro ha sido correcto.");
            setTimeout(() => {
              handlerShopSubscriptionModal();
            }, 10);
          } else {
            toast.warning(response.data.message);
          }
        })
        .catch((err) => {
          toast.error(" Error en el servidor, inténtelo nuevamente más tarde.");
        })
        .finally(() => {
          setOrderLoading(false);
        });
    }
  };

  const handlePayPlan = () => {
    proccessPayment();
  };

  const onChange = (e) => {
    setPlanSelected(e.target.value);
    const filterPlan = plans.filter((item) => item.id === e.target.value);
    setOrderOxxo({
      ...orderOxxo,
      id: filterPlan[0].id,
      name: filterPlan[0].name,
      oxxo_price: filterPlan[0].price,
    });
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        justifyContent="center"
        alignItems={"center"}
      >
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="div" align="center">
            Adquirir plan
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Card variant="outlined" sx={{ p: 2, bgcolor: "rgba(0,0,0,.03)" }}>
            <CardHeader title={"Selecciona el Plan"} />
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id="filled-select-currency"
                  select
                  label="Selecciona Un Plan"
                  value={planSelected}
                  onChange={onChange}
                  name="plan"
                  variant="filled"
                  fullWidth
                  required
                  size="small"
                >
                  {plans.map((option) => (
                    <MenuItem key={option.conekta_name} value={option.id}>
                      {option.name} - ${option.price}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  mt={2}
                >
                  Facturalite está obligado por ley a recaudar los impuestos de
                  las transacciones de las compras realizadas en determinadas
                  jurisdicciones fiscales.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined" sx={{ p: 2, bgcolor: "rgba(0,0,0,.03)" }}>
            <CardHeader title={" Selecciona el metodo de pago"} />
            <Grid container>
              <Grid item xs={12}>
                <ListPaymentsShopPayments
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            onClick={() => handlePayPlan()}
            disabled={planSelected === "" || selectedPayment === "e"}
          >
            {!orderLoading ? "Compar" : <CircularProgress color="inherit" />}
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={() => handleClickActiveData()}>Volver</Button>
        </Grid>
      </Grid>
      <DialogOxxoPay
        setOpen={setOpenDialogOxxo}
        open={openDialogOxxo}
        order={orderOxxo}
      />
    </>
  );
};

export default CardShopSubscription;
