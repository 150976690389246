import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//generar una orden oxxo de plan
export const axiosGenerateRecurrentOxxoPay = async (id) => {
  let url = `${API_HOST}/api/register-payment-data-oxxo`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let plan = { plan: id };

  let response = await axios.post(url, plan, headersConfig);
  return response;
};
//generar una suscripcion con tarjeta
export const axiosGenerateOrderPlanCard = async (plan_id, card_id) => {
  let url = `${API_HOST}/api/store/generate-suscripcion-card`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let body = {
    paymentId: plan_id,
    cardId: card_id,
  };

  let response = await axios.post(url, body, headersConfig);
  return response;
};
//Consultar ordenes
export const axiosGetOrdersData = async () => {
  let url = `${API_HOST}/api/get-order-data`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.get(url, headersConfig);
  return response;
};
//registrar cliente en conekta
export const axiosRegisterClient = async () => {
  let url = `${API_HOST}/api/register-client-conekta`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.post(url, headersConfig);
  return response;
};
//registrar un metodo de pago
export const axiosRegisterPaymentCard = async (token, plan) => {
  let url = `${API_HOST}/api/register-payment-data`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const body = {
    token,
    plan_id: plan,
  };

  let response = await axios.post(url, body, headersConfig);
  return response;
};
//consultar informacion subscripcion de usuario Conekta
export const axiosGetInfoCustomer = async () => {
  let url = `${API_HOST}/api/store/get-subscription`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.get(url, headersConfig);
  return response;
};
//consultar metodos de pago registrados de conekta
export const axiosGetUserPaymentData = async () => {
  let url = `${API_HOST}/api/store/get-payment-data`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.get(url, headersConfig);
  return response;
};
//Cambiar tarjeta por defauld a la que se le realiza el cobro
export const axiosUpdateDefaultCard = async (paymentId) => {
  let url = `${API_HOST}/api/store/set-card-as-default`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.patch(url, { paymentId }, headersConfig);
  return response;
};
//eliminar un metodo de pago
export const axiosDeletePaymentData = async (index) => {
  let url = `${API_HOST}/api/store/remove-payment-data/${index}`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.delete(url, headersConfig);
  return response;
};
