import React, { useState, useEffect } from 'react'
import {
    TextField,
    MenuItem,
    Grid,
    Typography,
    Divider,
    FormControl,
    InputLabel,
    Select,
    Paper,
} from '@mui/material';
import paymentModes from '../../../utils/payment_modes.json';
import esLocale from 'date-fns/locale/es';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RelatedDocumentsNewComplementsBill from './RelatedDocumentsNewComplementsBill';
import { DocumentDynamicGenerator } from './DocumentDynamicGenerator';


const ComplementsNewComplementBill = ({ complementBill, setComplementBill }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [numberDocument, setNumberDocument] = useState(1);
    const [hiddenDocument, setHiddenDocument] = useState([]);
    const [relatedDocuments, setRelatedDocuments] = useState([]);

    const [complements, setComplements] = useState({
        type: 'pago',
        data: [],
    });

    const [complementData, setComplementData] = useState({
        payment_form: '',
        related_documents: '',
        currency: 'MXN',
        exchange: '',
        date: '',
        numOperacion: '',

    });

    useEffect(() => {
        setComplementData({ ...complementData, related_documents: relatedDocuments });
    }, [relatedDocuments]);

    useEffect(() => {
        setComplements({ ...complements, data: complementData });
    }, [complementData]);

    useEffect(() => {
        setComplementBill({ ...complementBill, complements: complements });
    }, [complements]);


    /**
   * 
   * @param {Date/String} date 
   * 
   */
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setComplementData({ ...complementData, date: date });
    };
    const handlePaymentModeChange = ({ target }) => {
        setComplementData({ ...complementData, payment_form: target.value });
    }
    const handleCurrencyChange = ({ target }) => {
        setComplementData({ ...complementData, currency: target.value });
    }
    const handleNumOperacionChange = ({ target }) => {

        setComplementData({ ...complementData, numOperacion: target.value });
    }
    const handleChangeExchange = ({ target }) => {
        if (complementData.currency === "MXN") {
            setComplementData({ ...complementData, exchange: '' });
        } else {
            setComplementData({ ...complementData, exchange: target.value });

        }
    }

    return (
        <>
            <Typography variant="h6" fontWeight="600" marginBottom={'1%'} marginTop={'2%'}>
                Información del Complemento
            </Typography>
            <Divider style={{ marginBottom: "20px" }} />
            <Grid container spacing={1} >
                <Grid item xs={12} sm={3} xl={3}>
                    <FormControl variant="filled"
                        fullWidth
                        required
                        margin="dense">
                        <InputLabel id="payment-mode-label">Forma de pago</InputLabel>
                        <Select
                            labelId="payment-mode-label"
                            id="payment-mode"
                            value={complementData?.payment_form}
                            onChange={handlePaymentModeChange}
                            label="Forma de pago" >
                            {paymentModes.map(payment => (
                                <MenuItem key={payment.id} value={payment.code}>
                                    {`${payment.code} - ${payment.name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} xl={2}>
                    <TextField
                        margin="dense"
                        name="numOperacion"
                        label="Número de operación"
                        type="text"
                        placeholder='Referencia que permita identificar la operación correspondiente al pago'
                        helperText='Referencia que permita identificar la operación correspondiente al pago'
                        variant="filled"
                        onChange={handleNumOperacionChange}
                        value={complementData?.numOperacion}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3} xl={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DatePicker
                            label="Fecha del pago"
                            value={complementData.date ? (complementData.date) : (selectedDate)}
                            onChange={handleDateChange}
                            maxDate={new Date()}
                            renderInput={(params) => <TextField {...params}
                                helperText='Fecha en que se recibió el pago. '
                                variant="filled"
                                margin="dense"
                                fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={2} xl={2}>

                    <FormControl variant="filled"
                        fullWidth
                        required
                        margin="dense"
                        defaultValue='MXN'>
                        <InputLabel id="payment-method-label">Divisa</InputLabel>
                        <Select
                            labelId="payment-method-label"
                            id="payment-method"
                            value={complementData?.currency}
                            onChange={handleCurrencyChange}
                            label="Divisa"
                            defaultValue='MXN'>
                            <MenuItem value={'EUR'}>
                                Euro
                            </MenuItem>
                            <MenuItem value={'MXN'}>
                                Peso mexicano
                            </MenuItem>
                            <MenuItem value={'USD'}>
                                Dólar estadounidense
                            </MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} xl={2}>
                    <TextField
                        margin="dense"
                        name="exchange"
                        label="Tipo Cambio"
                        type="text"
                        placeholder='Escribe Tipo Cambio'
                        variant="filled"
                        disabled={complementData?.currency === '' || complementData?.currency === 'MXN'}
                        value={complementData.exchange}
                        onChange={handleChangeExchange}
                        fullWidth
                    />
                </Grid>
                <Grid container justifyContent="space-between" alignItems={'center'}
                    marginBottom={'1%'} marginTop={'2%'} marginLeft={'4%'}>
                    <Grid item xs={12} sm={6} xl={6}>
                        <Typography variant="h7" fontWeight="550"  >
                            Documentos relacionados del comprobante
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>

                        <DocumentDynamicGenerator
                            setHiddenDocument={setHiddenDocument}
                            setNumberDocument={setNumberDocument}
                            numberDocument={numberDocument}
                            relatedDocuments={relatedDocuments}
                            setRelatedDocuments={setRelatedDocuments}

                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} xl={12} marginLeft={'3%'}>
                    {
                        Array.from(Array(numberDocument), (e, i) => {
                            return (

                                <RelatedDocumentsNewComplementsBill
                                    key={i}
                                    keyId={i}
                                    hiddenDocument={hiddenDocument}
                                    relatedDocuments={relatedDocuments}
                                    setRelatedDocuments={setRelatedDocuments}
                                />

                            )
                        })
                    }


                </Grid>
            </Grid>

        </>
    )
}

export default ComplementsNewComplementBill