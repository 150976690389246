import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Autocomplete,
  InputAdornment,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  Switch,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { axiosEditProductFacturaApi } from "../../api/axiosProducts";
import { useFetchProdServCodes } from "../../hooks/useFetchProdServCodes";
import { useFetchUnitCodes } from "../../hooks/useFetchUnitCodes";

const EditProduct = ({
  product,
  setProduct,
  handleToggle,
  isOpen,
  triggerAxiosPaginate,
}) => {
  const [loading, setloading] = useState(false);
  const [searchProdServ, setSearchProdServ] = useState([]);
  const [searchUnitCode, setSearchUnitCode] = useState([]);
  const { data: prodServCodes } = useFetchProdServCodes(searchProdServ);
  const { data: unitCodes } = useFetchUnitCodes(searchUnitCode);

  const handlerProdServSearch = (e, val, reason) => {
    if (reason !== "reset") {
      setSearchProdServ(val);
    }
  };

  const handlerUnitCodeSearch = (e, val, reason) => {
    if (reason !== "reset") {
      setSearchUnitCode(val);
    }
  };

  const handlerProdServSelected = (option) => {
    if (option != null) {
      return setProduct({
        ...product,
        prod_serv_id: option.code,
        prodServCodeText: `${option.code} - ${option.description}`,
      });
    }
    return setProduct({
      ...product,
      prod_serv_id: "",
      prodServCodeText: "",
    });
  };
  const handlerUnitCodeSelected = (option) => {
    if (option != null) {
      return setProduct({
        ...product,
        unit_code_id: option.code,
        unitCodeText: `${option.code} - ${option.description}`,
      });
    }
    return setProduct({
      ...product,
      unit_code_id: "",
      unitCodeText: "",
    });
  };

  const handleUpdateProduct = async () => {
    setloading(true);
    axiosEditProductFacturaApi(product)
      .then((response) => {
        if (response.status === 200) {
          setloading(false);
          if (response.data.status === true) {
            toast.success(response.data.message);
            setTimeout(() => {
              handleToggle();
              triggerAxiosPaginate(1);
            }, 15);
          } else {
            toast.warning(response.data.message);
          }
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
        toast.error("Error en la Red");
      });
  };
  const onChange = (event) => {
    setProduct({ ...product, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    var tIva = 0;
    var tIsr = 0;
    var tIeps = 0;
    var tRiva = 0;
    const asignTaxes = (taxes) => {
      taxes.length === 0
        ? (tIva = 1)
        : taxes.map((t) => {
            switch (t.type) {
              case "IVA":
                if (t.factor === "Exento") {
                  tIva = "Exempt";
                } else {
                  tIva = t.rate;
                }
                break;

              case "ISR":
                tIsr = t.rate;
                break;

              case "IEPS":
                tIeps = t.rate * 100;
                break;

              default:
                console.log("error");
                break;
            }
          });
    };

    const asignLocalTaxes = (local_taxes) => {
      local_taxes.map((l_t) => {
        tRiva = l_t.rate;
      });

      setProduct({
        ...product,
        riva: tRiva,
      });
    };

    if (product?.taxes) asignTaxes(product.taxes);
    if (product?.local_taxes) asignLocalTaxes(product.local_taxes);

    setProduct({
      ...product,
      iva: tIva,
      isr: tIsr,
      ieps: tIeps,
      riva: tRiva,
    });
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      onClose={() => handleToggle()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Edición del Producto o servicio <b>{product?.description}. </b>{" "}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="text-code-val"
              margin="dense"
              label="Clave del producto o servicio"
              disabled
              name="text-code-val"
              value={product.product_key}
              variant={"filled"}
              size={"small"}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="text-unit-val"
              margin="dense"
              label="Clave de unidad"
              disabled
              name="text-code-val"
              value={product.unit_key + " - " + product.unit_name}
              variant={"filled"}
              size={"small"}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="combo-prod-serv-codes"
              loadingText="Cargando..."
              options={prodServCodes}
              onChange={(e, prodServ) => handlerProdServSelected(prodServ)}
              getOptionLabel={(prodServ) =>
                prodServ ? `${prodServ.code} - ${prodServ.description}` : ""
              }
              onInputChange={(e, newInputValue, reason) =>
                handlerProdServSearch(e, newInputValue, reason)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => handlerProdServSelected((e, params))}
                  value={product.prod_serv_id}
                  margin="dense"
                  label="Clave de producto o servicio"
                  name="prod_serv_id"
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              id="combo-unit-codes"
              options={unitCodes}
              onChange={(e, unitCode) => handlerUnitCodeSelected(unitCode)}
              getOptionLabel={(unitCode) =>
                `${unitCode.code} - ${unitCode.description}`
              }
              onInputChange={handlerUnitCodeSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => handlerUnitCodeSelected((e, params))}
                  value={product.unit_code_id}
                  margin="dense"
                  label="Clave de unidad"
                  name="prod_serv_id"
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="outlined-basic"
              margin="dense"
              label="Descripción"
              name="description"
              onChange={onChange}
              value={product.description}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="outlined-basic"
              margin="dense"
              label="SKU"
              name="sku"
              onChange={onChange}
              value={product.sku}
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Precio"
              name="price"
              onChange={onChange}
              value={product.price}
              variant="filled"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ px: "0 !important" }}>
            <FormControlLabel
              value={product.tax_included}
              control={<Switch checked={product.tax_included} />}
              onClick={() =>
                setProduct({
                  ...product,
                  tax_included: !product.tax_included,
                })
              }
              label="El precio ya incluye impuesto"
              labelPlacement="end"
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl variant="filled" margin="dense" fullWidth>
              <InputLabel id="percent-taxability-label">ObjetoImp</InputLabel>
              <Select
                labelId="percent-taxability-label"
                id="percent-taxability"
                name="taxability"
                value={product.taxability}
                fullWidth
                onChange={onChange}
                label="ObjetoImp"
                margin="dense"
              >
                <MenuItem value={"01"}>No objeto de impuesto.</MenuItem>
                <MenuItem value={"02"}>Sí objeto de impuesto.</MenuItem>
                <MenuItem value={"03"}>
                  Sí objeto de impuesto, pero no obligado a desglose.
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left">Impuestos federales del producto</Divider>
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="filled" margin="dense" fullWidth>
              <InputLabel id="percent-iva-label">IVA %</InputLabel>
              <Select
                labelId="percent-iva-label"
                id="percent-iva"
                name="iva"
                value={product.iva}
                fullWidth
                defaultValue=""
                margin="dense"
                onChange={onChange}
                label="IVA %"
              >
                <MenuItem value={0.16}>16.00%</MenuItem>
                <MenuItem value={0.08}>8.00%</MenuItem>
                <MenuItem value={0.0}>0%</MenuItem>
                <MenuItem value={1}>
                  <em>Sin Iva</em>
                </MenuItem>
                <MenuItem value="Exempt">
                  <em>Exento</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="filled" margin="dense" fullWidth>
              <InputLabel id="percent-retiva-label">Retención IVA %</InputLabel>
              <Select
                labelId="percent-retiva-label"
                id="percent-retiva"
                name="riva"
                value={product.riva}
                fullWidth
                onChange={onChange}
                label="Retención IVA %"
                margin="dense"
              >
                <MenuItem value="0">
                  <em>Sin retención</em>
                </MenuItem>
                <MenuItem value={0.10667}>2/3</MenuItem>
                <MenuItem value={0.06}>6.00%</MenuItem>
                <MenuItem value={0.04}>4.00%</MenuItem>
                <MenuItem value={0.03}>3.00%</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="filled" margin="dense" fullWidth>
              <InputLabel id="percent-isr-label">Retención ISR %</InputLabel>
              <Select
                labelId="percent-isr-label"
                id="percent-isr"
                name="isr"
                value={product.isr}
                fullWidth
                onChange={onChange}
                label="Retención  ISR %"
                margin="dense"
              >
                <MenuItem value="0">
                  <em>Sin ISR</em>
                </MenuItem>
                <MenuItem value={0.1}>10.00%</MenuItem>
                <MenuItem value={0.0125}>1.25%</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              margin="dense"
              label="IEPS"
              name="ieps"
              type="number"
              onChange={onChange}
              value={product.ieps}
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 4 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => handleUpdateProduct()}
          color="primary"
          variant="contained"
        >
          Editar
        </LoadingButton>
        <Button onClick={() => handleToggle()}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProduct;
