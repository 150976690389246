import React from "react";
import "./card.scss";
import { Button, Grid } from "@mui/material";
import { Delete } from "@mui/icons-material";

const CardPaymentConfig = ({
  payment,
  handleUpdateCardDefault,
  handleDeleteCard,
  index,
}) => {
  return (
    <>
      <Grid container item xs={12}>
        <div className={`credit-card  ${payment?.brand} selectable}`}>
          <div className="credit-card-last4">{payment?.last4}</div>
          <div className="credit-card-expiry">
            {payment?.exp_month} / {payment?.exp_year}
          </div>
        </div>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        xs={12}
        direction="column"
      >
        <Grid item xs={4}>
          <Button
            size="small"
            color="primary"
            variant={payment?.default ? "contained" : "outlined"}
            sx={{ m: 1 }}
            onClick={() => {
              handleUpdateCardDefault(payment.id);
            }}
          >
            {payment?.default ? "Principal" : "Usar como principal"}
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            size="small"
            color="error"
            variant="contained"
            endIcon={<Delete />}
            sx={{ m: 1 }}
            onClick={() => {
              handleDeleteCard(index);
            }}
          >
            Eliminar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CardPaymentConfig;
