import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CircularProgress,
  Box,
} from "@mui/material";

import { useNavigate } from "react-router";
import { Cancel, Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import { axiosStoreComplentBillFacturaApi } from "../../../api/axiosComplements";

const ButtonsNewComplementsBill = ({ complementBill }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //Cancelar Complementofactura
  const handleCancel = () => {
    navigate("/bills");
  };

  const validateComplementBillData = () => {
    let isNotNull = false;

    if (
      complementBill.customer_id &&
      complementBill.date &&
      complementBill.series &&
      complementBill.folio_number &&
      complementBill.complements.data.payment_form &&
      complementBill.complements.data.date &&
      complementBill.complements.data.numOperacion &&
      complementBill.complements.data.related_documents[0].uuid &&
      complementBill.complements.data.related_documents[0].amount &&
      complementBill.complements.data.related_documents[0].installment &&
      complementBill.complements.data.related_documents[0].last_balance &&
      complementBill.complements.data.related_documents[0].taxes.iva &&
      complementBill.complements.data.related_documents[0].taxes.isr &&
      complementBill.relation
    ) {
      isNotNull = true;
    }
    return isNotNull;
  };

  //guardar factura terminada
  const handleStoreBill = () => {
    setLoading(true);

    axiosStoreComplentBillFacturaApi(complementBill)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data.status === true) {
            toast.success("Factura creada.");
            setLoading(false);

            setTimeout(() => {
              navigate("/bills");
            }, 2000);
          } else {
            toast.warning(res.data.message);
          }
        } else {
          toast.warning(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error(
            "Ha ocurrido un problema al almacenar los datos de facturas, inténtelo nuevamente más tarde."
          );
        }
        return err;
      });
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Card variant="outlined" sx={{ p: 0 }}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid
                item
                sm={6}
                lg={6}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  display="flex"
                  alignitems="center"
                  variant="contained"
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="error"
                  onClick={handleCancel}
                >
                  <Typography display="flex" alignItems="center">
                    <Cancel />
                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Cancelar
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sm={6}
                lg={6}
                xs={12}
                // display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  display="flex"
                  alignitems="center"
                  variant="contained"
                  onClick={handleStoreBill}
                  disabled={!validateComplementBillData()}
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="success"
                >
                  <Typography display="flex" alignItems="center">
                    {!loading ? (
                      <Check />
                    ) : (
                      <CircularProgress color="info" size={20} />
                    )}
                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Guardar
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default ButtonsNewComplementsBill;
