//.env
const {REACT_APP_TOKEN} = process.env;
const {REACT_APP_HOST} = process.env;
const {REACT_APP_TEST} = process.env;
const {REACT_APP_CONEKTA_PUBLIC_KEY} = process.env;

export const TOKEN = REACT_APP_TOKEN;
export const API_HOST = REACT_APP_HOST;
export const APP_TEST = REACT_APP_TEST;
export const CONEKTA_KEY = REACT_APP_CONEKTA_PUBLIC_KEY;


