import React, {  useRef } from 'react'
import { AddCircleTwoTone, CancelTwoTone } from '@mui/icons-material'
import { Fab, Grid} from '@mui/material'


export const DocumentDynamicGenerator = ({
    setHiddenDocument,
    setNumberDocument,
    numberDocument,
    setRelatedDocuments

}) => {
    
    const lastRef = useRef(null);

    const executeScroll = () => lastRef.current.scrollIntoView(true, { behavior: "smooth" });

    const handlerAddDocument = () => {

        setNumberDocument(numberDocument + 1);

        // Allows zoom transition before delete
        setTimeout(() => {

            executeScroll();

        }, 10);

    }

    const handlerSubstractDocument = () => {

        // Remove last element on queue
        setRelatedDocuments (prevRelatedDocuments => {

            return prevRelatedDocuments.filter(
                (_, i) => i !== prevRelatedDocuments.length - 1
            );

        });


        setHiddenDocument(oldArray => ([...oldArray, numberDocument - 1]));

        // Allows zoom transition before delete
        setTimeout(() => {

            setNumberDocument(numberDocument - 1);

            executeScroll();

            setHiddenDocument([]);

        }, 50);

    }
    return (
        <>
            <Grid container alignItems="center" justifyContent='end' >

                <Grid item xs={3} md={3} lg={3} style={{ textAlign: "center" }} ref={lastRef} >

                    <Fab
                        color="primary"
                        onClick={handlerAddDocument} >
                        <AddCircleTwoTone />
                    </Fab>

                </Grid>
                <Grid item xs={3} md={3} lg={3} style={{ textAlign: "center" }}>
                    <Fab
                        disabled={numberDocument <= 1}
                        color="secondary"
                        onClick={handlerSubstractDocument} >
                        <CancelTwoTone />
                    </Fab>
                </Grid>

            </Grid>

        </>
    )
}
