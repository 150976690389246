import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import {
  validateRfc,
  validatePhoneNumber,
  validateEmail,
  validatePostalCode,
} from "../../utils/validations";
import { axiosGetRegimes } from "../../api/regimes";

import { axiosEditClientFacturaApi } from "../../api/axiosClients";

import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import ClientEditAbroad from "./ClientEditAbroad";
import ClientEdit from "./ClientEdit";

const EditClients = ({
  client,
  handleToggle,
  isOpen,
  triggerAxiosPaginate,
}) => {
  const [clientEdit, setClientEdit] = useState({});
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setClientEdit({
      id: client?.id,
      legal_name: client?.legal_name,
      tax_id: client?.tax_id,
      tax_system: client?.tax_system,
      email: client?.email,
      zip: client?.address?.zip,
      street: client?.address?.street,
      exterior: client?.address?.exterior,
      phone: client?.phone,
      country: client?.address?.country,
    });
  }, [client]);

  const handleUpdateClient = async () => {
    setloading(true);
    axiosEditClientFacturaApi(clientEdit)
      .then((response) => {
        if (response.status === 200) {
          setloading(false);
          if (response.data.status === true) {
            toast.success(response.data.message);
            setTimeout(() => {
              handleToggle();
              triggerAxiosPaginate(1);
            }, 15);
          } else {
            toast.warning(response.data.message);
          }
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
        toast.error("Error en la Red");
      });
  };

  const onChange = (event) => {
    setClientEdit({ ...clientEdit, [event.target.name]: event.target.value });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleToggle()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edición de cliente</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Editar los datos del cliente <b>{clientEdit?.legal_name}.</b>
        </DialogContentText>
        <br />
        <form
          onChange={onChange}
          // onSubmit={onSubmit}
        >
          {clientEdit?.tax_system === "616" ? (
            <ClientEditAbroad onChange={onChange} clientEdit={clientEdit} />
          ) : (
            <ClientEdit clientEdit={clientEdit} onChange={onChange} />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => handleUpdateClient()}
          color="primary"
          variant="contained"
        >
          Editar
        </LoadingButton>
        <Button onClick={() => handleToggle()}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClients;
