import React, { useState, useEffect } from "react";
import { useGetLists } from "../../hooks/getLists";
import { validatePhoneNumber, validateEmail } from "../../utils/validations";
import { Grid, MenuItem, TextField } from "@mui/material";

const ClientEditAbroad = ({ onChange, clientEdit }) => {
  const { getCountries } = useGetLists();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries({ setCountries });
  }, []);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientSocialReason"
            name="legal_name"
            label="Razón Social"
            type="text"
            variant="outlined"
            disabled
            onChange={onChange}
            value={clientEdit?.legal_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            select
            margin="dense"
            id="clientCountry"
            name="country"
            label="País"
            type="text"
            variant="outlined"
            required
            value={clientEdit.country}
            onChange={onChange}
            fullWidth
          >
            {countries?.length > 0 ? (
              countries?.map((list) => (
                <MenuItem key={list.code} value={list.code}>
                  {list.name}
                </MenuItem>
              ))
            ) : (
              <div></div>
            )}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientRfc"
            label="Número de registro"
            type="text"
            variant="outlined"
            name="tax_id"
            required
            value={clientEdit.tax_id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientEmail"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            onChange={onChange}
            value={clientEdit?.email}
            fullWidth
            error={
              !validateEmail(clientEdit?.email) && clientEdit?.email !== ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientPhone"
            name="phone"
            label="Teléfono"
            type="tel"
            variant="outlined"
            required
            onChange={onChange}
            value={clientEdit?.phone}
            fullWidth
            error={
              !validatePhoneNumber(clientEdit?.phone) &&
              clientEdit?.phone !== ""
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClientEditAbroad;
