import React, { useEffect } from "react";
import {
  Avatar,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import DialogBuySubscritions from "./Subscriptions/DialogBuySubscritions";
import DialogBuyBells from "./Bells/DialogBuyBells";
import { axiosGetInfoCustomer } from "../../api/axiosConekta";
import { axiosGetPlans } from "../../api/userInfo";
import { toast } from "react-toastify";

const CardInfoSubscriptionShop = () => {
  const [openShopSubscriptionModal, setOpenShopSubscriptionModal] =
    useState(false);
  const [openShopBellModal, setOpenShopBellModal] = useState(false);
  const [info, setInfo] = useState([]);
  const [bells, setBells] = useState(0);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const handlerShopSubscriptionModal = () => {
    setOpenShopSubscriptionModal(!openShopSubscriptionModal);
  };

  const handlerShopBellsModal = () => {
    setOpenShopBellModal(!openShopBellModal);
  };
  const handleInfo = () => {
    axiosGetInfoCustomer()
      .then((response) => {
        setInfo(response.data.data);
        setLoading(false);
        setBells(response.data.bells);
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    handleInfo();
  }, []);

  useEffect(() => {
    axiosGetPlans()
      .then((response) => {
        let {
          data: { data: data },
        } = response;
        setPlans(data);
      })
      .catch((err) => {
        toast.error("Error al cargar Planes", err);
      });
  }, []);

  return (
    <>
      <List>
        <ListItem
          secondaryAction={
            <>
              <Chip
                label={
                  loading ? (
                    <Skeleton width={50} />
                  ) : info?.subscription?.status === "active" ? (
                    "Activo"
                  ) : (
                    "Inactivo"
                  )
                }
                variant="outlined"
                color={
                  info?.subscription?.status === "active" ? "success" : "error"
                }
              />

              <IconButton
                edge="end"
                aria-label="subcripcion"
                onClick={() => {
                  handlerShopSubscriptionModal();
                }}
              >
                <ShoppingCartIcon />
              </IconButton>
            </>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <SubtitlesIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Plan Activo"
            secondary={
              loading ? (
                <Skeleton variant="text" width={210} />
              ) : (
                plans
                  .filter(
                    (item) => item.conekta_name === info?.subscription?.plan_id
                  )
                  .map((plan) => plan.name)
              )
            }
          />
        </ListItem>
        <ListItem
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="timbres"
              onClick={() => {
                handlerShopBellsModal();
              }}
            >
              <ShoppingCartIcon />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <NotificationsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Timbres"
            secondary={
              loading ? (
                <Skeleton variant="text" width={210} />
              ) : (
                bells + " folios"
              )
            }
          />
        </ListItem>
      </List>

      <DialogBuySubscritions
        openShopSubscriptionModal={openShopSubscriptionModal}
        handlerShopSubscriptionModal={handlerShopSubscriptionModal}
        info={info}
        handleInfo={handleInfo}
      />

      <DialogBuyBells
        openShopBellModal={openShopBellModal}
        handlerShopBellsModal={handlerShopBellsModal}
        handleInfo={handleInfo}
      />
    </>
  );
};

export default CardInfoSubscriptionShop;
