import jsPDF from "jspdf";

export const generateOxxoPDF = (oxxoLogo, price, reference, pack,code) => {
    
    let doc = new jsPDF("p", "px", 'a4');

    doc.addImage(oxxoLogo, 'PNG', 25, 40, 170, 50);
    doc.setFillColor(0,0,0);
    doc.rect(26, 0, 395, 30, 'F');
    doc.setTextColor(255, 255, 255);
    doc.text(100,25, 'FICHA DIGITAL. NO ES NECESARIO IMPRIMIR.');
    doc.setTextColor(77, 77, 77);
    doc.text(230, 60, 'MONTO A PAGAR');

    //PRICE VARIABLE
    doc.text(230, 85, '$'+ price);
    doc.setFontSize(9);
    doc.text(280, 75, 'MXN');
    doc.text(230, 100, 'OXXO cobrará una comisión adicional al');
    doc.text(230, 110, 'momento realizar el pago.');

    //REFERENCE VARIABLE
    doc.setFontSize(16);
    doc.text(33, 150, 'REFERENCIA');
    doc.setFillColor(248,249,250);
    doc.setDrawColor(176,175,181);
    doc.roundedRect(33, 160, 380, 40, 5, 5, 'FD');
    
    doc.setFontSize(26);
    doc.text(145, 188, reference);


    //PACK VARIABLE
    doc.setFontSize(16);
    doc.text(33, 230, 'COMPRA');
    doc.setFillColor(248,249,250);
    doc.setDrawColor(176,175,181);
    doc.roundedRect(33, 240, 380, 40, 5, 5, 'FD');
   
    doc.setFontSize(26);
    doc.text(165, 268, pack);

    // //IMG code
    // doc.addImage(code, 'JPG', 25, 40, 170, 50);
    //INTRUCTIONS
    doc.setFillColor(248,249,250);
    doc.setDrawColor(176,175,181);
    doc.rect(1, 300, 450, 250, 'FD');
  
    doc.setFontSize(16);
    doc.text(33, 340, 'INSTRUCCIONES');
    doc.setFontSize(12);
    doc.text(33, 355, '1. Acude a la tienda OXXO más cercana,');
    doc.setTextColor(102, 153, 255);
    doc.textWithLink(' encuentrala aquí.', 197, 355, {url: 'https://www.google.com.mx/maps/search/oxxo/'});
    doc.setTextColor(77, 77, 77);
    doc.text(33, 370, '2. Indica en caja que quieres realizar un pago de ');
    
    doc.text(228, 370, ' OXXOPay.');
    
    doc.text(33, 385, '3. Dicta al cajero el número de referencia en esta ficha.');
    doc.text(33, 410, '4. Realiza el pago correspondiente con dinero en efectivo.');
    doc.text(33, 425, '5. Al confirmar tu pago, el cajero te entregará un comprobante impreso.');
  
    doc.text(320, 425, 'En el podrás verificar');
    doc.text(40, 435, ' que se haya realizado correctamente.');
    
    doc.text(33, 450, '6. Conserva este comprobante de pago.');

    //FOOTER
    doc.setFillColor(255, 255, 255);
    doc.setDrawColor(69, 161, 141);
    doc.roundedRect(40, 470, 372, 40, 5, 5, 'FD');
    doc.setTextColor(69, 161, 141);
    doc.text(55, 493, 'Al completar estos pasos recibirás un correo de Facturalite confirmando tu pago');


    doc.save('oxxo-pago.pdf');
}
  
