import jsPDF from "jspdf";
import statusUuid from "../../utils/statusUuid.json";
export const acusePDF = (data, id) => {
  const date = `${data.fecha}`;
  const rfc = `${data.RfcEmisor}`;
  const uuid = `${data.UUID}`;
  const status = `${data.EstatusUUID}`;
  const sello = `${data.Signature}`;

  const statusText = statusUuid
    ?.filter((option) => option.code === status)
    .map((text) => text.code + " - " + text.message);

  var doc = new jsPDF("p", "px", "a4");
  //title
  doc.setFontSize(22);
  doc.text(20, 40, "Acuse de solicitud de Cancelación de CFDI.");
  //fecha
  doc.setFontSize(18);
  doc.setFont(undefined, "bold");
  doc.text(20, 100, "Fecha y hora de solicitud:");
  doc.setFont(undefined, "normal");
  doc.text(200, 100, date);

  //RFC
  doc.setFont(undefined, "bold");
  doc.text(20, 140, "RFC Emisor:");
  doc.setFont(undefined, "normal");
  doc.text(200, 140, rfc);

  //Table
  doc.setFontSize(16);
  doc.setDrawColor(35, 59, 99);
  //Folio Title cuadro
  doc.rect(20, 180, 200, 40);
  //Folio title text
  doc.setFont(undefined, "bold");
  doc.text(25, 195, "Folio Fiscal");
  //Folio Text cuadro
  doc.rect(20, 220, 200, 50);
  //Folio Text text
  doc.setFont(undefined, "normal");
  var splitUuid = doc.splitTextToSize(uuid, 182);
  doc.text(25, 235, splitUuid);

  //Estatus Title cuadro
  doc.rect(220, 180, 200, 40);
  //Estatus title text
  doc.setFont(undefined, "bold");
  var splitStatusTitle = doc.splitTextToSize(
    "Estatus de Proceso de Cancelación",
    182
  );
  doc.text(225, 195, splitStatusTitle);
  //Estatus Text cuadro
  doc.rect(220, 220, 200, 50);
  //Estatus Text text
  doc.setFont(undefined, "normal");
  var splitStatus = doc.splitTextToSize(statusText, 180);
  doc.text(225, 235, splitStatus);

  //Sello Title cuadro
  doc.rect(20, 280, 400, 60);
  //Sello title text
  doc.setFont(undefined, "bold");
  doc.text(25, 295, "Sello Digital SAT:");
  //Sello Text text
  doc.setFont(undefined, "normal");
  var splitSello = doc.splitTextToSize(sello, 390);
  doc.text(25, 310, splitSello);
  doc.save(`Acuse_${id}.pdf`);
};
