import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Slide,
  Switch,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { values, size } from "lodash";

import {
  validateEmail,
  validatePostalCode,
  validatePhoneNumber,
  validateRfc,
} from "../../utils/validations";
import { axiosCreateClientFacturaApi } from "../../api/axiosClients";
import { toast } from "react-toastify";
import AddClients from "./AddClients";
import AddClientsAbroad from "./AddClientsAbroad";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  floatRight: {
    float: "right",
  },
});

const NewClients = ({
  btnText,
  originPosition,
  triggerAxiosPaginate,
  getSearchClients,
  n = false,
}) => {
  const classes = useStyles();
  // UX / UI states
  const [open, setOpen] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState(false);

  // Data States
  const [client, setClient] = useState({
    legal_name: "",
    tax_id: "",
    tax_system: "",
    country: "",
    email: "",
    zip: "",
    street: "",
    exterior: "",
    phone: "",
    foreign: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setActiveSwitch(false);
    setClient({
      legal_name: "",
      tax_id: "",
      tax_system: "",
      email: "",
      zip: "",
      country: "",
      street: "",
      exterior: "",
      phone: "",
      foreign: false,
    });
    setOpen(false);
  };

  const handleStoreClient = (e) => {
    e.preventDefault();
    if (activeSwitch === true) {
      if (client.legal_name === "") {
        toast.warning("Completa el campo de nombre fiscal.");
      } else if (client.country === "") {
        toast.warning("Completa el campo del código de país.");
      } else if (client.tax_id === "") {
        toast.warning("Completa el campo de numero de registro.");
      } else {
        axiosCreateClientFacturaApi(client)
          .then((response) => {
            if (response.data.status === false) {
              toast.warning(response.data.message);
            } else {
              toast.success("Cliente añadido exitosamente.");
              setTimeout(() => {
                handleClose();
                if (n === false) {
                  triggerAxiosPaginate(1);
                } else {
                  getSearchClients();
                }

                setClient({
                  legal_name: "",
                  tax_id: "",
                  tax_system: "",
                  country: "",
                  email: "",
                  zip: "",
                  street: "",
                  exterior: "",
                  phone: "",
                  foreign: false,
                });

                setActiveSwitch(false);
              }, 10);
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.warning(err.response.data.message);
            } else {
              toast.error(`Ocurrió un error al realizar la petición`);
            }
          });
      }
    } else {
      if (
        client.legal_name === "" ||
        client.tax_id === "" ||
        client.tax_system === "" ||
        client.street === "" ||
        client.exterior === "" ||
        client.zip === "" ||
        client.email === "" ||
        client.phone === ""
      ) {
        toast.warning("Completa todos los campos del formulario.");
      } else if (!validateRfc(client.tax_id)) {
        toast.warning("Hay un error en el RFC.");
      } else if (!validatePhoneNumber(client.phone)) {
        toast.warning("Hay un error en el numero de teléfono.");
      } else if (!validatePostalCode(client.zip)) {
        toast.warning("Hay un error en el Código Postal.");
      } else if (!validateEmail(client.email)) {
        toast.warning("Hay un error en el email .");
      } else {
        axiosCreateClientFacturaApi(client)
          .then((response) => {
            if (response.data.status === false) {
              toast.warning(response.data.message);
            } else {
              toast.success(response.data.message);
              setTimeout(() => {
                handleClose();
                if (n === false) {
                  triggerAxiosPaginate(1);
                } else {
                  getSearchClients();
                }

                setClient({
                  legal_name: "",
                  tax_id: "",
                  tax_system: "",
                  email: "",
                  zip: "",
                  street: "",
                  exterior: "",
                  phone: "",
                });
              }, 10);
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.warning(err.response.data.message);
            } else {
              toast.warning("Error en la red");
            }
          });
      }
    }
  };

  useEffect(() => {
    setClient({ ...client, foreign: activeSwitch });
  }, [activeSwitch]);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className={originPosition && classes.floatRight}
      >
        {btnText}
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="dialog-title-add-client"
        aria-describedby="dialog-title-add-description"
      >
        <DialogTitle id="dialog-title-add-client">
          <Grid item container justifyContent="space-between" mr={2}>
            <Grid item>
              <Typography>{"Añadir Cliente"}</Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={activeSwitch}
                control={<Switch color="primary" checked={activeSwitch} />}
                onClick={() => setActiveSwitch(!activeSwitch)}
                label="¿Es un cliente publico en extranjero? "
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-title-add-description">
            Ingresa los datos del nuevo cliente
          </DialogContentText>

          {activeSwitch ? (
            <AddClientsAbroad client={client} setClient={setClient} />
          ) : (
            <AddClients client={client} setClient={setClient} />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleStoreClient}
          >
            Registrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewClients;
