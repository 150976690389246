import React, { useState } from "react";
import {
  List,
  ListItemText,
  ListItem,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemAvatar,
  Typography,
  TextField,
  Avatar,
} from "@mui/material";
import { moneyFormat } from "../../../utils/formats";
import {
  Description,
  Delete,
  Edit,
  Done,
  DoDisturb,
} from "@mui/icons-material";
const ListConceptsNewBill = ({ concepts, setConcepts }) => {
  const [previous, setPrevious] = useState({});

  const handlerSubstractConcept = (concept) => {
    if (concepts.length !== 1) {
      const filterConcept = concepts.filter(
        (item) => item.conceptId !== concept.conceptId
      );

      setConcepts(filterConcept);
    }
  };

  const handlerEditConcept = (id) => {
    // setSwitchEdit(true);
    setConcepts((state) => {
      return concepts.map((row) => {
        if (row.conceptId === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  const onToggleEditMode = (id) => {
    setConcepts((state) => {
      return concepts.map((row) => {
        if (row.conceptId === id) {
          return { ...row, isEditMode: !row.isEditMode, edit: true };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.conceptId]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;

    const { conceptId } = row;
    const newRows = concepts.map((row, index) => {
      if (row.conceptId === conceptId) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setConcepts(newRows);
  };

  const onRevert = (id) => {
    const newRows = concepts.map((row, index) => {
      if (row.conceptId === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setConcepts(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  return (
    <>
      <Grid item xs={12} md={12} lg={12} marginTop={2}>
        {concepts.length <= 0 ? (
          <List
            dense={true}
            sx={{
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
              borderRadius: "10px",
            }}
          >
            <ListItem>
              <ListItemText primary={"Sin Conceptos"} />
            </ListItem>
          </List>
        ) : (
          concepts.map((concept, index) => (
            <>
              <List
                dense={true}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "10px",
                }}
              >
                <ListItem key={index}>
                  <Grid container>
                    <ListItemAvatar>
                      <Avatar>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>

                    {concept.isEditMode ? (
                      <Grid item xs={12} md={2}>
                        <ListItemText
                          primary={concept?.product_key}
                          secondary={
                            <TextField
                              size="small"
                              value={concept?.description}
                              name="description"
                              onChange={(e) => onChange(e, concept)}
                            />
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={3}>
                        <ListItemText
                          primary={concept?.product_key}
                          secondary={
                            <Typography
                              color="textSecondary"
                              whiteSpace={"break-spaces"}
                              sx={{
                                width: "100%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {concept?.description}
                            </Typography>
                          }
                        />
                      </Grid>
                    )}

                    {concept?.taxes.length === 0 ? (
                      <Grid item xs={6} md={2}>
                        <ListItemText
                          primary={"Impuestos"}
                          secondary={"Sin impuestos"}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={6} md={2}>
                        <ListItemText
                          primary={concept?.taxes[0]?.type}
                          secondary={concept?.taxes[0]?.rate * 100 + "% "}
                        />
                      </Grid>
                    )}
                    {concept.isEditMode ? (
                      <Grid item xs={6} md={2}>
                        <ListItemText
                          primary={"Precio"}
                          secondary={
                            <TextField
                              size="small"
                              value={concept.price}
                              name="price"
                              type={"number"}
                              onChange={(e) => onChange(e, concept)}
                            />
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={6} md={2}>
                        <ListItemText
                          primary={"Precio"}
                          secondary={"$ " + moneyFormat(concept.price)}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6} md={2}>
                      <ListItemText
                        primary={"Cantidad"}
                        secondary={concept?.quantity}
                      />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <ListItemText
                        primary={"Importe Total"}
                        secondary={
                          "$ " + moneyFormat(concept.price * concept?.quantity)
                        }
                      />
                    </Grid>
                  </Grid>
                  <ListItemSecondaryAction
                    sx={{
                      position: { xs: "relative", md: "absolute" },
                      transform: {
                        xs: "translateX(30%)",
                        md: "translateY(-50%)",
                      },
                      top: { xs: "0", md: "50%" },
                      right: { xs: "0", md: "16px" },
                    }}
                  >
                    {concept.isEditMode ? (
                      <>
                        <IconButton
                          aria-label="done"
                          onClick={() => onToggleEditMode(concept.conceptId)}
                        >
                          <Done />
                        </IconButton>
                        <IconButton
                          aria-label="revert"
                          onClick={() => onRevert(concept.conceptId)}
                        >
                          <DoDisturb />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handlerEditConcept(concept.conceptId);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handlerSubstractConcept(concept);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </>
          ))
        )}
      </Grid>
    </>
  );
};

export default ListConceptsNewBill;
