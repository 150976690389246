import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button
} from '@mui/material'

import ListChangeCardDefaulPayments from '../Payments/ListChangeCardDefaulPayments';
import { useState } from 'react';
import { axiosUpdateDefaultCard } from '../../../api/axiosConekta';
import { toast } from 'react-toastify';

const CardChangePayment = ({ handleClickActiveData }) => {
    const [selectedPayment, setSelectedPayment] = useState('e');

    const handleChangePayment = () => {
       
        axiosUpdateDefaultCard(selectedPayment)
            .then(response => {
                if(response.data.status === true){

                    toast.success("Tarjeta seleccionada como principal");
                    handleClickActiveData();
                }else {
                    toast.warning(response.data.message);
                }
               
            }).catch(err => {
                toast.warning("Error al seleccionar como principal");
                return err;
            })
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom component="div" align='center'>
                    Métodos de pago
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Card variant='outlined' sx={{ p: 2, bgcolor: 'rgba(0,0,0,.03)' }}>
                    <CardContent>
                        <ListChangeCardDefaulPayments
                            selectedPayment={selectedPayment}
                            setSelectedPayment={setSelectedPayment} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Button variant='contained' onClick={() => handleChangePayment()}> Aceptar y Cambiar </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => handleClickActiveData()}> Volver </Button>
            </Grid>
        </>
    )
}

export default CardChangePayment