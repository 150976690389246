import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
  Slide,
  List,
  ListItem,
  ListItemText,
  Grid,
  Divider,
} from "@mui/material";
import LinearWithValueLabel from "../../../layouts/LinearWithValueLabel";
import { useSendBills } from "../../../hooks/useSendBills";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

const DialogConfirmMassSendBills = ({
  openConfirmMassModal,
  handlerConfirmMassModal,
  sendBills,
  sendEmails,
  isClient,
  handleCancel,
}) => {
  const { comfimsendBills } = useSendBills();
  const [count, setCount] = useState(0);
  const [active, setActive] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [listStatusBills, setListStatusBills] = useState([]);
  const handlerSendBills = () => {
    comfimsendBills({
      sendEmails,
      sendBills,
      isClient,
      setCount,
      setActive,
      setListStatusBills,
      setSuccessCount,
    });
    setActive(true);
  };

  useEffect(() => {
    if (openConfirmMassModal === false) {
      setCount(0);
      setListStatusBills([]);
      setActive(false);
    }
  }, [openConfirmMassModal]);
  const downloadExcel = () => {
    let header = ["Folio", "Email", "Mensaje", "Estatus"];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(listStatusBills, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "ReporteEnvioMasivoFacturalite.xlsx");
      });
    });
  };

  return (
    <Dialog
      open={openConfirmMassModal}
      fullWidth={true}
      TransitionComponent={Transition}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        ¿Confirmas el inicio del envio de las facturas para los siguientes
        correos?
        <Typography variant="h6" component="div" ml={2} mb={2}>
          {sendBills?.length} facturas seleccionadas a {sendEmails?.length}{" "}
          correos
        </Typography>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Typography sx={{ mt: 2 }} variant="h6" component="div">
              Correos
            </Typography>
            <Grid item xs={12}>
              <List dense={true}>
                {sendEmails.map((email, index) => (
                  <>
                    <ListItem key={index}>
                      <ListItemText primary={email} />
                    </ListItem>
                  </>
                ))}
              </List>
            </Grid>

            <Typography sx={{ mt: 2 }} variant="h6" component="div">
              Facturas
            </Typography>
            <Grid item xs={12}>
              <List dense={true}>
                <Grid container spacing={2}>
                  {sendBills.map((bill, index) => (
                    <>
                      <Grid item xs={3} key={index}>
                        <ListItem>
                          <ListItemText primary={bill.folio} />
                        </ListItem>
                        <Divider />
                      </Grid>
                    </>
                  ))}
                </Grid>
              </List>
            </Grid>
          </Grid>
        </DialogContentText>
        <Box sx={{ width: "100%" }}>
          <LinearWithValueLabel count={count} total={sendBills.length} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {count === sendBills.length && (
          <>
            <Typography variant="subtitulo1" component="div" ml={2}>
              Se han enviado {successCount} de {sendBills.length} facturas.
            </Typography>
            <List dense={true}>
              {listStatusBills.map((err, index) => (
                <>
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        err.status === "Enviada"
                          ? "Factura " +
                            err.folio +
                            "  enviada " +
                            " a " +
                            err?.email +
                            " "
                          : "No se pudo enviar la factura " +
                            " a " +
                            err?.email +
                            " "
                      }
                      secondary={err?.message}
                    />
                    {err.status === "Enviada" ? (
                      <CheckCircle color="success" />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </ListItem>
                </>
              ))}
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {count === sendBills.length && active && (
          <>
            <Button onClick={handleCancel}>Salir</Button>
            <Button
              color="success"
              variant="contained"
              onClick={() => downloadExcel()}
            >
              Exportar y Descargar Reporte
            </Button>
          </>
        )}
        {!active && <Button onClick={handlerConfirmMassModal}>Volver</Button>}
        <Button
          onClick={handlerSendBills}
          variant={"contained"}
          color={"success"}
          disabled={active}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmMassSendBills;
