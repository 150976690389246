import { AddBox, DisabledByDefault } from "@mui/icons-material";
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { validateEmail } from "../../../utils/validations";

const SelectedEmailsMassSendBills = ({
  sendEmails,
  setSendEmails,
  setIsClient,
  isClient,
}) => {
  const handleChange = (event) => {
    setIsClient(event.target.value);
  };

  const handleAddEmail = () => {
    let inputValue = document.getElementById("add-email").value;

    if (validateEmail(inputValue)) {
      setSendEmails((prev) => [...prev, inputValue]);
      document.getElementById("add-email").value = "";
    } else {
      document.getElementById("add-email").focus();
    }
  };

  const handleDeleteChip = (element) => {
    const newArr = sendEmails.filter((e) => e !== element);

    setSendEmails(newArr);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container alignItems={"center"}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="controlled-radio-email">
              Enviar la facturas a
            </FormLabel>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={isClient}
              onChange={handleChange}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                label="Correo registrado del cliente en cada factura."
              />
              <FormControlLabel
                value={"0"}
                control={<Radio />}
                label="otro correo (puedes agregar hasta 5 correos)."
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {isClient === "0" && (
          <>
            <Grid item xs={11}>
              <TextField
                size="small"
                type="email"
                id="add-email"
                label="Agregar correo"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              {sendEmails.length >= 5 ? (
                <Button disabled color="secondary">
                  <DisabledByDefault />
                </Button>
              ) : (
                <Button color="primary" onClick={handleAddEmail}>
                  <AddBox />
                </Button>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid container mt={1}>
        {sendEmails.map((e, index) => (
          <Grid item key={index}>
            <Chip
              label={e}
              color={"primary"}
              onDelete={() => handleDeleteChip(e)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          <h5>
            Enviar la factura <b>{sendEmails.length}</b> correos
          </h5>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectedEmailsMassSendBills;
