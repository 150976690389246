import {CONEKTA_KEY} from './constant';

const conektaHelper = {
    initConekta: () =>{
        window.Conekta.setPublicKey(CONEKTA_KEY)
        window.Conekta.setLanguage("es")  
    },
    validateName: (cardName='')=>{
        return  window.Conekta.card.validateName(cardName)
    },
    validateCardNumber: (cardNumber) => {
        return window.Conekta.card.validateNumber(cardNumber)       
    },
    validateCvc: (cvc) => {
        return window.Conekta.card.validateCVC(cvc)
    },
    validateMonth: (expiryMonth) =>{
        return window.Conekta.card.validateExpMonth(expiryMonth)
        
    },
    validateYear: (expiryYear) =>{
        return window.Conekta.card.validateExpYear(expiryYear)
    },
    tokenize: (token,successResponseHandler,errorResponseHandler )=> {
        const tokenParams ={
            card: {
                number: token.cardNumber,
                name: token.cardHolder,
                exp_year: String(token.cardYear).substring(2,4),
                exp_month: token.cardMonth,
                cvc:token.cardCvv
            }
        }

     let response = window.Conekta.Token.create(tokenParams, 
        successResponseHandler, errorResponseHandler);
 
    return response;   
    } 
}
export default conektaHelper;
