import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CircularProgress,
  Box,
} from "@mui/material";

import { useNavigate } from "react-router";
import { Cancel, Check, Preview } from "@mui/icons-material";
import DialogPrevBills from "../DialogPrevBills";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { axiosCreatePreviousInvoice } from "../../../api/axiosBills";

const ButtonsNewBill = ({ activeSuccess, bill, setActiveSuccess, user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  //Cancelar factura
  const handleCancel = () => {
    navigate("/bills");
  };

  const handlePrev = () => {
    setOpen(!open);
  };

  const handlePrevia = () => {
    setLoadingPdf(true);
    axiosCreatePreviousInvoice(bill, user.test_api_key, user.live_api_key)
      .then((response) => {
        console.log("response", response);
        const array = new Uint8Array(response?.data);

        const blob = new Blob([array], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(blob);
        setLoadingPdf(false);
        window.open(fileURL);

        return response;
      })
      .catch((err) => {
        setLoadingPdf(false);
        if (err?.response?.status === 400) {
          var decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(err?.response?.data)
          );
          console.log(decodedString);
          var obj = JSON.parse(decodedString);

          var message = obj["message"];

          toast.error(message);
        } else {
          toast.error(
            "Error en el servidor, intenta mas tarde o comunícate con el administrador."
          );
        }
        return err;
      });
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Card variant="outlined" sx={{ p: 0 }}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid
                item
                sm={4}
                lg={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  display="flex"
                  alignitems="center"
                  variant="contained"
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="error"
                  onClick={handleCancel}
                >
                  <Typography display="flex" alignItems="center">
                    <Cancel />
                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Cancelar
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sm={4}
                lg={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <LoadingButton
                  display="flex"
                  alignItems="center"
                  variant="contained"
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="primary"
                  onClick={handlePrevia}
                  loading={loadingPdf}
                  disabled={activeSuccess}
                >
                  <Typography display="flex" alignItems="center">
                    <Preview />
                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Previa
                    </Typography>
                  </Typography>
                </LoadingButton>
              </Grid>

              <Grid
                item
                sm={4}
                lg={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  display="flex"
                  alignItems="center"
                  variant="contained"
                  disabled={activeSuccess}
                  onClick={handlePrev}
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="success"
                >
                  <Typography display="flex" alignItems="center">
                    {!loading ? (
                      <Check />
                    ) : (
                      <CircularProgress color="success" size={20} />
                    )}
                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Timbrar
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>

        <DialogPrevBills
          open={open}
          setOpen={setOpen}
          bill={bill}
          handlePrev={handlePrev}
          setLoading={setLoading}
          loading={loading}
          setActiveSuccess={setActiveSuccess}
          navigate={navigate}
        />
      </Grid>
    </>
  );
};

export default ButtonsNewBill;
