import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MyAppBar from "./navbar/MyAppBar";
import MyDrawer from "./slidebar/MyDrawer";
import { Outlet } from "react-router-dom";
import {
  Box,
  Container,
  experimentalStyled,
  useMediaQuery,
} from "@mui/material";

import { ThemeProvider } from "@mui/styles";
import { theme } from "../utils/theme";
import Footer from "./footer/Footer";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "-20px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MainWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));

const PageWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    paddingTop: 60,
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: "64px",
  },
}));
const FullLayout = () => {
  const [open, setOpen] = useState(false);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainWrapper>
          <MyAppBar
            sx={{
              paddingLeft: open && lgUp ? "265px" : "",
            }}
            open={open}
            handleDrawerOpen={handleDrawerOpen}
          />

          <MyDrawer open={open} handleDrawerClose={handleDrawerClose} />
          <PageWrapper>
            <Container
              maxWidth={false}
              sx={{
                backgroundColor: "#e7e7e7",
              }}
            >
              <DrawerHeader />
              <Box sx={{ minHeight: "calc(100vh - 170px)" }}>
                <Outlet />
              </Box>

              <Footer />
            </Container>
          </PageWrapper>
        </MainWrapper>
      </ThemeProvider>
    </Box>
  );
};

export default FullLayout;
