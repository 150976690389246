import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isTotallyEmpty } from "../../../utils/validations";
import { Visibility, VisibilityOff, VerifiedUser } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { axiosCSDClientFacturaApi } from "../../../api/fiscal";
import { toast } from "react-toastify";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { formatDateLetter } from "../../../utils/formats";

const useStyles = makeStyles(() => ({
  fileInput: {
    display: "none",
  },
}));

const CardCSDDataConfig = ({
  user,
  setUser,
  handleChange,
  mutableFiscalData,
  setMutableFiscalData,
  show,
  setShow,
}) => {
  const classes = useStyles();
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [activeViewEdit, setActiveViewEdit] = useState(false);

  const handleClickShow = (pwd) => {
    if (pwd) {
      setShow({ ...show, showCiec_pass: !show.showCiec_pass });
    } else {
      setShow({ ...show, showStamp_pass: !show.showStamp_pass });
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handlefiles = (e) => {
    let files = e.target.files[0];
    if (!isTotallyEmpty(files)) {
      setMutableFiscalData({ ...mutableFiscalData, [e.target.name]: files });
    }
  };

  const handleAddCSD = () => {
    setLoadingArchive(true);
    axiosCSDClientFacturaApi(
      mutableFiscalData.password_csd,
      mutableFiscalData.cer_file_data,
      mutableFiscalData.key_file_data
    )
      .then((response) => {
        let { data } = response;
        if (data.status) {
          toast.success(data.message);
          setTimeout(() => {
            setLoadingArchive(false);

            setUser({
              ...user,
              has_cer_file: 1,
              has_key_file: 1,
              expire_certificate: data.data.certificate.expires_at,
              bells: 3,
              legal_name: data.data.legal.legal_name,
              tax_id: data.data.legal.tax_id,
              tax_system: data.data.tax_system,
              zip: data.data.legal.address.zip,
            });
            window.location.reload(true);
          }, 10);
        } else {
          toast.error(response.data.message);
          setLoadingArchive(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Error en el servidor");
        }
        setLoadingArchive(false);
        return err;
      });
  };

  const handleViewEdit = () => {
    setActiveViewEdit(!activeViewEdit);
  };

  return (
    <>
      <Card variant="outlined" sx={{ pb: 0, mb: 4, mr: 0, ml: 0 }}>
        <CardContent sx={{ pb: 0 }}>
          <Grid
            item
            sm={12}
            lg={12}
            xs={12}
            sx={{ mt: { xs: 2, sm: 0, lg: 0 } }}
          >
            <Typography variant="h6" fontWeight="600" marginBottom={"5%"}>
              CSD (Sellos Digitales)
            </Typography>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <TextField
                fullWidth
                margin="dense"
                id="input-with-icon-textfield"
                name="password_csd"
                label="Contraseña de Sello Digital"
                variant="filled"
                helperText="Clave para manipulación de facturas"
                value={mutableFiscalData?.password_csd}
                type={show.showStamp_pass ? "text" : "password"}
                onChange={handleChange("password_csd")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle stamp_pass visibility"
                        onClick={() => handleClickShow(false)}
                        onMouseDown={handleMouseDown}
                        edge="end"
                      >
                        {show.showStamp_pass ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {mutableFiscalData.has_cer_file == 1 && activeViewEdit === false ? (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent="center"
                >
                  <Typography variant="h6" fontWeight="600">
                    Sellos Digitales cargados
                  </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <AdminPanelSettingsIcon
                    color="info"
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent="center"
                >
                  <Typography color="primary">Fecha de expiración:</Typography>
                  <Typography color="primary">
                    {" " + formatDateLetter(mutableFiscalData.expire_csd)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent="center"
                >
                  <Button variant="contained" onClick={() => handleViewEdit()}>
                    Editar CSD
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent="flex-start"
                  sx={{ m: "32px 2px" }}
                >
                  <input
                    accept=".cer"
                    className={classes.fileInput}
                    id="contained-button-file-cer"
                    name="cer_file_data"
                    type="file"
                    onChange={(e) => handlefiles(e)}
                  />
                  <label htmlFor="contained-button-file-cer">
                    <Button
                      variant="contained"
                      color={
                        !mutableFiscalData?.cer_file_data?.name
                          ? "primary"
                          : "secondary"
                      }
                      component="span"
                      startIcon={<VerifiedUser />}
                      sx={{ mr: 2 }}
                    >
                      {!mutableFiscalData?.cer_file_data?.name
                        ? "ARCHIVO CER"
                        : mutableFiscalData?.cer_file_data?.name}
                    </Button>
                    <label htmlFor="">
                      {!user?.has_cer_file === 0 ? (
                        "Archivo Cargado"
                      ) : (
                        <Typography
                          color="secondary"
                          sx={{
                            display: {
                              xs: "block",
                              sm: "inline",
                              lg: "inline",
                            },
                          }}
                        >
                          Cargar Archivo
                        </Typography>
                      )}
                    </label>
                  </label>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent="flex-center"
                  sx={{ m: "32px 2px" }}
                >
                  <input
                    accept=".key"
                    className={classes.fileInput}
                    id="contained-button-file-key"
                    name="key_file_data"
                    type="file"
                    onChange={(e) => handlefiles(e)}
                  />
                  <label htmlFor="contained-button-file-key">
                    <Button
                      variant="contained"
                      color={
                        !mutableFiscalData?.key_file_data?.name
                          ? "primary"
                          : "secondary"
                      }
                      component="span"
                      sx={{ mr: 2 }}
                    >
                      {!mutableFiscalData?.key_file_data?.name
                        ? "ARCHIVO KEY"
                        : mutableFiscalData?.key_file_data?.name}
                    </Button>
                    <label htmlFor="">
                      {!user?.has_key_file === 0 ? (
                        "Archivo Cargado"
                      ) : (
                        <Typography
                          color="secondary"
                          sx={{
                            display: {
                              xs: "block",
                              sm: "inline",
                              lg: "inline",
                            },
                          }}
                        >
                          Cargar Archivo
                        </Typography>
                      )}
                    </label>
                  </label>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  justifyContent="flex-end"
                  marginTop={"10%"}
                >
                  <LoadingButton
                    loading={loadingArchive}
                    color="primary"
                    variant="contained"
                    disabled={
                      mutableFiscalData.key_file_data === "" ||
                      mutableFiscalData.cer_file_data === "" ||
                      isTotallyEmpty(mutableFiscalData.password_csd)
                    }
                    onClick={(e) => handleAddCSD()}
                  >
                    Guardar CSD
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CardCSDDataConfig;
