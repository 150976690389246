import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Fab,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import { PlusOne } from "@mui/icons-material";

const SelectConceptNewBill = ({
  setConcepts,
  concept,
  setConcept,
  handleAddProduct,
  products,
  setProducts,
  getSearchProducts,
  initialState,
}) => {
  useEffect(() => {
    getSearchProducts();
  }, []);

  const handlerProductSearch = (e, val, reason) => {
    if (reason !== "reset") {
      getSearchProducts(val);
    }
  };

  const handlerProductSelected = (product) => {
    if (product != null) {
      return setConcept({
        ...concept,
        conceptId: product.id,
        conceptText: `${product.code} - ${product.description}`,
        description: product.description,
        product_key: product.product_key,
        product_text: product.product_key,
        unit_key: product.unit_key,
        tax_included: product.tax_included,
        taxability: product.taxability,
        unit_name: product.unit_name,
        taxes: product.taxes,
        local_taxes: product.local_taxes,
        price: product.price,
        sku: product.sku,
      });
    }
    return setConcept(initialState());
  };

  const handleChange = ({ target }) => {
    setConcept({ ...concept, [target.name]: target.value });
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <Autocomplete
          id="combo-products"
          options={products}
          input={concept?.product_key}
          onChange={(event, newValue) => handlerProductSelected(newValue)}
          getOptionLabel={(concept) =>
            `${concept.product_key} - ${concept.description}`
          }
          onInputChange={(e, concept, reason) =>
            handlerProductSearch(e, concept, reason)
          }
          renderOption={(props, product) => (
            <Box
              component="li"
              sx={{ fontSize: 15, "& > span": { mr: "10px", fontSize: 18 } }}
              {...props}
            >
              {`${product?.product_key} - ${product?.description}`}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => handlerProductSelected(e, params)}
              label="Concepto"
              variant="filled"
              placeholder="Escribe Descripción del concepto"
              size="small"
              margin="normal"
              autoComplete="off"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          label="Cantidad"
          margin="normal"
          variant="outlined"
          type="number"
          size="small"
          name="quantity"
          value={concept?.quantity}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2} md={1} lg={2} style={{ textAlign: "center" }}>
        <Fab
          color="secondary"
          sx={{
            mr: 1,
            mb: { xs: 1, sm: 0, lg: 0 },
          }}
          disabled={concept.conceptId === "" || concept.quantity <= 0}
          onClick={handleAddProduct}
        >
          <PlusOne />
        </Fab>
        <Typography variant="h6">Agregar Concepto</Typography>
      </Grid>
    </>
  );
};

export default SelectConceptNewBill;
