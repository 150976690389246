import axios from "./axios";
import { TOKEN } from "../utils/constant";
import { isTotallyEmpty } from "../utils/validations";

export const useStampBill = () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  const key = localStorage.getItem("api_key");

  const stampBills = async ({
    setCount,
    selectedClients,
    bill,
    setListStatusBills,
    setSuccessCount,
  }) => {
    selectedClients.map((client, index) => {
      let folio = null;

      if (!isTotallyEmpty(bill.folio_number)) {
        folio = parseInt(bill.folio_number) + index;
      }

      let data = {
        key: key,
        client: client,
        bill: bill,
        folio: folio,
      };

      axios
        .post("/api/bills/createInvoiceMass", data, config)
        .then((res) => {
          if (res.data.status === false) {
            setCount((prevCount) => prevCount + 1);
            let newArr = {
              client: client.legal_name,
              message: res.data.message,
              status: "Sin Timbrar",
            };
            setListStatusBills((prev) => [...prev, newArr]);
          } else {
            setCount((prevCount) => prevCount + 1);
            let newArr = {
              client: client.legal_name,
              message: "Factura Timbrada ",
              status: "Timbrada",
            };
            setSuccessCount((prevsuccessCount) => prevsuccessCount + 1);
            setListStatusBills((prev) => [...prev, newArr]);
          }
        })
        .catch((err) => {
          setCount((prevCount) => prevCount + 1);
          let newArr = {
            client: client.legal_name,
            message: "Error en timbrado intenta mas tarde. ",
            status: "Sin Timbrar",
          };
          setListStatusBills((prev) => [...prev, newArr]);
        })
        .finally(setCount(0), setListStatusBills([]));
    });
  };

  return {
    stampBills,
  };
};
