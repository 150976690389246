import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { values, size } from "lodash";
import { AuthContext } from "../../auth/AuthContext";

import conektaHelper from "../../utils/conektaHelper";
import { API_HOST, TOKEN } from "../../utils/constant";
import CForm from "./Form";
import Card from "./Card";
import "./app.scss";

const initialState = {
  cardNumber: "#### #### #### ####",
  cardHolder: "FULL NAME",
  cardMonth: "",
  cardYear: "",
  cardCvv: "",
  isCardFlipped: false,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogAddCard = ({ open, setOpen, handleGetCard }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [state, setState] = useState(initialState);
  const [currentFocusedElm, setCurrentFocusedElm] = useState(null);
  const [formPayment, setFormPayment] = useState(initialFormValue());
  const { user, setUser } = useContext(AuthContext);

  //Carga de conekta para Tokenizar
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = false;
    document.body.appendChild(script);
  }, []);

  const updateStateValues = useCallback(
    (keyName, value) => {
      setState({
        ...state,
        [keyName]: value || initialState[keyName],
      });
    },
    [state]
  );

  // References for the Form Inputs used to focus corresponding inputs.
  let formFieldsRefObj = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
    cardCvv: useRef(),
  };

  let focusFormFieldByKey = useCallback((key) => {
    formFieldsRefObj[key].current.focus();
  });

  // This are the references for the Card DIV elements.
  let cardElementsRef = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
  };

  let onCardFormInputFocus = (_event, inputName) => {
    const refByName = cardElementsRef[inputName];
    setCurrentFocusedElm(refByName);
  };

  let onCardInputBlur = useCallback(() => {
    setCurrentFocusedElm(null);
  }, []);

  // const successResponseHandler = async (token) => {
  // }
  const handleClose = () => {
    cardElementsRef.cardNumber = "";
    cardElementsRef.cardHolder = "";
    cardElementsRef.cardDate = "";

    formFieldsRefObj.cardCvv = "";
    formFieldsRefObj.cardDate = "";
    formFieldsRefObj.cardHolder = "";
    formFieldsRefObj.cardNumber = "";
    setOpen(false);
  };

  const successResponseHandler = async (token) => {
    let url = `${API_HOST}/api/user/add-payment-data`;

    const headersConfig = {
      headers: {
        Authorization: localStorage.getItem(TOKEN),
      },
    };
    const body = {
      token,
      conekta_id: user?.conekta_id,
    };
    let response = await axios
      .post(url, body, headersConfig)
      .then((response) => {
        if (response.status === 200) {
          handleClose();

          toast.success(" Metodo de pago agregado correctamente.");
          handleGetCard();
          setFormPayment(initialFormValue());
        }
      });
    return response;
  };

  const errorResponseHandler = (error) => {
    toast.error(error.message_to_purchaser);
    return error;
  };

  const handlerAddPayment = (e) => {
    conektaHelper.initConekta();
    e.preventDefault();

    let validCount = 0;
    values(formPayment).some((value) => {
      value && validCount++;
      return null;
    });

    if (validCount !== size(formPayment)) {
      toast.warning("Completa todos los campos del formulario");
    } else if (size(formPayment.cardNumber) < 16) {
      toast.warning("Ingresa una tarjeta valida");
    } else {
      let req = conektaHelper.tokenize(
        formPayment,
        successResponseHandler,
        errorResponseHandler
      );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <CForm
            cardMonth={state.cardMonth}
            cardYear={state.cardYear}
            onUpdateState={updateStateValues}
            setFormPayment={setFormPayment}
            formPayment={formPayment}
            cardNumberRef={formFieldsRefObj.cardNumber}
            cardHolderRef={formFieldsRefObj.cardHolder}
            cardDateRef={formFieldsRefObj.cardDate}
            onCardInputFocus={onCardFormInputFocus}
            onCardInputBlur={onCardInputBlur}
          >
            <Card
              cardNumber={state.cardNumber}
              cardHolder={state.cardHolder}
              cardMonth={state.cardMonth}
              cardYear={state.cardYear}
              cardCvv={state.cardCvv}
              isCardFlipped={state.isCardFlipped}
              currentFocusedElm={currentFocusedElm}
              onCardElementClick={focusFormFieldByKey}
              cardNumberRef={cardElementsRef.cardNumber}
              cardHolderRef={cardElementsRef.cardHolder}
              cardDateRef={cardElementsRef.cardDate}
            ></Card>
          </CForm>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handlerAddPayment}>
            Agregar
          </Button>
          <Button onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogAddCard;

function initialFormValue() {
  return {
    cardHolder: "",
    cardNumber: "",
    cardCvv: "",
    cardMonth: "",
    cardYear: "",
  };
}
