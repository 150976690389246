import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  TableRow,
  TableHead,
  TableBody,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  Typography,
  Slide,
  Divider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { toast } from "react-toastify";
import { axiosStoreBillFacturaApi } from "../../api/axiosBills";
import { Cancel, TaskAlt } from "@mui/icons-material";
import { useGetLists } from "../../hooks/useGetLists";
import payment_modes from "../../utils/payment_modes.json";

import relationship from "../../utils/relationship_bills.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    fontSize: "small",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DialogPrevBills = ({
  open,
  setOpen,
  bill,
  setLoading,
  setActiveSuccess,
  navigate,
  handlePrev,
  loading,
}) => {
  const [cfdiList, setCfdiList] = useState([]);
  const { getCfdi } = useGetLists();

  const date = new Date(bill.date);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    getCfdi(setCfdiList);
  }, []);

  //guardar y timbrar factura terminada
  const handleStoreBill = () => {
    setLoading(true);
    setActiveSuccess(true);

    axiosStoreBillFacturaApi(bill)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data.status === true) {
            toast.success("Factura creada.");
            setLoading(false);
            setActiveSuccess(false);
            setTimeout(() => {
              navigate("/bills");
            }, 2000);
          } else {
            setActiveSuccess(false);
            toast.warning(res.data.message);
          }
        } else {
          toast.warning(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 401) {
          setActiveSuccess(false);
          toast.error(err?.response.data.message);
        } else {
          toast.error(
            "Ha ocurrido un problema al almacenar los datos de facturas, inténtelo nuevamente más tarde."
          );
        }
        return err;
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      TransitionComponent={Transition}
    >
      <>
        <DialogTitle>
          <Typography variant="h6" fontWeight="600" textAlign={"center"}>
            CONFIRMACIÓN DE TIMBRADO
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justifyContent={"space-between"}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="600"
                marginBottom={"1%"}
                marginTop={"2%"}
              >
                Información general
              </Typography>
              <Divider style={{ marginBottom: "15px" }} />
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">
                  Fecha de creación:
                  <b>{date.toLocaleDateString("es-MX", options)}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle1">
                  Tipo factura:
                  <b>{bill?.type === "I" ? "Ingreso" : "Egreso"}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle1">
                  Serie: <b>{bill?.series}</b>
                </Typography>
                <Typography variant="subtitle1">
                  Folio: <b>{bill?.folio_number}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">
                Cliente:{" "}
                <b>
                  {bill.customer_name} | {bill.customer_rfc}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {cfdiList.map((cfdi, index) => {
                if (cfdi.code === bill.use) {
                  return (
                    <Typography variant="subtitle1" key={index}>
                      Uso CDFI:
                      <b>
                        {cfdi.code} | {cfdi.name}
                      </b>
                    </Typography>
                  );
                }
              })}
            </Grid>
            {bill.pdf_custom_section !== "" && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant="subtitle1">
                  Observaciones: <b>{bill.pdf_custom_section}</b>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={0} sx={{ mt: 2, fontWeight: "bolder" }}>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="600" marginBottom={"1%"}>
                Conceptos
              </Typography>
              <Divider style={{ marginBottom: "15px" }} />
            </Grid>
            <Grid item xs={12}>
              <TableContainer sx={{ border: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Clave ProdServ</StyledTableCell>
                      <StyledTableCell>SKU</StyledTableCell>
                      <StyledTableCell>Descripción</StyledTableCell>
                      <StyledTableCell>Impuestos incluidos</StyledTableCell>
                      <StyledTableCell>ObjetoImp</StyledTableCell>
                      <StyledTableCell>Precio unitario</StyledTableCell>
                      <StyledTableCell>Cantidad</StyledTableCell>
                      <StyledTableCell>Importe S/I</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bill?.items?.length > 0
                      ? bill?.items?.map((i, index) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                sx={{ fontWeight: "bold" }}
                              >
                                {i[0]?.product_key}
                              </StyledTableCell>
                              <StyledTableCell>{i[0]?.sku}</StyledTableCell>
                              <StyledTableCell>
                                {i[0]?.description}
                              </StyledTableCell>
                              <StyledTableCell>
                                {i[0]?.tax_included ? "✓" : "✕"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {i[0]?.taxability}
                              </StyledTableCell>
                              <StyledTableCell>
                                ${" "}
                                {typeof i[0]?.price === "number"
                                  ? i[0]?.price.toFixed(2)
                                  : i[0]?.price}
                              </StyledTableCell>

                              <StyledTableCell>
                                {i[0]?.quantity}
                              </StyledTableCell>
                              <StyledTableCell>
                                $ {(i[0]?.price * i[0]?.quantity).toFixed(2)}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container spacing={0} justifyContent={"space-between"}>
                <Grid item xs={12} md={5}>
                  {payment_modes.map((payment, index) => {
                    if (payment.code === bill.payment_form) {
                      return (
                        <Typography variant="subtitle1" key={index}>
                          Forma de pago: <b>{payment.name}</b>
                        </Typography>
                      );
                    }
                  })}
                  <Typography variant="subtitle1">
                    Método de pago:
                    <b>
                      {bill.payment_method === "PUE"
                        ? "PUE (Pago en Una sola Exhibición)"
                        : "PPD (Pago en Parcialidades o Diferido)"}
                    </b>
                  </Typography>
                  <Grid item xs={12} md={3}>
                    <Typography variant="subtitle1">
                      Moneda: <b>{bill.currency}</b>
                    </Typography>
                    {bill.currency !== "MXN" && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">
                          Tipo de cambio{" "}
                          <b>$ {bill.exchange ? bill.exchange : 1}</b>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {Object.keys(bill?.related_documents).length !== 0 && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight="600" marginBottom={"1%"}>
                    Documentos relacionados
                  </Typography>
                  <Divider style={{ marginBottom: "15px" }} />
                </Grid>
                <Grid item xs={12}>
                  <TableContainer sx={{ border: 1 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Relación</StyledTableCell>
                          <StyledTableCell>Folio fiscal</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>
                            {relationship.map((related) => {
                              if (
                                related.code ===
                                bill?.related_documents.relationship
                              ) {
                                return `${related.code} - ${related.name}`;
                              }
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            {bill?.related_documents.documents.map(
                              (i, index) => {
                                return (
                                  <>
                                    {i} <br></br>
                                  </>
                                );
                              }
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handlePrev()}
            variant={"contained"}
            color={"error"}
            startIcon={<Cancel />}
          >
            Cerrar
          </Button>
          <Button
            onClick={handleStoreBill}
            disabled={loading}
            variant={"contained"}
            color={"success"}
            startIcon={<TaskAlt />}
          >
            Timbrar
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default DialogPrevBills;
