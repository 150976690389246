import React, { useState } from "react";
import {
  Button,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { axiosCreateCompanyFacturaApi } from "../../api/axiosCompany";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  floatRight: {
    float: "right",
  },
});

const NewBusiness = ({ btnText, originPosition, triggerAxiosPaginate }) => {
  const classes = useStyles();
  // UX / UI states
  const [open, setOpen] = useState(false);

  const [companyName, setCompanyName] = useState("");

  const handleStoreCompany = () => {
    axiosCreateCompanyFacturaApi(companyName)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          setTimeout(() => {
            handleClose();
            setCompanyName("");
            window.location.reload(true);
          }, 10);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Error en el servidor");
        }
        return err;
      });
  };

  const handleClickOpen = () => {
    setCompanyName("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (event) => {
    setCompanyName(event.target.value);
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className={originPosition && classes.floatRight}
      >
        {btnText}
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="dialog-title-add-bussiness"
        aria-describedby="dialog-title-add-bussiness"
      >
        <DialogTitle id="dialog-title-add-client"> {btnText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-title-add-description">
            Registra otra empresa para poder facturar con nosotros, solo
            necesitas el nombre comercial.
          </DialogContentText>

          <TextField
            variant="filled"
            placeholder="Escribe Nombre Comercial"
            id="comercialName"
            name="company"
            margin="normal"
            value={companyName}
            label="Nombre Comercial"
            required
            type="text"
            onChange={onChange}
            fullWidth
          />

          <DialogContentText id="dialog-title-add-description" sx={{ mt: 2 }}>
            Después de crear la empresa los pasos son muy sencillos para
            facturar, debes de subir tus certificados en la configuración de tus
            empresas y actualizar tus datos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleStoreCompany}
          >
            Registrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewBusiness;
