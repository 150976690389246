import React from "react";

import { Card, CardHeader, CardContent, Divider } from '@mui/material';

const BaseCard = (props) => {
  return (
    <Card
      variant="elevation"
      sx={{
        width: "100%",
        p: 0,
      }}
    >
      <CardHeader title={props.title} sx={{textAlign:'center', fontWeight:'600' }}/>
     
      <Divider />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default BaseCard;
