import React, { useState } from "react";

import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  DialogContentText,
  Grid,
  Alert,
  AlertTitle,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import esLocale from "date-fns/locale/es";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Slide from "@mui/material/Slide";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { oldFiveYear } from "../../utils/formats";
import { axiosGetBillData } from "../../api/axiosBills";
import { Assessment } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

const DialogReportBills = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(true);
  const [selectedDateLte, setSelectedDateLte] = useState(null);
  const [selectedDateGet, setSelectedDateGte] = useState(null);
  const [colorAlert, setColorAlert] = useState("info");
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [customer, setCustomer] = useState();
  const [message, setMessage] = useState(
    "Al Validar corroboramos que si existen facturas creadas en el periodo para exportar."
  );
  const [data, setData] = useState([]);
  const [dataReport, setDataReport] = useState({
    lte: null,
    gte: new Date(),
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
      setDataReport({
        lte: null,
        gte: new Date(),
      });
      setSelectedDateLte(null);
      setSelectedDateGte(null);
      setColorAlert("info");
      setMessage(
        "Al Validar corroboramos que si existen facturas creadas en el periodo para exportar."
      );
      setData([]);
      setActiveButton(true);
    }
  };

  const handleGetBillData = () => {
    setLoadingValidate(true);
    axiosGetBillData(dataReport)
      .then((response) => {
        if (response.data.status === false) {
          setMessage(response.data.message);
          setColorAlert("warning");
          setActiveButton(true);
          setLoadingValidate(false);
        } else {
          setActiveButton(false);
          setMessage(response.data.message);
          setData(response.data.data);
          setCustomer(response.data.customer);
          setColorAlert("success");
          setLoadingValidate(false);
        }
        return response;
      })
      .catch((err) => {
        setLoadingValidate(false);
        return err;
      });
  };

  const downloadExcel = () => {
    let header = [
      "id",
      "Fecha",
      "Folio",
      "Factura",
      "Razón Social",
      "Estatus",
      "Tipo",
      "Método de Pago",
      "Monto",
      "Cantidad",
      "Servicio",
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0].length;
      const rang = sheet1.range("A1:K1");
      rang.value(customer);
      rang.style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontColor: "FFFFFF",
        bold: true,
        fill: "233B63",
      });
      rang.merged(true);

      sheet1.cell("A2").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      // sheet1.row(1).style("bold", true);
      sheet1.range("A2:" + endColumn + "2").style({ bold: true, border: true });
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "ReporteFacturalite.xlsx");
      });
    });
  };

  /**
   *
   * @param {Date/String} date
   *
   */
  const handleDateChangeLte = (date) => {
    setSelectedDateLte(date);
    setDataReport({ ...dataReport, lte: date });
  };

  /**
   *
   * @param {Date/String} date
   *
   */
  const handleDateChangeGte = (date) => {
    setSelectedDateGte(date);
    setDataReport({ ...dataReport, gte: date });
  };

  return (
    <>
      <Card elevation={8}>
        <CardActionArea onClick={() => handleToggle()}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardHeader
              sx={{ mr: 0 }}
              avatar={
                <Avatar sx={{ bgcolor: "#233B63" }}>
                  {" "}
                  <Assessment />{" "}
                </Avatar>
              }
            />
          </Box>
          <Typography variant="h6" mb={2} align="center">
            Reporte por rango de Fechas.
          </Typography>
        </CardActionArea>
      </Card>

      <Dialog
        open={isOpen}
        onClose={handleToggle}
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleToggle}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Generar Reporte.
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
            Indica el rango de fechas a consultar.
          </DialogContentText>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <MobileDatePicker
                  label="Fecha Inicial"
                  value={dataReport.lte ? dataReport.lte : selectedDateLte}
                  minDate={oldFiveYear()}
                  maxDate={new Date()}
                  onChange={handleDateChangeLte}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      fullWidth
                      helperText={
                        "Límite superior inclusivo del rango de fechas a solicitar."
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esLocale}
              >
                <MobileDatePicker
                  label="Fecha Final"
                  value={dataReport.gte ? dataReport.gte : selectedDateGet}
                  maxDate={new Date()}
                  onChange={handleDateChangeGte}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      fullWidth
                      helperText={
                        "Límite inferior inclusivo del rango de fechas a solicitar. "
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid container item justifyContent={"center"} marginBottom={2}>
              <LoadingButton
                loading={loadingValidate}
                color="primary"
                variant="contained"
                onClick={() => handleGetBillData(data)}
              >
                Validar
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Alert severity={colorAlert}>
                <AlertTitle>{message}</AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="success"
            variant="contained"
            disabled={activeButton}
            onClick={() => downloadExcel()}
          >
            Exportar y Descargar
          </Button>
          <Button onClick={handleToggle} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogReportBills;
