import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { axiosUpdateLogo, axiosGetLogo } from "../../api/axiosCompany";
import { PhotoCamera } from "@mui/icons-material";

export const PersonalizeBusiness = ({
  business,
  setBusiness,
  handleToggle,
  isOpen,
  triggerAxiosPaginate,
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const [logo, setLogo] = useState("");

  // ComponentDidMount
  useEffect(() => {
    if (isOpen === true) {
      axiosGetLogo(business)
        .then((res) => {
          let img = res.data.image;
          setLogo(img);
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
  }, [isOpen]);

  const handlerUploadFileChange = ({ target }) => {
    let file = target.files[0];
    setUploadImage(file);
  };

  const uploadTrigger = () => {
    axiosUpdateLogo(uploadImage, business)
      .then((res) => {
        if (res?.data?.image) {
          // localStorage.setItem('image', res.data.image);
          // setUser({ ...user, image: res.data.image, has_avatar: true});
          setLogo(res.data.image);
          setBusiness({ ...business, logotipo: true });
        }
        toast.success("Se a actualizado la imagen de la cuenta");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.warning("El peso máximo de la imagen debe ser de 500KB");
        } else if (err.response.status === 422) {
          toast.warning("Formato de imagen inválido.");
        } else {
          toast.error("Error al actualizar la imagen de la cuenta");
        }
      });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`PERSONALIZAR A ${business.legal_name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sube el logotipo de la organización que será colocado en el PDF y en
          los correos que se envían al cliente con la factura adjunta.
        </DialogContentText>
        <Box
          sx={{
            // background: "-webkit-radial-gradient(left,#233B63,#365d9e)",
            padding: "10px",
            border: "solid #365d9e",
            borderRadius: "30%",
            width: "221px",
            height: "121px",
            overflow: "hidden",
            margin: "16px auto 10px auto",
          }}
        >
          <Avatar
            variant="square"
            src={
              business.logotipo && logo
                ? `data:image/png;base64,${logo}`
                : business.legal_name
            }
            alt={business.legal_name}
            sx={{
              width: 190,
              height: 90,
              img: { objectFit: "contain !important" },
            }}
          />
        </Box>
        <Box
          sx={{
            justifyItems: "center",
            display: "grid",
            m: { xs: "auto -20px 15px -20px" },
          }}
        >
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="icon-button-file"
            type="file"
            onChange={handlerUploadFileChange}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera />
            </IconButton>
            <label>
              {!uploadImage ? "Selecciona una image" : uploadImage.name}
            </label>
          </label>

          <label htmlFor="contained-button-avatar">
            <Button
              color="primary"
              variant="contained"
              disabled={!uploadImage}
              onClick={uploadTrigger}
              aria-label="upload picture"
              component="span"
            >
              Subir
            </Button>
          </label>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <LoadingButton
                    color='primary'
                    loading={loading}
                    onClick={personalizeCompanyHandler}
                    variant='contained' >
                    Guardar
                </LoadingButton> */}

        <Button onClick={handleToggle} autoFocus>
          Regresar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
