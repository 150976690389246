import { toast } from "react-toastify";
import axios from "./axios";

export const useGetLists = () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const getCfdi = async (setCfdiList) => {
    axios
      .get("/api/tools/cfdiUses", config)
      .then((res) => setCfdiList(res.data.data))
      .catch((err) => toast.error("Error al cargar lista de CFDI."));
  };

  const getRegimes = async (setRegimes) => {
    axios
      .get("/api/tools/regimes", config)
      .then((res) => setRegimes(res.data.data))
      .catch((err) => console.log(err));
  };

  return {
    getCfdi,
    getRegimes,
  };
};
