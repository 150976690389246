import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  Stack,
  TableRow,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import SkeletonTable from "../General/SkeletonTable";
import DeleteProduct from "./DeleteProduct";
import EditProduct from "./EditProduct";
import { axiosShowProductIDFacturaApi } from "../../api/axiosProducts";
import { LoadingButton } from "@mui/lab";

const TableProducts = ({
  loading,
  products,
  totalPages,
  page,
  search,
  triggerAxiosPaginate,
}) => {
  const [productData, setProductData] = useState({});
  const [statusEditModal, setStatusEditModal] = useState(false);
  const [statusDeleteModal, setStatusDeleteModal] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    triggerAxiosPaginate(page);
  }, []);

  const handleChangePage = (event, page) => {
    triggerAxiosPaginate(page, search);
  };

  const handleToggleEditModal = (product) => {
    if (statusEditModal === false) {
      setLoadingEdit(true);
      axiosShowProductIDFacturaApi(product)
        .then((response) => {
          let { data: data } = response;

          setProductData(data.data);
          setLoadingEdit(false);
          setTimeout(() => {
            setStatusEditModal(true);
          }, 10);
        })
        .catch((err) => {
          setLoadingEdit(false);

          return err;
        });
    }

    setStatusEditModal(false);
  };

  const handleToggleDeleteModal = () => {
    setStatusDeleteModal(!statusDeleteModal);
  };

  return (
    <>
      <Card variant="elevation">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={{ maxHeight: 440, borderTop: "8px solid #233B63" }}
          >
            <Table
              stickyHeader
              aria-label="custom pagination table"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">SKU</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Clave Producto</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Clave de unidad</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Precio</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="h5">Impuestos</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Acciones</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <SkeletonTable />
                ) : (
                  products?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h5">{product?.sku}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">
                          {product?.product_key}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          fontWeight="400"
                          whiteSpace={"break-spaces"}
                          sx={{
                            width: "230px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {product?.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          variant="h6"
                          fontWeight="400"
                          whiteSpace={"break-spaces"}
                        >
                          {product?.unit_key + " " + product?.unit_name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          variant="h6"
                          fontWeight="400"
                        >
                          $ {product?.price}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          variant="h6"
                          fontWeight="400"
                        >
                          {product?.taxability === "01"
                            ? "Sin impuestos"
                            : product?.taxes[0]?.rate * 100 +
                              "% " +
                              product?.taxes[0]?.type}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" scope="product">
                        <ButtonGroup
                          variant="contained"
                          aria-label="contained primary button group"
                        >
                          <LoadingButton
                            size="small"
                            color="primary"
                            variant="contained"
                            loading={
                              product.id === productData.id && loadingEdit
                            }
                            onClick={() => {
                              setProductData(product);
                              handleToggleEditModal(product.id);
                            }}
                          >
                            <Edit />
                          </LoadingButton>
                          <Button
                            size="small"
                            color="error"
                            onClick={() => {
                              setProductData(product);
                              handleToggleDeleteModal();
                            }}
                          >
                            <Delete />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack spacing={2} style={{ float: "right" }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </Paper>

        <EditProduct
          product={productData}
          setProduct={setProductData}
          handleToggle={handleToggleEditModal}
          isOpen={statusEditModal}
          triggerAxiosPaginate={triggerAxiosPaginate}
        />

        <DeleteProduct
          product={productData}
          handleToggle={handleToggleDeleteModal}
          isOpen={statusDeleteModal}
          triggerAxiosPaginate={triggerAxiosPaginate}
        />
      </Card>
    </>
  );
};

export default TableProducts;
