import { LoadingButton } from "@mui/lab";
import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosValidateRFC } from "../../api/axiosClients";
import { axiosGetRegimes } from "../../api/regimes";
import { toast } from "react-toastify";
import {
  validateEmail,
  validatePostalCode,
  validatePhoneNumber,
  validateRfc,
} from "../../utils/validations";

const AddClients = ({ setClient, client }) => {
  const [regimes, setRegimes] = useState([]);
  const [loadingValidate, setLoadingValidate] = useState(false);

  function handleClickValidate() {
    setLoadingValidate(true);
    axiosValidateRFC(client.tax_id)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          setLoadingValidate(false);
        } else {
          if (validateRfc(client.tax_id)) {
            toast.warning("EL RFC NO TIENE FORMATO CORRECTO.");
            setLoadingValidate(false);
          } else {
            toast.error(response.data.message);
            setLoadingValidate(false);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Error en el servidor");
        }
        setTimeout(() => {
          setLoadingValidate(false);
        }, 10);
      });
  }
  // ComponentDidMount
  useEffect(() => {
    axiosGetRegimes()
      .then((response) => {
        setRegimes(response.data.data);
      })
      .catch((err) => {
        return err;
      });
  }, []);
  const onChange = (event) => {
    if (event.target.name === "legal_name" || event.target.name === "tax_id") {
      event.target.value = event.target.value.toUpperCase();
    }
    setClient({ ...client, [event.target.name]: event.target.value });
  };
  return (
    <form onChange={onChange} autoComplete="on">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientSocialReason"
            name="legal_name"
            label="Razón Social"
            type="text"
            placeholder="Escribe Razón Social"
            variant="filled"
            required
            value={client.legal_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={7} sm={5} xl={5}>
          <TextField
            autoFocus
            margin="dense"
            id="clientRfc"
            label="RFC del cliente"
            type="text"
            placeholder="Escribe RFC del cliente"
            variant="filled"
            name="tax_id"
            required
            error={!validateRfc(client.tax_id) && client.tax_id !== ""}
            value={client.tax_id}
            fullWidth
          />
        </Grid>
        <Grid item xs={5} sm={1} xl={1}>
          <LoadingButton
            color="secondary"
            variant="contained"
            onClick={handleClickValidate}
            loading={loadingValidate}
            disabled={client.tax_id === ""}
            sx={{
              boxSizing: "border-box",
              width: "100%",

              marginTop: "8px",
              marginBottom: "4px",
              fontSize: " 0.775rem",
            }}
          >
            Validar RFC
          </LoadingButton>
        </Grid>

        <Grid item xs={12} sm={3} xl={3}>
          <TextField
            id="clientSelectRegime"
            select
            margin="dense"
            name="tax_system"
            label="Regimen del cliente"
            placeholder="Escribe Regimen"
            variant="filled"
            required
            value={client.tax_system}
            onChange={onChange}
            fullWidth
            autoComplete="off"
          >
            {regimes?.map((option) => (
              <MenuItem key={option.id} value={option.code}>
                {option.code} - {option.regimen}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3} xl={3}>
          <TextField
            autoFocus
            margin="dense"
            id="clientPostalCode"
            name="zip"
            label="Código Postal"
            type="postal_code"
            placeholder="Escribe Código Postal"
            variant="filled"
            required
            value={client.zip}
            fullWidth
            error={!validatePostalCode(client.zip) && client.zip !== ""}
          />
        </Grid>

        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientStreet"
            name="street"
            label="Calle"
            type="text"
            placeholder="Escribe Calle"
            variant="filled"
            required
            value={client.street}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2} xl={2}>
          <TextField
            autoFocus
            margin="dense"
            id="clientStreetNumber"
            name="exterior"
            label="#Numero exterior"
            type="text"
            placeholder="Escribe Numero exterior"
            variant="filled"
            required
            value={client.exterior}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientEmail"
            name="email"
            label="Email"
            type="email"
            placeholder="Escribe Email"
            variant="filled"
            required
            value={client.email}
            fullWidth
            error={!validateEmail(client.email) && client.email !== ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientPhone"
            name="phone"
            label="Teléfono"
            type="tel"
            placeholder="Escribe Teléfono"
            variant="filled"
            required
            value={client.phone}
            fullWidth
            error={!validatePhoneNumber(client.phone) && client.phone !== ""}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddClients;
