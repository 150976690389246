import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//consultar cantidad de empresas registradas por usuario
export const axiosCountCompany = async () => {
  let url = `${API_HOST}/api/company/countCompany`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = axios.get(url, headersConfig);
  return response;
};
//Crear empresa
export const axiosCreateCompanyFacturaApi = async (name) => {
  let url = `${API_HOST}/api/company/create`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let company = {
    comercial_name: name,
  };
  let response = axios.post(url, company, headersConfig);

  return response;
};
//Buscar y listar Empresa
export const axiosShowCompanyFacturaApi = async (page = 1, search = "") => {
  let url = `${API_HOST}/api/company/show?page=${page}`;
  search.length === 0
    ? (url = `${API_HOST}/api/company/show?page=${page}`)
    : (url = `${API_HOST}/api/company/show/${search}?page=${page}`);

  //headers
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = axios
    .get(url, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Buscar empresa
export const axiosSearchCompany = async (q) => {
  let url = `${API_HOST}/api/company/show/${q}`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.get(url, headersConfig);

  return response;
};
//Eliminar empresa
export const axiosDeleteCompanyFacturaApi = async (id) => {
  let url = `${API_HOST}/api/company/delete/${id}`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.get(url, headersConfig);

  return response;
};
//cargar archivos csd de emmpresa
export const axiosCSDCompanyFacturaApi = async (
  password_csd,
  cer_file,
  key_file,
  id_facturapi
) => {
  let url = `${API_HOST}/api/company/uploadCSD`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
      "Content-Type": "multipart/form-data",
    },
  };

  let formData = new FormData();
  formData.append("id_organization", id_facturapi);
  formData.append("password", password_csd);
  formData.append("cerFile", cer_file);
  formData.append("keyFile", key_file);

  let response = axios.post(url, formData, headersConfig);

  return response;
};
//modificar informacion fiscal
export const axiosUpdateLegalDataFacturaApi = async (data) => {
  let url = `${API_HOST}/api/company/updateLegal`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = axios.put(url, data, headersConfig);

  return response;
};
//Agregar logo de la cunta y visible en el pdf
export const axiosUpdateLogo = async (img, company) => {
  let formData = new FormData();

  formData.append("avatar", img);
  formData.append("id", company.id);
  formData.append("id_organization", company.id_facturapi);

  let url = `${API_HOST}/api/company/upload-avatar-pic`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
      "Content-Type": "multipart/form-data",
    },
  };

  let response = axios.post(url, formData, headersConfig);

  return response;
};
//consultar el logo de la cuenta
export const axiosGetLogo = async (company) => {
  let url = `${API_HOST}/api/company/get-avatar-pic`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
      responseType: "arraybuffer",
    },
  };
  let data = {
    id_organization: company.id_facturapi,
    id: company.id,
  };
  let response = axios.post(url, data, headersConfig);

  return response;
};
//activar/desactivar el folio automatico
export const axiosActiveFolio = async (activeSwitch, folio_number, company) => {
  const url = `${API_HOST}/api/company/active-folio`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data =
    activeSwitch === true
      ? {
          next_folio_number: folio_number,
          active: true,
          id: company.id,
          id_facturapi: company.id_facturapi,
          key: key,
        }
      : {
          id: company.id,
          active: false,
          key: key,
        };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
