import React, { useEffect, useState } from 'react'
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material'
import styles from "../../components/Dashboard/styles.css";
import CountUp from "react-countup";
import { ContentPasteGo, Difference, Group, NotificationsActive } from '@mui/icons-material';
import { axiosDashboard } from '../../api/axiosDashboard';
import { toast } from 'react-toastify';
const Cards = () => {
    const [dataDashboard, setDataDashboard] = useState([]);
    useEffect(() => {
        axiosDashboard()
            .then(res => {
                setDataDashboard(res.data.data);
            }).catch(err => {
                toast.error('Ha ocurrido un problema al obtener tus pendientes.');
                // setLoading(false);
            });

    }, [])

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent={'center'} >
                <h2>Información General de la cuenta </h2>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={6} lg={3} sm={3} key={"Plan"}>
                    <Card
                        variant="elevation"
                        sx={{
                            p: 0,
                            borderLeft: '5px solid #33a3ff',
                            margin: '1% 2% !important',
                        }}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <ContentPasteGo />
                                Plan Activo
                            </Typography>
                            <Typography variant="h5">{dataDashboard?.plan}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={3} sm={3} key={"Timbres"} className={styles.recovered}>
                    <Card
                        variant="elevation"
                        sx={{
                            p: 0,
                            borderLeft: '5px solid #3cb371',
                            margin: '1% 2% !important',
                        }}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <Difference /> Total Facturado
                            </Typography>
                            <Typography variant="h5">
                                <CountUp
                                    start={0}
                                    end={dataDashboard?.bills}
                                    duration={1.5}
                                    separator=","
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={3} sm={3} key={"TotalFacturado"} 
                className={styles.deaths}>
                    <Card
                        variant="elevation"
                        sx={{
                            p: 0,
                            borderLeft: '5px solid #ff3370',
                            margin: '1% 2% !important',
                        }}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <NotificationsActive />
                                Timbres Disponibles
                            </Typography>
                            <Typography variant="h5">
                                <CountUp
                                    start={0}
                                    end={dataDashboard?.bells}
                                    duration={1.5}
                                    separator=","
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={3} sm={3} key={"Clientes Registrados"} className={styles.deaths}>
                    <Card
                        variant="elevation"
                        sx={{
                            p: 0,
                            borderLeft: '5px solid #E57200',
                            margin: '1% 2% !important',
                        }}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <Group />
                                Clientes Registrados
                            </Typography>
                            <Typography variant="h5">
                                <CountUp
                                    start={0}
                                    end={dataDashboard?.clients}
                                    duration={1.5}
                                    separator=","
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Cards
