import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Chip,
  Pagination,
  Stack,
  CircularProgress,
} from "@mui/material";
import {
  Block,
  Mail,
  Visibility,
  PictureAsPdf,
  ReceiptLong,
} from "@mui/icons-material";
import SkeletonTable from "../General/SkeletonTable";
import { formatDateLetter } from "../../utils/formats";
import CancelBills from "./CancelBills";
import {
  axiosCancellationReceipt,
  axiosShowIdBillsFacturaApi,
  axiosViewPDF,
} from "../../api/axiosBills";
import { toast } from "react-toastify";
import SendBills from "./SendBills";
import ViewBills from "./ViewBills";
import CancellationReceiptBills from "./CancellationReceiptBills";

const TableBills = ({
  loading,
  bills,
  totalPages,
  page,
  search,
  triggerAxiosPaginate,
}) => {
  const [billInfo, setBillInfo] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openReceiptModal, setOpenReceiptModal] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [acuseLoading, setAcuseLoading] = useState(false);
  // ComponentDidMount
  useEffect(() => {
    triggerAxiosPaginate(page);
  }, []);

  const handleChangePage = (event, page) => {
    triggerAxiosPaginate(page, search);
  };

  const billDetailsHandler = async (billId) => {
    setPdfLoading(true);
    axiosViewPDF(billId)
      .then((response) => {
        const array = new Uint8Array(response.data);
        const blob = new Blob([array], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(blob);
        setPdfLoading(false);
        window.open(fileURL);

        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setPdfLoading(false);
        return err;
      });
  };

  const handlerCancellationReceipt = () => {
    setOpenReceiptModal(!openReceiptModal);
  };

  const handlerBillCancelModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handlerBillSendModal = () => {
    setOpenSendModal(!openSendModal);
  };

  const handlerBillViewModal = (billId) => {
    if (!openViewModal) {
      setViewLoading(true);
      axiosShowIdBillsFacturaApi(billId)
        .then((response) => {
          setViewLoading(false);
          setBillInfo(response.data.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setViewLoading(false);
          return err;
        });
    }

    setOpenViewModal(!openViewModal);
  };

  return (
    <Card variant="elevation">
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440, borderTop: "8px solid #233B63" }}>
          <Table
            stickyHeader
            aria-label="custom pagination table"
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5"> Factura / UUID</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Fecha </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Cliente</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5"> Total</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5"> Estatus </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5"> Tipo </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5"> Acciones </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <SkeletonTable />
              ) : (
                bills?.map((bill, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="h6" fontWeight="600">
                        {" "}
                        {bill.series + "-" + bill.folio_number}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="h7"
                        fontWeight="400"
                      >
                        {" "}
                        {bill.uuid}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {formatDateLetter(bill.created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight="600">
                        {bill.customer.tax_id}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="h6"
                        fontWeight="400"
                        sx={{
                          width: "180px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {bill.customer.legal_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">${bill.total}</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        sx={{
                          backgroundColor:
                            bill.status === "valid"
                              ? bill.cancellation_status === "pending"
                                ? bill.cancellation_status === "rejected"
                                  ? bill.cancellation_status === "expired"
                                    ? (theme) => theme.palette.warning.light
                                    : (theme) => theme.palette.warning.light
                                  : (theme) => theme.palette.secondary.light
                                : (theme) => theme.palette.success.light
                              : (theme) => theme.palette.error.light,
                          color:
                            bill.status === "valid"
                              ? bill.cancellation_status === "pending"
                                ? bill.cancellation_status === "rejected"
                                  ? bill.cancellation_status === "expired"
                                    ? (theme) => theme.palette.warning.dark
                                    : (theme) => theme.palette.warning.dark
                                  : (theme) => theme.palette.secondary.dark
                                : (theme) => theme.palette.success.dark
                              : (theme) => theme.palette.error.dark,
                          borderRadius: "6px",
                          pl: "3px",
                          pr: "3px",
                        }}
                        size="small"
                        label={
                          bill.status === "valid"
                            ? bill.cancellation_status === "pending"
                              ? bill.cancellation_status === "rejected"
                                ? bill.cancellation_status === "expired"
                                  ? "Expirada"
                                  : "Cancelación rechazada"
                                : "Pendiente"
                              : "Timbrada"
                            : "Cancelada"
                        }
                      ></Chip>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        {bill.type === "I"
                          ? "Ingreso"
                          : bill.type === "P"
                          ? "Complemento"
                          : "Egreso"}
                      </Typography>
                    </TableCell>

                    <TableCell component="th" scope="bill">
                      <ButtonGroup
                        variant="contained"
                        aria-label="contained primary button group"
                      >
                        <Button
                          size="small"
                          color="success"
                          variant="contained"
                          onClick={() => {
                            setBillInfo(bill);
                            handlerBillViewModal(bill.id);
                          }}
                        >
                          {bill.id === billInfo.id && viewLoading ? (
                            <CircularProgress size={18} />
                          ) : (
                            <Visibility />
                          )}
                        </Button>
                        <Button
                          size="small"
                          color="inherit"
                          variant="contained"
                          onClick={() => {
                            setBillInfo(bill);
                            billDetailsHandler(bill.id);
                          }}
                        >
                          {bill.id === billInfo.id && pdfLoading ? (
                            <CircularProgress size={18} />
                          ) : (
                            <PictureAsPdf color="error" />
                          )}
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                        >
                          <Mail
                            onClick={() => {
                              setBillInfo(bill);
                              handlerBillSendModal();
                            }}
                          />
                        </Button>
                        {bill.status === "valid" &&
                          bill.cancellation_status === "none" && (
                            <Button
                              size="small"
                              color="error"
                              variant="contained"
                              disabled={bill.cancellation_status === "pending"}
                            >
                              <Block
                                onClick={() => {
                                  setBillInfo(bill);
                                  handlerBillCancelModal();
                                }}
                              />
                            </Button>
                          )}

                        {bill.status === "canceled" && (
                          <>
                            <Button
                              size="small"
                              color="error"
                              variant="outlined"
                            >
                              {bill.id === billInfo.id && acuseLoading ? (
                                <CircularProgress color="error" size={18} />
                              ) : (
                                <ReceiptLong
                                  onClick={() => {
                                    setBillInfo(bill);
                                    handlerCancellationReceipt(bill.id);
                                  }}
                                />
                              )}
                            </Button>
                          </>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack spacing={2} style={{ float: "right" }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </Paper>

      <CancelBills
        billInfo={billInfo}
        handlerBillCancelModal={handlerBillCancelModal}
        openDeleteModal={openDeleteModal}
        triggerAxiosPaginate={triggerAxiosPaginate}
      />

      <SendBills
        billInfo={billInfo}
        handlerBillSendModal={handlerBillSendModal}
        openSendModal={openSendModal}
      />

      <ViewBills
        billInfo={billInfo}
        handlerBillViewModal={handlerBillViewModal}
        openViewModal={openViewModal}
        viewLoading={viewLoading}
      />

      <CancellationReceiptBills
        billInfo={billInfo}
        openReceiptModal={openReceiptModal}
        setOpenReceiptModal={setOpenReceiptModal}
        handlerCancellationReceipt={handlerCancellationReceipt}
      />
    </Card>
  );
};

export default TableBills;
