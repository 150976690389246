import React, { useState } from "react";

import SelectPayRegister from "./SelectPayRegister";
import CardPayRegister from "./CardPayRegister";
import OxxoPayRegister from "./OxxoPayRegister";

const PaymentDataRegister = ({ handlepay }) => {
  const [state, setState] = useState({
    showSelect: true,
    showPayCard: false,
    showPayOxxo: false,
  });

  const handleClickPayCard = () => {
    setState({
      showSignUp: false,
      showPayCard: true,
      showPayOxxo: false,
    });
  };

  const handleClickPayOxxo = () => {
    setState({
      showSignUp: false,
      showPayOxxo: true,
      showPayCard: false,
    });
  };

  const handleClickPaySelect = () => {
    setState({
      showSignUp: true,
      showPayOxxo: false,
      showPayCard: false,
    });
  };

  let isShowPayCard = state.showPayCard === true;
  let isShowPayOxxo = state.showPayOxxo === true;

  return (
    <>
      {isShowPayCard ? (
        <CardPayRegister
          handleClickPaySelect={handleClickPaySelect}
          handlepay={handlepay}
        />
      ) : isShowPayOxxo ? (
        <OxxoPayRegister
          handleClickPaySelect={handleClickPaySelect}
          handlepay={handlepay}
        />
      ) : (
        <SelectPayRegister
          handleClickPayCard={handleClickPayCard}
          handleClickPayOxxo={handleClickPayOxxo}
          handlepay={handlepay}
        />
      )}
    </>
  );
};

export default PaymentDataRegister;
