import React from "react";

import {
  Home,
  Business,
  Group,
  Store,
  ListAlt,
  Settings,
  Description,
  Assessment,
} from "@mui/icons-material";

const Menuitems = [
  {
    title: "Inicio",
    icon: <Home />,
    href: "/dashboard",
  },
  {
    title: "Mis Empresas",
    icon: <Business />,
    href: "/business",
  },
  {
    title: "Mis clientes",
    icon: <Group />,
    href: "/clients",
  },
  {
    title: "Productos/Servicios",
    icon: <ListAlt />,
    href: "/products-services",
  },
  {
    title: "Facturación",
    icon: <Description />,
    href: "/bills",
  },
  {
    title: "Configuración",
    icon: <Settings />,
    href: "/config",
  },
  // {
  //     title: "Tienda",
  //     icon: <Store />,
  //     href: "/shop",
  // },
  {
    title: "Reportes",
    icon: <Assessment />,
    href: "/reports",
  },
];

export default Menuitems;
