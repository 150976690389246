import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import DashboardPage from "../pages/Dashboard/DashboardPage";
import FullLayout from "../layouts/FullLayout";
import ClientesPage from "../pages/Clients/ClientesPage";
import ProductsAndServicesPage from "../pages/ProductsAndServices/ProductsAndServicesPage";
import BillsPage from "../pages/Bills/BillsPage";
import ConfigPage from "../pages/Config/ConfigPage";
import ShopPage from "../pages/Shop/ShopPage";
import BusinessPage from "../pages/Business/BusinessPage";
import NewBillsPage from "../pages/Bills/NewBillsPage";
import NewComplementBillPage from "../pages/Bills/NewComplementBillPage";
import ReportsPage from "../pages/Reports/ReportsPage";
import MassBillsPage from "../pages/Bills/MassBillsPage";
import MassSendBillsPage from "../pages/Bills/MassSendBillsPage";

export const DashboardRouters = ({ props }) => {
  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<FullLayout />}>
            <Route path="/" element={<Navigate to="dashboard" />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/business" element={<BusinessPage />} />
            <Route path="/clients" element={<ClientesPage />} />
            <Route
              path="/products-services"
              element={<ProductsAndServicesPage />}
            />
            <Route path="/bills" element={<BillsPage />} />
            <Route path="/new-bill" element={<NewBillsPage />} />
            <Route
              path="/new-complement-bill"
              element={<NewComplementBillPage />}
            />
            <Route path="/config" element={<ConfigPage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/mass-bills" element={<MassBillsPage />} />
            <Route path="mass-send" element={<MassSendBillsPage />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};
