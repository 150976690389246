import axios from "axios";
import { TOKEN } from "../utils/constant";
import { API_HOST } from "../utils/constant";

//Axios login
export const signIn = async (user) => {
  const url = `${API_HOST}/api/auth/login`;

  let data = await axios.post(url, user);
  return data;
};

//Axios Register
export const signUpApi = async (user) => {
  const url = `${API_HOST}/api/register-basic-data`;
  const userTemp = {
    ...user,
    email: user?.email.toLowerCase(),
    country_code: user?.country_code,
  };

  let data = await axios.post(url, userTemp);

  return data;
};

//Save Storege Token
export const setStorageToken = (token) => {
  localStorage.setItem(TOKEN, token);
  let Token = localStorage.getItem(TOKEN);
  if (Token != null || Token.length > 0) {
    return true;
  }
  return false;
};

//Get the Storege Token
export const getTokenApi = () => {
  let token = localStorage.getItem(TOKEN);
  return token;
};

//information User
export const handlerGetAditionalData = async () => {
  const url = `${API_HOST}/api/me`;
  let response = await axios.post(url, "", {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  });
  return response;
};

export const logoutApi = async () => {
  const url = `${API_HOST}/api/logout`;

  let response = await axios.post(url, "", {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem(TOKEN),
    },
  });
  return response;
};

//Is login User
export const isUserLogedApi = () => {
  //validate the user login
  const token = getTokenApi();
  if (token) {
    return token;
  } else {
    return null;
  }
};
