import axios from "./axios";
import { TOKEN } from "../utils/constant";
import { isTotallyEmpty } from "../utils/validations";

export const useSendBills = () => {
  const config = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  const key = localStorage.getItem("api_key");

  const comfimsendBills = async ({
    setCount,
    setListStatusBills,
    setSuccessCount,
    sendEmails,
    sendBills,
    isClient,
  }) => {
    sendBills.map((bill) => {
      let data = {
        key: key,
        emails: sendEmails,
        idInvoice: bill.id,
        isClient: isClient,
      };

      axios
        .post("/api/bills/sendInvoiceMass", data, config)
        .then((res) => {
          if (res.data.status === false) {
            setCount((prevCount) => prevCount + 1);
            let newArr = {
              folio: bill.folio,
              email: sendEmails,
              message: res.data.message,
              status: "Sin enviar ",
            };
            setListStatusBills((prev) => [...prev, newArr]);
          } else {
            setCount((prevCount) => prevCount + 1);
            let newArr = {
              folio: bill.folio,
              email: JSON.stringify(sendEmails),
              message: "Factura Enviada ",
              status: "Enviada",
            };
            setSuccessCount((prevsuccessCount) => prevsuccessCount + 1);
            setListStatusBills((prev) => [...prev, newArr]);
          }
        })
        .catch((err) => {
          setCount((prevCount) => prevCount + 1);
          let newArr = {
            folio: bill.folio,
            email: JSON.stringify(sendEmails),
            message: "Error en envio intenta mas tarde. ",
            status: "Sin enviar",
          };
          setListStatusBills((prev) => [...prev, newArr]);
        })
        .finally(setCount(0), setListStatusBills([]));
    });
  };

  return {
    comfimsendBills,
  };
};
