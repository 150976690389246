import React, {useState} from 'react'
import { styled } from '@mui/material/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableHead,
    TableRow,

} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { toast } from 'react-toastify';

import { axiosUpdateFiscalDataUser } from '../../../api/fiscal';
import { LoadingButton } from '@mui/lab';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white,
        fontSize: 'larger',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,  
    },
    [`&.${tableCellClasses.body}`]: {

    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const DialogConfirmFiscalConfig = ({ isOpen, handleToggle,   mutableFiscalData }) => {
    const [loadingSave, setLoadingSave] = useState(false);
    const handleUpdateFiscalUser = (e) => {
        setLoadingSave(true);
        axiosUpdateFiscalDataUser(mutableFiscalData)
            .then(response => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        handleToggle();
                        setLoadingSave(false);

                    }, 10);
                } else {
                    toast.warning(response.data.message);
                    handleToggle();
                    setLoadingSave(false);
                }
            })
            .catch(err => {
                toast.error('Error en el servidor');
                setLoadingSave(false);
                return err;
            });
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleToggle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Estas seguro de guardar los datos, como la información fiscal para tu facturación?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={2}>
                                        Datos Fiscales
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}} > RFC:</StyledTableCell>
                                    <StyledTableCell align="right">{mutableFiscalData.tax_id}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}}> Denominación/Razón Social: </StyledTableCell>
                                    <StyledTableCell align="right">{mutableFiscalData.legal_name}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}}> Régimen: </StyledTableCell>
                                    <StyledTableCell align="right">{mutableFiscalData.tax_system}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}}> Código Postal: </StyledTableCell>
                                    <StyledTableCell align="right">{mutableFiscalData.zip}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}}> Calle: </StyledTableCell>
                                    <StyledTableCell align="right">{mutableFiscalData.street}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}}> Número Exterior: </StyledTableCell>
                                    <StyledTableCell align="right">{mutableFiscalData.tax_id}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton  loading={loadingSave} onClick={handleUpdateFiscalUser} 
                    variant='contained' color='primary'> Confirmar </LoadingButton>
                    <Button onClick={handleToggle} autoFocus>
                        Volver
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogConfirmFiscalConfig