import React from 'react'
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { axiosDeleteProductFacturaApi } from '../../api/axiosProducts';

const DeleteProducts = ({ product, handleToggle, isOpen, triggerAxiosPaginate }) => {
  const deleteProductHandler = () => {

    axiosDeleteProductFacturaApi(product.id).then(response => {      
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            handleToggle();
            triggerAxiosPaginate(1);
          }, 15);
        } else {
          toast.warning(response.data.message);
          handleToggle();
        }      
    }).catch(err => {
      toast.error("Ocurrió un error al hacer la petición.");
    });
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
        
      <DialogTitle id="alert-dialog-title">{`Producto o artículo a eliminar :  ${product.description}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
         Al confirmar, se eliminará este producto, si se realizo alguna factura con este producto ya no podrá relacionarlo despues 
         en alguna otra factura, ¿Desea continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={deleteProductHandler} variant='contained' >
          Eliminar
        </Button>
        <Button onClick={handleToggle} autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteProducts