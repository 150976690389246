import React from 'react'
import {
    TableCell,
    TableRow,
    Skeleton
} from '@mui/material';

const SkeletonTable = () => {
    return (
        <>
    { Array.from(Array(5)).map((_, index) => (
            <TableRow key={index}>
                <TableCell>

                    <Skeleton animation="wave" variant="text" height={30} />
                </TableCell>
                <TableCell>

                    <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>

                    <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>

                    <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" />
                </TableCell>
            </TableRow>

            ))}
        </>
    )
}

export default SkeletonTable