import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";

import ListConceptsNewBill from "./ListConceptsNewBill";
import SelectConceptNewBill from "./SelectConceptNewBill";
import NewProducts from "../../Products/NewProducts";
import { toast } from "react-toastify";
import { axiosSeachProductsFacturaApi } from "../../../api/axiosProducts";

const ConceptsNewBill = ({ bill, setBill }) => {
  const [concepts, setConcepts] = useState([]);
  const [concept, setConcept] = useState(initialState());
  const [products, setProducts] = useState([]);

  const getSearchProducts = (q = "") => {
    axiosSeachProductsFacturaApi(q)
      .then((response) => {
        setProducts(response.data.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error("Ha ocurrido un problema al obtener los clientes.");
        }

        return err;
      });
  };

  const handleAddProduct = () => {
    setConcepts((prevConcepts) => {
      let newArr = [];
      let isNew = true;
      for (let i = 0; i < prevConcepts.length; i++) {
        if (prevConcepts[i].conceptId === concept.conceptId) {
          isNew = false;
          newArr.push(concept);
        } else {
          newArr.push(prevConcepts[i]);
        }
      }

      if (isNew) {
        newArr.push(concept);
      }

      return newArr;
    });

    setTimeout(() => {
      setConcept(initialState());
    }, 15);
  };

  useEffect(() => {
    handlerAddConcept();
  }, [concepts]);

  const handlerAddConcept = () => {
    const dataConcept = concepts.map((c) => {
      // if (c.edit === true) {

      // } else {
      //     return [{ 'conceptId': c.conceptId, 'quantity': c.quantity }]
      // }
      return [c];
    });

    setBill({ ...bill, items: dataConcept });
  };

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems="center">
        <Typography
          variant="h6"
          fontWeight="600"
          marginBottom={"1%"}
          marginTop={"3%"}
        >
          Conceptos
        </Typography>
        <Grid item xs={12} lg={4}>
          <NewProducts
            originPosition={true}
            btnText={"Agregar un producto/servicio"}
            getSearchProducts={getSearchProducts}
            n={true}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />

      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <SelectConceptNewBill
          concepts={concepts}
          setConcepts={setConcepts}
          concept={concept}
          setConcept={setConcept}
          handleAddProduct={handleAddProduct}
          products={products}
          getSearchProducts={getSearchProducts}
          initialState={initialState}
        />
      </Grid>

      <Grid container>
        <Grid item xs={12} lg={12}>
          <ListConceptsNewBill concepts={concepts} setConcepts={setConcepts} />
        </Grid>
      </Grid>
    </>
  );
};

export default ConceptsNewBill;

function initialState() {
  return {
    description: "",
    product_key: "",
    product_text: "",
    taxes: [],
    local_taxes: [],
    price: "",
    conceptId: "",
    quantity: 0,
    conceptText: "", // Not on DB only logic
  };
}
