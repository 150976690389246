import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  Alert,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { axiosGetPlans } from "../../../api/userInfo";
import { axiosGenerateRecurrentOxxoPay } from "../../../api/axiosConekta";
import DialogOxxoPay from "../../../layouts/DialogOxxoPay";

const OxxoPayRegister = ({
  handleClickPaySelect,
  handlepay,
  setShowExitPrompt,
}) => {
  const [order, setOrder] = useState(initialFormValue());
  const [oxxoLoading, setOxxoLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (order.name === 0) {
      toast.warning("Por favor Selecciona Un Plan");
    } else {
      setOxxoLoading(true);
      axiosGenerateRecurrentOxxoPay(order.id)
        .then((response) => {
          if (response.data.status === true) {
            toast.success("El registro ha sido correcto.");
            setOrder({
              ...order,
              reference: response.data.data.payment_sources.reference,
              code: response.data.data.payment_sources.barcode_url,
            });
            setOpen(true);
            setOxxoLoading(false);
          } else {
            toast.warning(response.data.message);
            setOxxoLoading(false);
          }
        })
        .catch((err) => {
          setOxxoLoading(false);
        });
    }
  };

  useEffect(() => {
    axiosGetPlans()
      .then((response) => {
        let {
          data: { data: data },
        } = response;
        setPlans(data);
      })
      .catch((err) => {
        toast("Error al cargar Planes", err);
      });
  }, []);

  const onChange = (e) => {
    const filterPlan = plans.filter((item) => item.id === e.target.value);
    setOrder({
      ...order,
      id: filterPlan[0].id,
      name: filterPlan[0].name,
      oxxo_price: filterPlan[0].price,
    });
  };

  return (
    <>
      <DialogContent sx={{ p: 2 }}>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={0}
          color="primary"
        >
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Alert severity="info">
              <Typography component={"div"} variant="button">
                <b>Aquí podrás generar tu orden de pago en OXXO.</b>
              </Typography>
              <Typography>
                Recuerda que tienes de 2 a 3 días para realizar tu pago, una vez
                concluido el lapso de tiempo tu orden de pago ya no será válida.
              </Typography>
              <Typography variant="subtitle2">
                Puedes realizar tu pago en cualquier sucursal de OXXO de tu
                preferencia en todo México.
              </Typography>
            </Alert>
          </Grid>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <TextField
              id="select-plan"
              select
              label="Selecciona Un Plan"
              name="id"
              required
              fullWidth
              value={order.id}
              onChange={onChange}
              variant="filled"
              margin="normal"
            >
              {plans.map((list, index) => (
                <MenuItem key={index} value={list.id}>
                  {list.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
          }}
        >
          <Button
            id="Aceptar"
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            disabled={order.id === ""}
          >
            {!oxxoLoading ? "Aceptar" : <CircularProgress color="secondary" />}
          </Button>

          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Button onClick={() => handleClickPaySelect()}>
              Elegir otro metodo de pago
            </Button>
          </Grid>
        </Box>
        {/* <Grid item xs={12} sm={12} md={12} xl={12}>
          <Button onClick={() => handlepay()}>
            BasicData
          </Button>
        </Grid> */}
      </DialogActions>

      <DialogOxxoPay
        setOpen={setOpen}
        open={open}
        handleRegister={true}
        order={order}
      />
    </>
  );
};

function initialFormValue() {
  return {
    id: "",
    name: "",
    oxxo_price: "",
    reference: "Generando...",
    code: "",
  };
}

export default OxxoPayRegister;
