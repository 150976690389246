import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import esLocale from "date-fns/locale/es";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { dateFormat3Days } from "../../../utils/formats";
import paymentModes from "../../../utils/payment_modes.json";

import { axiosGetCfdiUses } from "../../../api/cfdiUses";
import { axiosSearchClientsFacturaApi } from "../../../api/axiosClients";
import NewClients from "../../Clients/NewClients";

const FormDataNewBill = ({
  bill,
  setBill,
  activeSwitch,
  activeFolio,
  masive = false,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [cfdiUses, setCfdiUses] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientSelected, setClientSelected] = useState({
    clientId: "",
    clientText: "",
  });

  useEffect(() => {
    axiosGetCfdiUses()
      .then((response) => {
        setCfdiUses(response.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error("Ha ocurrido un problema al obtener los usos de CFDI.");
        }

        return err;
      });

    getSearchClients();
  }, []);

  useEffect(() => {
    if (clientSelected != null) {
      setBill({
        ...bill,
        customer_id: clientSelected.id,
        customer_name: clientSelected.legal_name,
        customer_rfc: clientSelected.tax_id,
      });
    }
  }, [clientSelected]);

  const getSearchClients = (q = "") => {
    axiosSearchClientsFacturaApi(q)
      .then((response) => {
        setClients(response.data.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error("Ha ocurrido un problema al obtener los clientes.");
        }

        return err;
      });
  };

  const handlerClientSelected = (client) => {
    if (client != null) {
      return setClientSelected(client);
    }

    return setClientSelected({});
  };

  const handleClientSearch = (e, val, reason) => {
    if (reason !== "reset") {
      getSearchClients(val);
    }
  };

  /**
   *
   * @param {Date/String} date
   *
   */
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setBill({ ...bill, date: date });
  };
  /**
   *
   * @param {JS Handler} event
   *  Setting specific value on object's state
   */
  const handlePaymentModeChange = ({ target }) => {
    setBill({ ...bill, payment_form: target.value });
  };

  const handlePaymentMethodChange = ({ target }) => {
    setBill({ ...bill, payment_method: target.value });
  };
  const handleCurrencyChange = ({ target }) => {
    setBill({ ...bill, currency: target.value });
  };
  const handleSerieChange = ({ target }) => {
    setBill({ ...bill, series: target.value });
  };

  const handleFolioChange = ({ target }) => {
    setBill({ ...bill, folio_number: target.value });
  };
  const handleCfdiUseChange = ({ target }) => {
    setBill({ ...bill, use: target.value });
  };
  const handleBillTypeChange = ({ target }) => {
    setBill({ ...bill, type: target.value });
  };
  const handleExchangeChange = ({ target }) => {
    setBill({ ...bill, exchange: target.value });
  };

  const handleObservationChange = ({ target }) => {
    setBill({ ...bill, pdf_custom_section: target.value });
  };

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems="center">
        <Typography
          variant="h6"
          fontWeight="600"
          marginBottom={"1%"}
          marginTop={"2%"}
        >
          Datos
        </Typography>
        {masive === false && (
          <Grid item xs={12} lg={4}>
            <NewClients
              originPosition={true}
              btnText={"Agregar un Cliente"}
              getSearchClients={getSearchClients}
              n={true}
            />
          </Grid>
        )}
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />

      <Grid container spacing={1}>
        {masive === false && (
          <Grid item xs={12} sm={3} xl={3}>
            {activeSwitch === true ? (
              <TextField
                margin="dense"
                name="client"
                label="Cliente"
                type="text"
                variant="filled"
                fullWidth
                disabled
                value={"XAXX010101000 - PUBLICO EN GENERAL"}
              />
            ) : (
              <Autocomplete
                id="combo-clients"
                options={clients}
                getOptionDisabled={(option) =>
                  option.tax_id === "XAXX010101000"
                }
                // value={clientSelected.clientId}
                onChange={(e, client) => handlerClientSelected(client)}
                getOptionLabel={(clients) =>
                  `${clients.tax_id} - ${clients.legal_name}`
                }
                onInputChange={(e, client, reason) =>
                  handleClientSearch(e, client, reason)
                }
                renderOption={(props, client) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      "& > span": { mr: "10px", fontSize: 18 },
                    }}
                    {...props}
                  >
                    {`${client.tax_id} - ${client.legal_name}`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => handlerClientSelected(e, params)}
                    label="Cliente"
                    variant="filled"
                    placeholder="Escribe Cliente"
                    autoComplete="off"
                    margin="dense"
                  />
                )}
              />
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={masive === true ? 6 : 3}
          xl={masive === true ? 6 : 3}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DatePicker
              label="Fecha de creación"
              value={bill.created_at ? bill.created_at : selectedDate}
              onChange={handleDateChange}
              shouldDisableDate={dateFormat3Days()}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={masive === true ? 6 : 2}
          xl={masive === true ? 6 : 2}
        >
          {activeSwitch === true ? (
            <TextField
              margin="dense"
              name="type"
              label="Tipo de factura"
              type="text"
              variant="filled"
              fullWidth
              disabled
              value={"Ingreso"}
            />
          ) : (
            <FormControl variant="filled" sx={{ mt: 1 }} fullWidth required>
              <InputLabel id="bill-type-label">Tipo de factura</InputLabel>
              <Select
                labelId="bill-type-label"
                id="bill-type"
                label="Tipo de factura"
                value={bill?.type}
                onChange={handleBillTypeChange}
              >
                <MenuItem value={"I"}>Ingreso</MenuItem>
                <MenuItem value={"E"}>Egreso</MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
        {masive === false && (
          <Grid item xs={12} sm={2} xl={2}>
            <TextField
              margin="dense"
              name="series"
              label="Serie"
              type="text"
              placeholder="Codigo para control interno"
              variant="filled"
              required
              fullWidth
              onChange={handleSerieChange}
              value={bill?.series}
            />
          </Grid>
        )}
        {masive === false && (
          <Grid item xs={12} sm={2} xl={2}>
            <TextField
              margin="dense"
              name="folio_number"
              label="Folio"
              type="number"
              placeholder="Escribe Folio"
              variant="filled"
              fullWidth
              onChange={handleFolioChange}
              disabled={activeFolio}
              value={bill?.folio_number}
              helperText={
                activeFolio &&
                "Folio automatico activo, para editar es en configuración."
              }
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={masive === true ? 6 : 3}
          xl={masive === true ? 6 : 3}
        >
          <FormControl variant="filled" fullWidth required margin="dense">
            <InputLabel id="payment-method-label">Método de pago</InputLabel>
            <Select
              labelId="payment-method-label"
              id="payment-method"
              value={bill?.payment_method}
              onChange={handlePaymentMethodChange}
              label="Método de pago"
            >
              <MenuItem value={"PUE"}>
                PUE (Pago en Una sola Exhibición)
              </MenuItem>
              <MenuItem value={"PPD"}>
                PPD (Pago en Parcialidades o Diferido)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={masive === true ? 6 : 3}
          xl={masive === true ? 6 : 3}
        >
          <FormControl variant="filled" fullWidth required margin="dense">
            <InputLabel id="payment-mode-label">Forma de pago</InputLabel>
            <Select
              labelId="payment-mode-label"
              id="payment-mode"
              value={bill?.payment_form}
              onChange={handlePaymentModeChange}
              label="Forma de pago"
            >
              {bill?.payment_method === "PUE" ? (
                paymentModes.map((payment) => (
                  <MenuItem key={payment.id} value={payment.code}>
                    {`${payment.code} - ${payment.name}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={"99"} value={"99"}>
                  {`99 - Por definir`}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={masive === true ? 12 : 2}
          xl={masive === true ? 12 : 2}
        >
          <FormControl variant="filled" fullWidth required margin="dense">
            <InputLabel id="cfdi-use-label">Uso del CFDI</InputLabel>
            <Select
              labelId="cfdi-use-label"
              id="cfdi-use"
              value={bill?.use}
              onChange={handleCfdiUseChange}
              label="Uso del CFDI"
              disabled={activeSwitch ? true : false}
            >
              <MenuItem>Selecciona un valor</MenuItem>
              {cfdiUses.map((cfdiUse) => (
                <MenuItem value={cfdiUse.code} key={cfdiUse.code}>
                  {cfdiUse.code} | {cfdiUse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={masive === true ? 6 : 2}
          xl={masive === true ? 6 : 2}
        >
          <FormControl
            variant="filled"
            fullWidth
            required
            margin="dense"
            defaultValue="MXN"
          >
            <InputLabel id="payment-method-label">Divisa</InputLabel>
            <Select
              labelId="payment-method-label"
              id="payment-method"
              value={bill?.currency}
              onChange={handleCurrencyChange}
              label="Divisa"
              defaultValue="MXN"
            >
              <MenuItem value={"EUR"}>Euro</MenuItem>
              <MenuItem value={"MXN"}>Peso mexicano</MenuItem>
              <MenuItem value={"USD"}>Dólar estadounidense</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={masive === true ? 6 : 2}
          xl={masive === true ? 6 : 2}
        >
          <TextField
            margin="dense"
            name="exchange"
            label="Tipo Cambio"
            type="text"
            value={bill.exchange}
            onChange={handleExchangeChange}
            placeholder="Escribe Tipo Cambio"
            variant="filled"
            disabled={bill?.currency === "" || bill?.currency === "MXN"}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <FormControl fullWidth margin="dense" size="small">
            <TextField
              name="pdf_custom_section"
              variant="filled"
              label="Observaciones"
              multiline
              maxRows={4}
              value={bill?.pdf_custom_section}
              onChange={handleObservationChange}
            />
          </FormControl>
        </Grid>
        {masive === true && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="600" marginTop={"1%"}>
                Configuración de serie y folio con el que inicial
              </Typography>
              <Typography variant="subtitle2" marginBottom={"1%"}>
                La serie sera constante en todo el lote de faturas y el folio
                sera incremental
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <TextField
                margin="dense"
                name="series"
                label="Serie"
                type="text"
                placeholder="Codigo para control interno"
                variant="filled"
                required
                fullWidth
                onChange={handleSerieChange}
                value={bill?.series}
              />
            </Grid>
          </>
        )}
        {masive === true && (
          <Grid item xs={12} sm={6} xl={3}>
            <TextField
              margin="dense"
              name="folio_number"
              label="Folio"
              type="number"
              placeholder="Escribe Folio"
              variant="filled"
              fullWidth
              onChange={handleFolioChange}
              disabled={activeFolio}
              helperText={
                activeFolio &&
                "Folio automático activo, si requieres editar debe ser desde la configuración de usuario"
              }
              value={bill?.folio_number}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default FormDataNewBill;
