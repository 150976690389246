import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Divider,
  Alert,
  Skeleton,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import { axiosGetRegimes } from "../../../api/regimes";
import { axiosGetInfoFiscal } from "../../../api/fiscal";
import DialogConfirmFiscalConfig from "./DialogConfirmFiscalConfig";

const CardDataFiscalConfig = ({
  user,
  mutableFiscalData,
  setMutableFiscalData,
  handleChange,
}) => {
  const [regimes, setRegimes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openConfim, setOpenConfirm] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    axiosGetRegimes()
      .then((response) => {
        setRegimes(response.data.data);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  useEffect(() => {
    if (mutableFiscalData.has_cer_file == 1) {
      getFiscalData();
    }
  }, [mutableFiscalData.has_cer_file]);

  const getFiscalData = () => {
    axiosGetInfoFiscal()
      .then((response) => {
        let { data } = response;
        if (data.status) {
          toast.success(data.message);

          let legal = data.data.legal;
          setMutableFiscalData({
            ...mutableFiscalData,
            legal_name: legal.legal_name ? legal.legal_name : "",
            tax_id: legal.tax_id ? legal.tax_id : "",
            tax_system: legal.tax_system ? legal.tax_system : "",
            zip: legal.address.zip ? legal.address.zip : "",
            street: legal.address.street ? legal.address.street : "",
            exterior: legal.address.exterior ? legal.address.exterior : "",
            support_email: legal.support_email ? legal.support_email : "",
            phone: legal.phone ? legal.phone : "",
            website: legal.website ? legal.website : "",
          });

          setLoading(false);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  };

  const handleToggleConfirmFiscal = () => {
    setOpenConfirm(!openConfim);
  };

  const onChange = (event) => {
    setMutableFiscalData({
      ...mutableFiscalData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Card variant="outlined" sx={{ pb: 0, mb: 4, mr: 0, ml: 0 }}>
        <CardContent sx={{ pb: 0 }}>
          {mutableFiscalData.has_cer_file == 1 &&
          mutableFiscalData.has_key_file == 1 ? (
            <Grid container spacing={0} justifyContent={"center"}>
              <Grid
                item
                sm={10}
                lg={11}
                xs={12}
                sx={{ mt: { xs: 2, sm: 0, lg: 0 } }}
              >
                <Typography variant="h6" fontWeight="600" marginBottom={"2%"}>
                  Datos Fiscales
                </Typography>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={"100%"}
                  />
                ) : (
                  <>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField
                          margin="dense"
                          name="legal_name"
                          label="Razón Social"
                          type="text"
                          placeholder="Nombre Fiscal o Razón Social, sin el régimen societario."
                          fullWidth
                          variant="filled"
                          required
                          value={mutableFiscalData?.legal_name}
                          disabled
                          helperText="Nombre de la persona que quedará registrada en los procesos de facturación"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField
                          margin="dense"
                          id="user-rfc"
                          name="tax_id"
                          label="RFC"
                          inputProps={{ maxLength: 13 }}
                          type="text"
                          placeholder="Escribe Nombre"
                          variant="filled"
                          required
                          disabled
                          value={mutableFiscalData?.tax_id}
                          fullWidth
                          helperText="Emisor que quedará registrado en los procesos de facturación"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField
                          id="select-regime"
                          select
                          name="tax_system"
                          margin="dense"
                          label="Régimen"
                          value={mutableFiscalData.tax_system}
                          onChange={onChange}
                          helperText="Regimen que quedarán registrados en los procesos de facturación"
                          required
                          fullWidth
                          variant="filled"
                        >
                          {regimes?.map((option) => (
                            <MenuItem key={option.id} value={option.code}>
                              {option.code} - {option.regimen}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: "30px" }} />
                    <Typography
                      variant="h6"
                      fontWeight="600"
                      marginBottom={"2%"}
                      marginTop={"3%"}
                    >
                      Domicilio Fiscal / Empresa
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={6} xl={6}>
                        <TextField
                          margin="dense"
                          id="profileStreet"
                          name="street"
                          label="Calle"
                          type="text"
                          size="small"
                          placeholder="Escribe Calle"
                          variant="filled"
                          required
                          helperText="Calle donde radica"
                          onChange={onChange}
                          value={mutableFiscalData?.street}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <TextField
                          margin="dense"
                          id="profileStreetNumber"
                          name="exterior"
                          label="#"
                          type="number"
                          placeholder="Escribe Numero exterior"
                          variant="filled"
                          size="small"
                          required
                          helperText="Numero ext."
                          onChange={onChange}
                          value={mutableFiscalData?.exterior}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <TextField
                          margin="dense"
                          id="profilePostalCode"
                          name="zip"
                          label="Código Postal"
                          type="text"
                          placeholder="Escribe Código Postal"
                          variant="filled"
                          size="small"
                          required
                          fullWidth
                          helperText="Codigo postal "
                          inputProps={{ maxLength: 6 }}
                          onChange={onChange}
                          value={mutableFiscalData?.zip}
                          // error={!validatePostalCode(profile.postal_code) && profile.postal_code != ''}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <TextField
                          margin="dense"
                          id="phone"
                          name="phone"
                          label="Teléfono"
                          type="phone"
                          placeholder="Escribe Teléfono"
                          variant="filled"
                          size="small"
                          inputProps={{ maxLength: 10 }}
                          required
                          helperText="Numero Telefónico"
                          onChange={onChange}
                          value={mutableFiscalData?.phone}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                        <TextField
                          margin="dense"
                          id="webSite"
                          name="website"
                          label="Sitio web"
                          type="text"
                          placeholder="Escribe Sitio web"
                          variant="filled"
                          size="small"
                          required
                          helperText="www.miempresa.com"
                          onChange={onChange}
                          value={mutableFiscalData?.website}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} xl={6}>
                        <TextField
                          margin="dense"
                          id="support_email"
                          name="support_email"
                          label="Correo de soporte"
                          type="email"
                          placeholder="Escribe Correo de soporte"
                          variant="filled"
                          size="small"
                          required
                          helperText="soporte@midominio.com"
                          onChange={onChange}
                          value={mutableFiscalData?.support_email}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      justifyContent="flex-end"
                      marginTop={"5%"}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={
                          mutableFiscalData.street === "" ||
                          mutableFiscalData.exterior === "" ||
                          mutableFiscalData.zip === ""
                        }
                        onClick={() => handleToggleConfirmFiscal()}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={0} justifyContent={"center"}>
              <Alert variant="outlined" severity="warning">
                <Typography variant="h5" gutterBottom component="div">
                  Para poder editar o visualizar tu información Fiscal es
                  necesario completar la carga de CSD
                </Typography>
              </Alert>

              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Skeleton animation="wave" height={40} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Skeleton animation="wave" height={30} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Skeleton animation="wave" height={30} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Skeleton animation="wave" height={40} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Skeleton animation="wave" height={40} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Skeleton animation="wave" height={40} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Skeleton animation="wave" height={30} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Skeleton animation="wave" height={30} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>

        <DialogConfirmFiscalConfig
          isOpen={openConfim}
          handleToggle={handleToggleConfirmFiscal}
          mutableFiscalData={mutableFiscalData}
        />
      </Card>
    </>
  );
};

export default CardDataFiscalConfig;
