import React, { useState } from 'react'
import {
  Grid,
} from '@mui/material';

import BaseCard from '../../components/General/BaseCard';
import ListBusiness from '../../components/Business/ListBusiness';
import NewBusiness from '../../components/Business/NewBusiness';
import SearchBusiness from '../../components/Business/SearchBusiness';
import { toast } from 'react-toastify';
import { axiosShowCompanyFacturaApi } from '../../api/axiosCompany';

const BusinessPage = () => {
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const triggerAxiosPaginate = (page = 1, search ) => {
    setLoading(true);
    // api
    axiosShowCompanyFacturaApi(page, search)
      .then(response => {
        if(response.data.status === true){

          let { data: { data: data } } = response;
          
          setPage(data.current_page);
          setBusiness(Object.values(data.data));
          setTotalPages(data.last_page);
          setLoading(false);
        }else{
          setLoading(false);
          setBusiness([]);
          toast.warning(response.data.message);
        }

      })
      .catch(err => {
        if (err.response.status === 401) {
          toast.error(err.response.data.message);
        } else {

          toast.error('Error en el servidor');
        }
        setLoading(false);
        return err;
      });
  }
  return (
    <Grid container spacing={0}>
      <BaseCard title="EMPRESAS">
        <Grid container justifyContent={'space-between'} alignItems='center' >
          <Grid item xs={12} lg={4}>
            <SearchBusiness
            triggerAxiosPaginate={triggerAxiosPaginate}
            search={search}
            setSearch={setSearch} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <NewBusiness
              originPosition={true}
              btnText={'Crear Empresa'}
              triggerAxiosPaginate={triggerAxiosPaginate} />
          </Grid>
        </Grid>

        <Grid container>

          <Grid item xs={12} lg={12}>
            <ListBusiness
              business={business}
              totalPages={totalPages}
              page={page}
              loading={loading}
              search={search}
              triggerAxiosPaginate={triggerAxiosPaginate} />
          </Grid>
        </Grid>
      </BaseCard>
    </Grid>
  )
}

export default BusinessPage