import axios from "./axios";
import { TOKEN } from "../utils/constant";

export const useGetLists = () => {
  const getCountries = async ({ setCountries }) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem(TOKEN),
      },
    };

    axios
      .get("/api/tools/getCountries", config)
      .then((res) => setCountries(res.data.data))
      .catch((err) => console.log(err));
  };

  return {
    getCountries,
  };
};
