import React, { useState } from 'react'
import {
    Grid,
} from '@mui/material'

import BaseCard from '../../components/General/BaseCard';
import TableClients from '../../components/Clients/TableClients';
import SearchClients from '../../components/Clients/SearchClients';
import NewClients from '../../components/Clients/NewClients';
import { toast } from 'react-toastify';
import { axiosGetClientsFacturaApi } from '../../api/axiosClients';

const ClientesPage = () => {
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const triggerAxiosPaginate = (page = 1, search) => {
        
        setLoading(true);
        // api
        axiosGetClientsFacturaApi(page, search)
            .then(response => {
                if (response.data.status === false) {
                    toast.warning(response.data.message);
                } else {

                    // let { data: { data: data } } = response;

                    setLoading(false);
                    setPage(response.data.data.page);
                    setTotalPages(response.data.data.total_pages);
                    setClients(response.data.data.data);
                }

            })
            .catch(err => {
              
                    toast.error('Error en el servidor');
              
                setLoading(false);
                return err

            });
    }

    return (
        <Grid container spacing={0}>
            <BaseCard title="CLIENTES">
                <Grid container justifyContent={'space-between'} alignItems='center' >
                    <Grid item xs={12} lg={4}>
                        <SearchClients
                            triggerAxiosPaginate={triggerAxiosPaginate}
                            search={search}
                            setSearch={setSearch}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <NewClients
                            originPosition={true}
                            btnText={'Agregar un Cliente'}
                            triggerAxiosPaginate={triggerAxiosPaginate} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <TableClients
                            loading={loading}
                            clients={clients}
                            totalPages={totalPages}
                            page={page}
                            search={search}
                            triggerAxiosPaginate={triggerAxiosPaginate}
                        />
                    </Grid>
                </Grid>
            </BaseCard>
        </Grid>

    )
}

export default ClientesPage