import React, { useState } from "react";
import { Card, Button } from "@mui/material";
import DialogAddCard from "../../Card/DialogAddCard";
const CardNewPaymentConfig = ({ handleGetCard }) => {
  const [openAddCard, setOpenAddCard] = useState(false);

  const handleOpenAddCard = () => {
    setOpenAddCard(!openAddCard);
  };

  return (
    <>
      <Card variant="outlined" sx={{ p: 3 }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleOpenAddCard}
        >
          Agregar Tarjeta
        </Button>

        <DialogAddCard
          setOpen={setOpenAddCard}
          open={openAddCard}
          handleGetCard={handleGetCard}
        />
      </Card>
    </>
  );
};

export default CardNewPaymentConfig;
