import React from 'react';

import { Navigate } from 'react-router-dom';
import { isUserLogedApi  } from "../api/auth";

const PrivateRoute = ({ children }) => {

   let auth = isUserLogedApi();

    return auth !== null
        ? children
        : <Navigate to="/auth" />
};

export default PrivateRoute;