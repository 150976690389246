import React, { useState } from 'react'
import {
    Grid,
} from '@mui/material'

import BaseCard from '../../components/General/BaseCard';
import TableProducts from '../../components/Products/TableProducts';
import NewProducts from '../../components/Products/NewProducts';
import { SearchProducts } from '../../components/Products/SearchProducts';
import { toast } from 'react-toastify';
import { axiosGetProductFacturaApi } from '../../api/axiosProducts';

const ProductsAndServicesPage = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const triggerAxiosPaginate = (page = 1, search) => {

        setLoading(true);
        // api
        axiosGetProductFacturaApi( page, search )
            .then(response => {
                let { data: { data: data } } = response;
                setLoading(false);
                setPage(data.page);
                setTotalPages(data.total_pages);
                setProducts(data.data);

            })
            .catch(err => {
                if (err.response.status === 401) {
                    toast.error(err.response.data.message);
                } else {

                    toast.error('Error en el servidor');
                }
                setLoading(false);
                return err

            });

    }
    return (
        <Grid container spacing={0}>
            <BaseCard title="PRODUCTOS Y SERVICIOS">
                <Grid container justifyContent={'space-between'} alignItems='center' >
                    <Grid item xs={12} lg={4}>
                        <SearchProducts 
                        triggerAxiosPaginate={triggerAxiosPaginate}
                        search={search}
                        setSearch={setSearch}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <NewProducts
                            originPosition={true}
                            btnText={'Agregar un producto/servicio'}
                            triggerAxiosPaginate={triggerAxiosPaginate} />
                    </Grid>
                </Grid>

                <Grid container>

                    <Grid item xs={12} lg={12}>
                        <TableProducts
                            loading={loading}
                            products={products}
                            totalPages={totalPages}
                            page={page}
                            search={search}
                            triggerAxiosPaginate={triggerAxiosPaginate} />
                    </Grid>
                </Grid>
            </BaseCard>
        </Grid>
    )
}

export default ProductsAndServicesPage