import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//Consultar informcaion FIscal del usuario
export const axiosGetInfoFiscal = async () => {
  const url = `${API_HOST}/api/bills/getInfoOrganization`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };
  let response = await axios.post(url, data, headersConfig);
  return response;
};
//Cargar CFDI del Usuario
export const axiosCSDClientFacturaApi = async (
  password_csd,
  cer_file,
  key_file
) => {
  let url = `${API_HOST}/api/client/uploadCSD`;

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
      "Content-Type": "multipart/form-data",
    },
  };

  let formData = new FormData();

  formData.append("password", password_csd);
  formData.append("cerFile", cer_file);
  formData.append("keyFile", key_file);

  let response = axios.post(url, formData, headersConfig);

  return response;
};

export const axiosUpdateFiscalDataUser = async (data) => {
  const url = `${API_HOST}/api/user/updateLegal`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.put(url, data, headersConfig);
  return response;
};
