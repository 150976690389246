

const Shopitems = [
  {
    id: 1,
    badge: 'none',
    name: 'Plan Esencial',
    price: '149.50',
    oxxo: '5,499.50',
    conekta_name: 'ContaliteAnual',
    beneficios: [
      '50 Folios',
      '$2.20 Folio extra',
      ],
    btnsize: 'medium',
    btncolor: (theme) => theme.palette.info.main,
    link: '/tienda-subscripciones',
  },
  {
    id: 2,
    badge: 'flex',
    name: 'Plan Empresarial',
    price: '250.00',
    oxxo: '555.00',
    conekta_name: 'ContaliteMensual',
    anual: true,
    beneficios: [
      '100 Folios',
      '$2.10 Folio extra',
        ],
    btnsize: 'medium',
    btncolor: (theme) => theme.palette.primary.main,
  },
  {
    id: 3,
    badge: 'none',
    name: 'Plan Ejecutivo',
    price: '799.50',
    oxxo: '888.50',
    conekta_name: 'contalitenminas',
    beneficios: [
      '150 Folios',
      '$2.00 Folio extra',
     ],
    btnsize: 'medium',
    btncolor: (theme) => theme.palette.info.main,
  },
  {
    id: 4,
    badge: 'none',
    name: 'Plan Anual',
    price: '699.50',
    oxxo: ' 777.50',
    conekta_name: 'contaliteplataformas',
    beneficios: [
      '1,000 Folios',
      '$1.50 Folio extra',
     ],
    restriccion: '*   Aplica restricciones',
    btnsize: 'medium',
    btncolor: (theme) => theme.palette.primary.main,
  },
];


export default Shopitems;