import React, { useContext, useState } from "react";
import {
  Avatar,
  Card,
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { PhotoCamera } from "@mui/icons-material";
import { logoutApi } from "../../../api/auth";
import { TOKEN } from "../../../utils/constant";
import { AuthContext } from "../../../auth/AuthContext";
import BackdropLoader from "../../../layouts/BackdropLoader";
import { axiosUploadAvatarPic } from "../../../api/userInfo";

import { toast } from "react-toastify";
import { axiosActiveFolio } from "../../../api/userInfo";

const CardAvatarDataConfig = ({ mutableUserData, setMutableUserData }) => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [activeSwitch, setActiveSwitch] = useState(mutableUserData.folio_check);
  const [folio, setFolio] = useState(mutableUserData.folio_init);
  const handlerUploadFileChange = ({ target }) => {
    let file = target.files[0];
    setUploadImage(file);
  };

  const uploadTrigger = () => {
    axiosUploadAvatarPic(uploadImage)
      .then((res) => {
        if (res?.data?.image) {
          localStorage.setItem("image", res.data.image);
          setUser({ ...user, image: res.data.image, has_avatar: true });
          setMutableUserData({
            ...mutableUserData,
            image: res.data.image,
            has_avatar: true,
          });
        }
        toast.success("Se a actualizado la imagen de la cuenta");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.warning("El peso máximo de la imagen debe ser de 500KB");
        } else if (err.response.status === 422) {
          toast.warning("Formato de imagen inválido.");
        } else {
          toast.error("Error al actualizar la imagen de la cuenta");
        }
      });
  };

  //Cerrar sesion
  const handleLogout = () => {
    setLoading(true);
    logoutApi()
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          localStorage.removeItem(TOKEN);
          localStorage.removeItem("api_key");
          setUser({ name: "user" });
          navigate("/auth/login");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  //Activar folio automatico
  const handleActiveFolio = () => {
    axiosActiveFolio(activeSwitch, folio)
      .then((response) => {
        if (response.data.status === true) {
          setUser({
            ...user,
            folio_check: response.data.folio_check,
            folio_init: response.data.folio_init,
          });
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Error En el servidor, intenta mas tarde");
        return err;
      });
  };

  return (
    <>
      {loading && <BackdropLoader loading={loading} />}
      <Card variant="outlined" sx={{ p: 3 }}>
        <Box
          sx={{
            background: "-webkit-linear-gradient(left,#233B63,#365d9e)",
            padding: "5px",
            borderRadius: "50%",
            width: "121px",
            height: "121px",
            overflow: "hidden",
            margin: "16px auto 10px auto",
          }}
        >
          <Avatar
            src={
              mutableUserData.has_avatar && mutableUserData.image
                ? `data:image/png;base64,${mutableUserData.image}`
                : mutableUserData.name[0]
            }
            alt={mutableUserData.name[0]}
            sx={{ width: 110, height: 110 }}
          />
        </Box>
        <Box
          sx={{
            justifyItems: "center",
            display: "grid",
            m: { xs: "auto -20px 15px -20px" },
          }}
        >
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="icon-button-file"
            type="file"
            onChange={handlerUploadFileChange}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera />
            </IconButton>
            <label>
              {!uploadImage ? "Selecciona una image" : uploadImage.name}
            </label>
          </label>

          <label htmlFor="contained-button-avatar">
            <Button
              color="primary"
              variant="contained"
              disabled={!uploadImage}
              onClick={uploadTrigger}
              aria-label="upload picture"
              component="span"
            >
              Subir
            </Button>
          </label>
        </Box>
        <Divider style={{ marginTop: "30px" }} />
        <Box
          sx={{
            justifyItems: "center",
            display: "grid",
            m: { xs: "auto -20px 15px -20px" },
          }}
        >
          <Typography variant="h6" fontWeight="600">
            Folio automatico
          </Typography>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <FormControlLabel
                value={activeSwitch}
                control={
                  <Switch
                    color="info"
                    checked={activeSwitch === 0 ? false : true}
                  />
                }
                onClick={() => setActiveSwitch(!activeSwitch)}
                label="Activar Folio automatico"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} m={2}>
              <TextField
                margin="dense"
                name="folio"
                label="Folio Inicial"
                type="number"
                variant="filled"
                fullWidth
                value={folio}
                onChange={(event) => setFolio(event.target.value)}
                disabled={!activeSwitch}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleActiveFolio}
                disabled={activeSwitch && folio === ""}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ marginTop: "30px" }} />
        <Box
          sx={{
            justifyItems: "center",
            display: "grid",
            m: { xs: "auto -20px 15px -20px" },
          }}
        >
          <Typography variant="h6" fontWeight="600" sx={{ mt: 3, mb: 1 }}>
            Editar contraseña de usuario.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            // onClick={handlerTogglePassword}
          >
            {" "}
            Editar{" "}
          </Button>
        </Box>

        <Divider style={{ marginTop: "30px" }} />
        <Button
          color="error"
          variant="contained"
          sx={{ mt: 3 }}
          fullWidth
          onClick={handleLogout}
        >
          Salir del sistema
        </Button>
      </Card>
    </>
  );
};

export default CardAvatarDataConfig;
