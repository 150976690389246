import React from 'react';
import { Outlet } from 'react-router-dom';


import TParticles from '../../components/TParticles/TParticles';
import logo from '../../img/FacturaliteLogoDB.png';
import {
    CardMedia,
    Dialog,
    DialogTitle,
} from '@mui/material';

const SignInSignUp = () => {

    return (
        <>
            <TParticles />

            <Dialog
                open={true}
                scroll={'paper'}
                maxWidth={'sm'}
                fullWidth
                >
                <DialogTitle id="scroll-dialog-title">
                    <CardMedia
                        component="img"
                        height="85"
                        image={logo}
                        alt="Logo"
                        sx={{ objectFit: 'contain' }}
                    />
                </DialogTitle>
                <Outlet />

            </Dialog>


        </>


    )
}

export default SignInSignUp