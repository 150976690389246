import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
  Slide,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LinearWithValueLabel from "../../../layouts/LinearWithValueLabel";
import { useStampBill } from "../../../hooks/useStampBill";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}
const DialogConfirmMassBillingBills = ({
  openConfirmMassModal,
  bill,
  setBill,
  setLoading,
  setActiveSuccess,
  handleCancel,
  handlerConfirmMassModal,
  selectedClients,
}) => {
  const { stampBills } = useStampBill();
  const [count, setCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [listStatusBills, setListStatusBills] = useState([]);
  const [active, setActive] = useState(false);

  const handlerStampBills = () => {
    stampBills({
      setCount,
      selectedClients,
      bill,
      setBill,
      setListStatusBills,
      setSuccessCount,
    });
    setActive(true);
  };

  useEffect(() => {
    if (openConfirmMassModal === false) {
      setCount(0);
      setListStatusBills([]);
      setActive(false);
    }
  }, [openConfirmMassModal]);

  const downloadExcel = () => {
    let header = ["Cliente", "Mensaje", "Estatus"];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(listStatusBills, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "ReporteFacturaMasivaFacturalite.xlsx");
      });
    });
  };
  return (
    <Dialog
      open={openConfirmMassModal}
      fullWidth={true}
      TransitionComponent={Transition}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        ¿Confirmas el inicio de la facturación para los siguientes clientes?
        <Typography variant="h6" component="div" ml={2} mb={2}>
          {selectedClients.length} clientes seleccionados
        </Typography>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          <List dense={true}>
            {selectedClients.map((client, index) => (
              <>
                <ListItem key={index}>
                  <ListItemText primary={client.legal_name} />
                </ListItem>
              </>
            ))}
          </List>
        </DialogContentText>
        <Box sx={{ width: "100%" }}>
          <LinearWithValueLabel count={count} total={selectedClients.length} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {count === selectedClients.length && (
          <>
            <Typography variant="subtitulo1" component="div" ml={2}>
              Se han Timbrado {successCount} de {selectedClients.length}{" "}
              facturas.
            </Typography>
            <List dense={true}>
              {listStatusBills.map((err, index) => (
                <>
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        err.status === "Timbrada"
                          ? "Factura generada" + " a " + err?.client
                          : "No se pudo generar la factura " +
                            " a " +
                            err?.client
                      }
                      secondary={err?.message}
                    />
                    {err.status === "Timbrada" ? (
                      <GridCheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </ListItem>
                </>
              ))}
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {count === selectedClients.length && active && (
          <>
            <Button onClick={handleCancel}>Salir</Button>
            <Button
              color="success"
              variant="contained"
              onClick={() => downloadExcel()}
            >
              Exportar y Descargar Reporte
            </Button>
          </>
        )}
        {!active && <Button onClick={handlerConfirmMassModal}>Volver</Button>}
        <Button
          onClick={handlerStampBills}
          variant={"contained"}
          color={"success"}
          disabled={active}
        >
          Timbrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmMassBillingBills;
