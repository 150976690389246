export function isEmailValid(email) {
  // eslint-disable-next-line no-useless-escape
  const emailValid =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  return emailValid.test(String(email).toLowerCase());
}

export function isPhoneValid(phone) {
  const phoneValid = /^\d{10}$/;

  return phoneValid.test(phone);
}

export const validateRfc = (rfc) => {
  const reg =
    /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;

  return reg.test(rfc);
};

export const validatePhoneNumber = (phoneNumber) => {
  const reg = /^[0-9]{10}$/;

  return reg.test(phoneNumber);
};

export const validateEmail = (email) => {
  const reg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return reg.test(email);
};

export const validateUuid = (uuid) => {
  const reg =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  return reg.test(uuid);
};

export const validatePostalCode = (postalCode) => {
  const reg = /^\d{5}$/;

  return reg.test(postalCode);
};

export const validateMatchPasswords = (pwd, pwd2) => {
  return pwd === pwd2;
};

export const isTotallyEmpty = (variable) => {
  return (
    !variable ||
    0 === variable?.length ||
    variable === "" ||
    variable === "undefined" ||
    variable === null
  );
};
