import React, { useState, useEffect } from 'react'
import {
    TextField,
    Grid,
    Divider,
    Autocomplete,
    Box,
    FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import esLocale from 'date-fns/locale/es';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { dateFormat3Days } from '../../../utils/formats';
import { axiosSearchClientsFacturaApi } from '../../../api/axiosClients';

const FormDataNewComplementBill = ({ complementBill, setComplementBill }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [clients, setClients] = useState([]);
    const [clientSelected, setClientSelected] = useState({
        clientId: '',
        clientText: ''
    });

    useEffect(() => {

        getSearchClients();

    }, [])

    useEffect(() => {

        if (clientSelected != null) {
            setComplementBill({ ...complementBill, customer_id: clientSelected.id });
        }

    }, [clientSelected]);


    const getSearchClients = (q = '') => {
        axiosSearchClientsFacturaApi(q)
            .then(response => {
                setClients(response.data.data.data);
            }).catch(err => {
                if (err.response?.status === 401) {
                    toast.error(err?.response.data.message);
                } else {

                    toast.error('Ha ocurrido un problema al obtener los clientes.');
                }

                return err;
            });
    }

    const handlerClientSelected = (client) => {
        if (client != null) {

            return setClientSelected(client);

        }

        return setClientSelected({});
    }

    const handleClientSearch = (e, val, reason) => {
        if (reason !== "reset") {

            getSearchClients(val);
        }
    }

    /**
  * 
  * @param {Date/String} date 
  * 
  */
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setComplementBill({ ...complementBill, date: date });
    };

    const handleSerieChange = ({ target }) => {
        setComplementBill({ ...complementBill, series: target.value });
    };

    const handleFolioChange = ({ target }) => {
        setComplementBill({ ...complementBill, folio_number: target.value })
    };

    const handleObservationChange = ({ target }) => {
        setComplementBill({ ...complementBill, pdf_custom_section: target.value });
    }

    return (
        <>
            <Divider style={{ marginBottom: "20px" }} />

            <Grid container spacing={1}>

                <Grid item xs={12} sm={4} xl={4}>
                    <Autocomplete
                        id="combo-clients"
                        fullWidth
                        options={clients}
                        onChange={(e, client) => handlerClientSelected(client)}
                        getOptionLabel={(clients) => (`${clients.tax_id} - ${clients.legal_name}`)}
                        onInputChange={(e, client, reason) => handleClientSearch(e, client, reason)}
                        renderOption={(props, client) => (
                            <Box
                                component="li"
                                sx={{ fontSize: 15, "& > span": { mr: "10px", fontSize: 18 } }}
                                {...props} >

                                {`${client.tax_id} - ${client.legal_name}`}
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params}
                            onChange={(e) => handlerClientSelected(e, params)}
                            label="Cliente"
                            variant="filled"
                            placeholder='Escribe Cliente'
                            autoComplete="off"
                            margin='dense'
                        />}
                    />
                </Grid>

                <Grid item xs={12} sm={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DatePicker
                            label="Fecha de creación"

                            value={complementBill?.data ? (complementBill.date) : (selectedDate)}
                            onChange={handleDateChange}
                            shouldDisableDate={dateFormat3Days()}
                            maxDate={new Date()}
                            renderInput={(params) => <TextField {...params}
                                variant="filled"
                                margin="dense"
                                fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={2} xl={2}>
                    <TextField
                        margin="dense"
                        name="series"
                        label="Serie"
                        type="text"
                        placeholder='Codigo para control interno'
                        variant="filled"
                        required
                        fullWidth
                        onChange={handleSerieChange}
                        value={complementBill?.series}
                    />
                </Grid>

                <Grid item xs={12} sm={2} xl={2}>
                    <TextField
                        margin="dense"
                        name="folio_number"
                        label="Folio"
                        type="text"
                        placeholder='Escribe Folio'
                        variant="filled"
                        fullWidth
                        onChange={handleFolioChange}
                        value={complementBill?.folio_number}
                    />
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                    <FormControl
                        fullWidth
                        margin='dense'
                        size='small'>
                        <TextField
                            name='pdf_custom_section'
                            variant="filled"
                            label="Observaciones"
                            multiline
                            maxRows={4}
                            value={complementBill?.pdf_custom_section}
                            onChange={handleObservationChange}>
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}

export default FormDataNewComplementBill