import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validateRfc,
} from "../../utils/validations";
import { axiosGetRegimes } from "../../api/regimes";

const ClientEdit = ({ onChange, clientEdit }) => {
  const [regimes, setRegimes] = useState([]);
  // ComponentDidMount
  useEffect(() => {
    axiosGetRegimes()
      .then((response) => {
        setRegimes(response?.data?.regimes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientRfc"
            label="RFC del cliente"
            type="text"
            variant="outlined"
            name="tax_id"
            disabled
            onChange={onChange}
            error={
              !validateRfc(clientEdit?.tax_id) && clientEdit?.tax_id !== ""
            }
            value={clientEdit?.tax_id}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            autoFocus
            margin="dense"
            id="clientSocialReason"
            name="legal_name"
            label="Razón Social"
            type="text"
            variant="outlined"
            disabled
            onChange={onChange}
            value={clientEdit?.legal_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          <TextField
            id="clientSelectRegime"
            select
            margin="dense"
            name="tax_system"
            label="Regimen del cliente"
            variant="outlined"
            required
            value={clientEdit?.tax_system}
            onChange={onChange}
            fullWidth
            autoComplete="off"
          >
            {regimes?.map((option) => (
              <MenuItem key={option.id} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3} xl={3}>
          <TextField
            autoFocus
            margin="dense"
            id="clientPostalCode"
            name="zip"
            label="Código Postal"
            type="postal_code"
            variant="outlined"
            onChange={onChange}
            value={clientEdit?.zip}
            fullWidth
            error={
              !validatePostalCode(clientEdit?.zip) && clientEdit?.zip !== ""
            }
          />
        </Grid>
        <Grid item xs={9} sm={7} xl={7}>
          <TextField
            autoFocus
            margin="dense"
            id="clientStreet"
            name="street"
            label="Domicilio"
            type="text"
            variant="outlined"
            required
            onChange={onChange}
            value={clientEdit?.street}
            fullWidth
          />
        </Grid>

        <Grid item xs={3} sm={2} xl={2}>
          <TextField
            autoFocus
            margin="dense"
            id="clientStreetNumber"
            name="exterior"
            label="#"
            type="text"
            variant="outlined"
            required
            onChange={onChange}
            value={clientEdit?.exterior}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientEmail"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            onChange={onChange}
            value={clientEdit?.email}
            fullWidth
            error={
              !validateEmail(clientEdit?.email) && clientEdit?.email !== ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={6}>
          <TextField
            autoFocus
            margin="dense"
            id="clientPhone"
            name="phone"
            label="Teléfono"
            type="tel"
            variant="outlined"
            required
            onChange={onChange}
            value={clientEdit?.phone}
            fullWidth
            error={
              !validatePhoneNumber(clientEdit?.phone) &&
              clientEdit?.phone !== ""
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClientEdit;
