import React from 'react'
import { Avatar, Box, Card, Divider, Grid, Typography } from '@mui/material'
import { formatUnixToDate } from '../../../utils/formats'

import Visa from '../../../img/visa.png';
import Mastercard from '../../../img/mastercard.png';

const CardActiveSubscription = ({ info }) => {
    return (
        <>
            <Card variant='outlined' sx={{ p: 2, bgcolor: 'rgba(0,0,0,.03)' }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom component="div">
                            Fecha de creación
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom component="div" fontWeight='700'>
                            {formatUnixToDate(info?.subscription?.billing_cycle_start)}
                        </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom component="div">
                            Próximo cargo
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom component="div" fontWeight='700'>
                            {formatUnixToDate(info?.subscription?.billing_cycle_end)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom component="div">
                            Estatus
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom component="div" fontWeight='700'>
                            {info?.subscription?.status === "active" && "Activa"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="subtitle1" gutterBottom component="div">
                            Tarjeta
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>

                        {Object.values(info?.payment_sources?.data)?.filter(item => item.default === true).map(payment =>
                            <Box display={'flex'} justifyContent='center' alignItems={'center'} key={payment.id}>
                                {payment.brand === "visa" ? (
                                    <Avatar alt="Tarjeta" src={Visa} />
                                ) : (
                                    <Avatar alt="Tarjeta" src={Mastercard} />
                                )}
                                <Typography variant="subtitle2" gutterBottom component="div" ml={2} fontWeight='700'>
                                    {payment.card_type + ' con terminación ' + payment.last4}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Card>

        </>
    )
}

export default CardActiveSubscription