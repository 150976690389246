import React, { useState, useEffect, useContext } from "react";
import BaseCard from "../../components/General/BaseCard";
import ValidatePending from "../../components/General/ValidatePending";
import BackdropLoader from "../../layouts/BackdropLoader";
import { axiosPending } from "../../api/axiosPending";
import { isTotallyEmpty } from "../../utils/validations";
import { toast } from "react-toastify";
import CheckListMassBillingBills from "../../components/Bills/MassBillingBills/CheckListMassBillingBills";
import { Grid, Box } from "@mui/material";
import { axiosAllClientsFacturaApi } from "../../api/axiosClients";
import ButtonsMassBillingBills from "../../components/Bills/MassBillingBills/ButtonsMassBillingBills";
import { AuthContext } from "../../auth/AuthContext";
import FormDataNewBill from "../../components/Bills/NewBill/FormDataNewBill";
import ConceptsNewBill from "../../components/Bills/NewBill/ConceptsNewBill";

const MassBillsPage = () => {
  const { user } = useContext(AuthContext);
  const [pending, setPending] = useState(false);
  const [carga, setCarga] = useState(true);
  const [clients, setClients] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [countClients, setCountClients] = useState(0);
  const [search, setSearch] = useState("");

  const [bill, setBill] = useState({
    customer_id: "",
    items: "",
    payment_method: "",
    payment_form: "",
    type: "",
    use: "",
    currency: "MXN",
    exchange: "",
    date: "",
    series: "",
    folio_number: "",
    pdf_custom_section: "",
    clients: [],
  });

  // ComponentDidMount
  useEffect(() => {
    axiosPending()
      .then((response) => {
        if (response.data.data) {
          Object.keys(response.data.data).forEach((e) => {
            if (isTotallyEmpty(response.data.data[e])) {
              setPending(true);
            }
          });
        }
        setCarga(false);
      })
      .catch((err) => {
        toast.error("Error de red", err);
        setCarga(false);
      });
  }, []);

  const triggerAxiosPaginate = (page = 1, search) => {
    setLoading(true);
    axiosAllClientsFacturaApi()
      .then((response) => {
        if (response.data.status) {
          setClients(response.data.data.data);
          setLoading(false);
          setPage(response.data.data.page);
          setTotalPages(response.data.data.total_pages);
          setCountClients(response.data.data.total_results);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error("Error en el servidor");
        }
        setLoading(false);
        return err;
      });
  };

  // ComponentDidMount
  useEffect(() => {
    triggerAxiosPaginate(page);
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <BaseCard title="FACTURACIÓN MASIVA">
          {carga ? (
            <BackdropLoader loading={carga} />
          ) : pending ? (
            <ValidatePending />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CheckListMassBillingBills
                  clients={clients}
                  bill={bill}
                  setBill={setBill}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  totalPages={totalPages}
                  loading={loading}
                  page={page}
                  search={search}
                  setSearch={setSearch}
                  countClients={countClients}
                  triggerAxiosPaginate={triggerAxiosPaginate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDataNewBill
                  bill={bill}
                  setBill={setBill}
                  masive={true}
                  activeFolio={user?.folio_check === 0 ? false : true}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ConceptsNewBill bill={bill} setBill={setBill} />
              </Grid>
            </Grid>
          )}
        </BaseCard>

        <Box
          sx={{
            position: "fixed",
            right: "15%",
            left: "20%",
            display: "flex",
            bottom: "1%",
            alignItems: "center",
          }}
        >
          <ButtonsMassBillingBills
            bill={bill}
            setBill={setBill}
            selectedClients={selectedRows}
            user={user}
          />
        </Box>
      </Grid>
    </>
  );
};

export default MassBillsPage;
