import React, { useState } from 'react'
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import "../../../utils/styles/SingInSingUp.scss";
import AutocompleteLada from '../../General/AutocompleteLada';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isEmailValid, isPhoneValid } from "../../../utils/validations";
import { signUpApi, setStorageToken } from '../../../api/auth';
import { values, size } from "lodash";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const BasicDataRegister = ({ handlepay }) => {
  const [formData, setFormData] = useState(initialFormValue());
  const [signUpLoading, setSignUpLoading] = useState(false);

  const [show, setShow] = useState({
    password: "",
    showPassword: false,
    repeatPassword: "",
    showRepeatPasword: false
  });

  //passwords
  const handleClickShowPassword = pwdRepeat => {
    if (pwdRepeat) {
      setShow({ ...show, showRepeatPasword: !show.showRepeatPasword });
    } else {
      setShow({ ...show, showPassword: !show.showPassword });
    }
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChange = prop => event => {
    setShow({ ...show, [prop]: event.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    let validCount = 0;
    values(formData).some(value => {
      value && validCount++;
      return null;
    });

    if (validCount !== size(formData)) {
      toast.warning("Completa todos los campos del formulario");
    } else {
      if (!isEmailValid(formData.email)) {
        toast.warning("Ingresa un email valido");
      } else if (!isPhoneValid(formData.phone)) {
        toast.warning("Ingresa un número telefónico valido");
      } else if (formData.password !== formData.repeatPassword) {
        toast.warning("Las contraseñas deben ser iguales");
      } else if (!formData.password.match(/[A-Z]/)) {
        toast.warning("La contraseña debe tener al menos una mayúscula");
      } else if (!formData.password.match(/\d/)) {
        toast.warning("La contraseña debe tener al menos un número");
      } else if (size(formData.password) < 8) {
        toast.warning("La contraseña debe de tener al menos 8 caracteres");
      } else {
        setSignUpLoading(true);
        signUpApi(formData)
          .then(response => {
            if (response.status === 201) {

              toast.success(response.data.message);
              let jwt = `${response.data.token_type} ${response.data.access_token}`;
              let tokenStored = setStorageToken(jwt); //function to save token
              localStorage.setItem('api_key', response.data.api_key);
              if (tokenStored === true) {
                // localStorage.setItem('firstTime', true);
                // setToken(isUserLogedApi());
                setFormData(initialFormValue());
                handlepay();
              }
            }

          }).catch(err => {

            if (err.response.status === 404 || err.response.status === 422) {
              toast.warning('Email no disponible');

            } else if (err.response.status === 420) {
              toast.warning("Error en el formato de los datos")
            } else {
              toast.warning("Error en el Servidor intenta mas tarde")
            }

          }).finally(() => {

            setSignUpLoading(false);
        });
      }
    }
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    < >

      <DialogContent sx={{ p: 2 }}>

        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ p: 1, }} onChange={onChange}>
          <Grid item xs={12} >
            <TextField
              label="Usuario/Nombre"
              placeholder='Escribe Usuario'
              variant='filled'
              fullWidth
              margin='dense'
              autoComplete='off'
              name='name'
              value={formData.name} />
          </Grid>

          <Grid item xs={12} >
            <TextField
              label="Razón Social"
              placeholder='Escribe Razón Social'
              variant='filled'
              fullWidth
              margin='dense'
              autoComplete='off'
              name='legal_name'
              value={formData.legal_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo"
              variant='filled'
              placeholder='Escribe Correo'
              fullWidth
              margin='dense'
              autoComplete='off'
              name='email'
              value={formData.email}
            />
          </Grid>
          <Grid item xs={5} >
            <AutocompleteLada formData={formData} setFormData={setFormData} />
          </Grid>
          <Grid item xs={7} >
            <TextField
              label="Teléfono (10 Dígitos)"
              variant='filled'
              placeholder='Escribe Teléfono'
              type="number"
              inputProps={{ maxLength: 10 }}
              fullWidth
              margin='dense'
              autoComplete='off'
              name='phone'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contraseña"
              variant='filled'
              placeholder='Escribe Contraseña'
              fullWidth
              margin="normal"
              autoComplete='off'
              name='password'
              type={show.showPassword ? "text" : "password"}
              helperText="La contraseña debe contar con al menos 1 Mayúscula, 1 Número y 8 Caracteres"
              value={formData.password}
              onChange={handleChange("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(false)
                      }
                      onMouseDown={handleMouseDownPassword} edge="end" >
                      {show.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirmar Contraseña"
              variant='filled'
              placeholder='Escribe Confirmar Contraseña'
              fullWidth
              margin='dense'
              autoComplete='off'
              name='repeatPassword'
              value={formData.repeatPassword}
              type={show.showRepeatPasword ? "text" : "password"}
              onChange={handleChange("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(true)
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {show.showRepeatPasword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>


      <DialogActions sx={{ mt: 2 }}>
        <Grid container justifyContent={'center'}>

          <Grid item  >
            <Button
              id="Aceptar"
              variant="contained"
              color="primary"
              type="submit"
              required
              onClick={onSubmit}
              disabled={
                formData.name === "" ||
                formData.legal_name === "" ||
                formData.email === "" ||
                formData.country_code === "" ||
                formData.phone === "" ||
                formData.password === "" ||
                formData.repeatPassword === ""

              } >
              {!signUpLoading ? (
                "Registrarme"
              ) : (
                <CircularProgress color='inherit' />
              )}
            </Button>
          
            {/* <Button onClick={() => handlepay()}>
              PayData
            </Button> */}
          </Grid>

          <Grid item xs={12} sx={{mt:2}}>
            <Typography
              component={Link}
              to="/auth/login"
              fontWeight="550"
              variant="body2"
              align='center'
              sx={{
                textDecoration: "none",
                color: "primary.light",
                textAlign: 'center',
                display: 'block'
              }}>
              Ya tengo una cuenta.
            </Typography>
          </Grid>
        </Grid>
      </DialogActions >


    </>

  )
}
export default BasicDataRegister

function initialFormValue() {
  return {
    name: "",
    legal_name: "",
    email: "",
    country_code: '52',
    phone: "",
    password: "",
    repeatPassword: ""
  };
}