import React,{useState} from 'react'
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { axiosDeleteCompanyFacturaApi } from '../../api/axiosCompany';
import { LoadingButton } from '@mui/lab';

const DeleteBusiness = ({ business, handleToggle, isOpen, triggerAxiosPaginate }) => {
  const [loading, setLoading] = useState(false);

    const deleteCompanyHandler = () => {
      setLoading(true);
        axiosDeleteCompanyFacturaApi(business.id_facturapi).then(response => {
          if (response.status === 200) {
            if (response.data.status === true) {
              toast.success(response.data.message);
              setLoading(false);
              setTimeout(() => {
                handleToggle();
                window.location.reload(true);
              }, 15);
            } else {
              toast.warning(response.data.message);
              setLoading(false);
            }
          } else {
            toast.warning(response.data.message);
            setLoading(false);
          }
        }).catch(err => {
          if (err.response.status === 401) {
            toast.error(err.response.data.message);
        }else{

            toast.error("Ocurrió un error al hacer la petición.");
        }
          setLoading(false);
        });
      }
  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`ELIMINAR A ${business.legal_name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Si la empresa seleccionada cuenta con alguna factura realizada por Facturalite no podrá eliminarse, en caso contrario
          una vez que eliminela empresa podrá agregarlo en cualquier momento. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton 
        color='error'
         loading={loading} 
         onClick={deleteCompanyHandler} 
         variant='contained' >
          Eliminar
        </LoadingButton>
       
        <Button onClick={handleToggle} autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteBusiness