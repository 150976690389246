import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Alert,
  Skeleton,
  MenuItem,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  Divider,
} from "@mui/material";
import { axiosGetRegimes } from "../../api/regimes";
import { axiosActiveFolio } from "../../api/axiosCompany";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/AuthContext";

const FormEditDataBusiness = ({ businessEdit, setBusinessEdit }) => {
  const { user, setUser } = useContext(AuthContext);
  const [regimes, setRegimes] = useState([]);
  const [activeSwitch, setActiveSwitch] = useState(
    businessEdit.folio_check === 1 ? true : false
  );
  const [folio, setFolio] = useState(businessEdit.folio_init);

  // ComponentDidMount
  useEffect(() => {
    axiosGetRegimes()
      .then((response) => {
        setRegimes(response.data.data);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const onChange = (event) => {
    setBusinessEdit({
      ...businessEdit,
      [event.target.name]: event.target.value,
    });
  };
  //Activar folio automatico
  const handleActiveFolio = () => {
    axiosActiveFolio(activeSwitch, folio, businessEdit)
      .then((response) => {
        if (response.data.status === true) {
          setUser({ ...user, folio_check: response.data.folio_check });
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Error En el servidor, intenta mas tarde");
        return err;
      });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="h6" fontWeight="600">
            Folio automatico
          </Typography>
        </Grid>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item xs={10} sm container mr={2} ml={2}>
            <FormControlLabel
              value={activeSwitch}
              control={<Switch color="info" checked={activeSwitch} />}
              onClick={() => setActiveSwitch(!activeSwitch)}
              label="Activar Folio automatico"
              labelPlacement="start"
            />
            <TextField
              margin="dense"
              name="folio"
              label="Folio Inicial"
              type="number"
              variant="filled"
              fullWidth
              value={folio}
              onChange={(event) => setFolio(event.target.value)}
              disabled={!activeSwitch}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleActiveFolio}
              disabled={activeSwitch && folio === ""}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="h6" fontWeight="600" marginBottom={"2%"}>
            Datos fiscales
          </Typography>
        </Grid>

        {businessEdit.files_csd === 1 ? (
          <>
            {businessEdit.legal_check === 0 && (
              <Grid item lg={12} md={12} xs={12}>
                <Alert severity="warning">
                  Corrobora tu información Fiscal y Edita de ser necesario para
                  la Facturación
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} sm={12} xl={12}>
              <TextField
                margin="dense"
                name="legal_name"
                label="Razón Social"
                type="text"
                variant="filled"
                placeholder="Nombre Fiscal o Razón Social, sin el régimen societario."
                required
                value={businessEdit?.legal_name}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
              <TextField
                margin="dense"
                name="tax_id"
                label="RFC"
                type="text"
                variant="filled"
                placeholder="Emisor que quedará registrado en los procesos de facturación."
                required
                value={businessEdit?.tax_id}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Nombre Comercial"
                type="text"
                variant="filled"
                name="name"
                placeholder="Nombre comercial de la organización."
                value={businessEdit?.name}
                onChange={onChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <TextField
                select
                fullWidth
                required
                id="SelectRegime"
                name="tax_system"
                variant="filled"
                margin="dense"
                label="Regimen de la Empresa"
                value={businessEdit.tax_system}
                onChange={onChange}
              >
                {regimes?.map((option) => (
                  <MenuItem key={option.id} value={option.code}>
                    {option.code} - {option.regimen}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                variant="filled"
                type="text"
                label="Código postal"
                placeholder="Código postal"
                name="zip"
                value={businessEdit.zip}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <TextField
                name="street"
                margin="dense"
                variant="filled"
                type={"text"}
                label="Calle"
                placeholder="Nombre de la calle"
                fullWidth
                required
                value={businessEdit.street}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                variant="filled"
                type="text"
                label="exterior"
                placeholder="Numero exterior"
                name="exterior"
                value={businessEdit.exterior}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                variant="filled"
                type="text"
                label="Sitio Web"
                placeholder="Sitio web de la organización, que aparecerá en el PDF y correos de facturas."
                name="website"
                value={businessEdit.website}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                variant="filled"
                type="text"
                label="Correo de Soporte"
                placeholder="Dirección de correo electrónico para aclaraciones. "
                name="support_email"
                value={businessEdit.support_email}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <TextField
                fullWidth
                required
                margin="dense"
                variant="filled"
                type="text"
                label="Teléfono e la organización "
                placeholder="Teléfono de la organización"
                name="phone"
                value={businessEdit.phone}
                onChange={onChange}
              />
            </Grid>
          </>
        ) : (
          <Grid container spacing={0} justifyContent={"center"}>
            <Alert variant="outlined" severity="warning">
              <Typography variant="h5" gutterBottom component="div">
                Para poder editar o visualizar tu información Fiscal es
                necesario completar la carga de CSD
              </Typography>
            </Alert>

            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" height={40} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" height={30} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" height={30} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" height={40} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" height={40} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" height={40} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" height={30} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Skeleton animation="wave" height={30} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FormEditDataBusiness;
