import React, { useState } from "react";
import { Card, Grid, Skeleton } from "@mui/material";
import CardPaymentConfig from "./CardPaymentConfig";
import {
  axiosDeletePaymentData,
  axiosUpdateDefaultCard,
} from "../../../api/axiosConekta";
import { toast } from "react-toastify";
const CardListPaymentConfig = ({ handleGetCard, payments, loading }) => {
  // Delete User Card
  const handleDeleteCard = (index) => {
    axiosDeletePaymentData(index)
      .then((response) => {
        toast.success("Tarjeta eliminada con Exito", response);
        if (response.status === 200) {
          if (response.data.status === true) {
            toast.success(response.data.message);

            handleGetCard();
          } else {
            toast.warning(response.data.message);
          }
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Error al eliminar Tarjeta ", err.message_to_purchaser);
      });
  };
  // Assign Card Default
  const handleUpdateCardDefault = (paymentId) => {
    axiosUpdateDefaultCard(paymentId)
      .then((response) => {
        toast.success("Tarjeta seleccionada como principal", response);
        handleGetCard();
      })
      .catch((err) => {
        toast.error("Error al seleccionar como principal", err);
      });
  };

  return (
    <>
      <Card variant="outlined" sx={{ p: 3 }}>
        <Grid
          container
          columns={{ xs: 4, sm: 12, md: 12 }}
          sx={{ m: { xs: 0, sm: 2, lg: 2 } }}
        >
          {loading
            ? Array.from(Array(4)).map((_, index) => (
                <Grid item xs={12} sm={3} md={3} sx={{ p: 2 }} key={index}>
                  <Skeleton
                    variant="rectangular"
                    width={230}
                    height={110}
                    sx={{
                      bgcolor: "grey.400",
                      borderRadius: "10px",
                      p: 2,
                    }}
                  />
                </Grid>
              ))
            : payments.map((payment, index) => (
                <Grid item xs={12} sm={3} md={3} key={index} sx={{ p: 2 }}>
                  <CardPaymentConfig
                    payment={payment}
                    index={index}
                    handleUpdateCardDefault={handleUpdateCardDefault}
                    handleDeleteCard={handleDeleteCard}
                  />
                </Grid>
              ))}
        </Grid>
      </Card>
    </>
  );
};

export default CardListPaymentConfig;
