import React from 'react'
import {
  Grid,
} from '@mui/material'
import Cards from '../../components/Dashboard/Cards';
import PendingsDashboard from '../../components/Dashboard/PendingsDashboard';

const DashboardPage = () => {

  return (

    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Cards />
      </Grid>
      <Grid item xs={12} lg={12}>
        <PendingsDashboard />
      </Grid>
    </Grid>

  )
}

export default DashboardPage