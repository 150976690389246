import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { axiosDeleteBillsFacturaApi } from '../../api/axiosBills';
import { UUIDCustom } from '../../utils/formats';

const CancelBills = ({ triggerAxiosPaginate, billInfo, handlerBillCancelModal, openDeleteModal }) => {

    const [info, setInfo] = useState({
        idInvoice: billInfo.id,
        motive: '',
        substitution: ''
    });

    const handleMotive = ({ target }) => {
        setInfo({ ...info, motive: target.value });
    }

    const handleSustitution = ({ target }) => {
        setInfo({ ...info, substitution: target.value })
    }
    const deleteBillHandler = () => {
        if (info.motive === '01' && info.substitution === '') {
            toast.warning('Debes indicar la factura relacionada suplente.');
        } else {
            axiosDeleteBillsFacturaApi(info).then(response => {
                if (response.data.status === true) {
                   
                    triggerAxiosPaginate();

                    // retroalimentación
                    toast.success("Factura Cancelada exitosamente.");

                    // cerrar dialog
                    handlerBillCancelModal();

                } else {

                    toast.warning(response.data.message);

                }

            }).catch(err => {
                toast.error("Ocurrió un error al hacer la petición.");
            });
        }

    }

    //Limpieza de useState    
    useEffect(() => {
        setInfo({
            idInvoice: billInfo.id,
            motive: '',
            substitution: ''
        });
    }, [openDeleteModal === false])

    return (
        <Dialog
            open={openDeleteModal}
            onClose={handlerBillCancelModal}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            maxWidth={'sm'}
            fullWidth >
            <DialogTitle id="alert-dialog-title" sx={{ m: 2 }}>
            <Typography component={'span'} variant={'h5'}>
                  Estas por cancelar la Factura {billInfo?.folio_number} - 
                {billInfo?.series} realizada a {billInfo?.customer?.legal_name} por {billInfo?.total}`
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ mr: 4, ml: 4 }}>

                <DialogContentText id="alert-dialog-description">
                    <FormControl
                        variant='filled'
                        fullWidth
                        required
                        margin="dense"
                        size="small">

                        <InputLabel id="motive-cancellation">
                            ¿Cuál es el motivo?
                        </InputLabel>

                        <Select
                            labelId="motive-cancellation-label"
                            id="motive-cancellation"
                            value={info?.motive}
                            onChange={handleMotive}>
                            <MenuItem value={'01'}>
                                01: Comprobante emitido con errores con relación.
                            </MenuItem>
                            <MenuItem value={'02'}>
                                02: Comprobante emitido con errores sin relación.
                            </MenuItem>
                            <MenuItem value={'03'}>
                                03: No se llevó a cabo la operación.
                            </MenuItem>
                            <MenuItem value={'04'}>
                                04: Operación nominativa relacionada en la factura global.
                            </MenuItem>
                        </Select>

                    </FormControl>
                    <br />

                    {info.motive === '01' &&
                        <TextField
                            margin='normal'
                            size='small' fullWidth
                            label="Sustitucion/relación de factura"
                            placeholder='Folio fiscal (UUID).'
                            helperText='Indica el folio fiscal (UUID) que la reemplazará.'
                            variant='filled'
                            value={info.substitution}
                            InputProps={{
                                inputComponent: UUIDCustom
                            }}
                            onChange={handleSustitution} />}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='error' variant='contained' disabled={!info.motive || ( info.motive == '01' && !info.substitution) }
                    onClick={deleteBillHandler} >
                    Cancelar
                </Button>
                <Button onClick={handlerBillCancelModal} >
                    Volver
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelBills