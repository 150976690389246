import React from 'react'
import {
    Avatar,
    ListItem,
    Radio,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
} from '@mui/material';
import Oxxo from '../../../img/oxxo.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    avatar: {
      height: 20,
    },
  });

const SelectOxxoPayments = ({ handleChange,selectedPayment}) => {
    
    const classes = useStyles();

    return (
        <>
            <ListItem key={'select-oxxo'}>
                <ListItemAvatar>
                    <Avatar alt="Tarjeta" src={Oxxo} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText primary={"Oxxo"} />
                <ListItemSecondaryAction>
                    <Radio
                        checked={selectedPayment === "oxxo"}
                        onChange={handleChange}
                        value={"oxxo"}
                        name="radio-button-type-pay"
                        inputProps={{ 'aria-label': 'O' }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </>
    )
}

export default SelectOxxoPayments