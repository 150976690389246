import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, AppBar } from "@mui/material";

import { Phone, PersonPin, Favorite, Help } from "@mui/icons-material";
import UserAccountConfig from "./UserAccountConfig";
import FiscalConfig from "./FiscalConfig";
import PaymentConfig from "./PaymentConfig";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsConfig = () => {
  const location = useLocation();
  const tab = location?.state?.tab !== undefined ? location?.state?.tab : 1;

  const [value, setValue] = React.useState(tab !== 1 ? location.state.tab : 1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          scrollButtons={true}
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Mi cuenta" icon={<Phone />} {...a11yProps(0)} />
          <Tab label="Fiscal" icon={<PersonPin />} {...a11yProps(1)} />
          <Tab label="Metodos de pago" icon={<Favorite />} {...a11yProps(2)} />
          <Tab label="Manuales" icon={<Help />} {...a11yProps(3)} disabled />
        </Tabs>

        <TabPanel value={value} index={0}>
          <UserAccountConfig />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FiscalConfig />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PaymentConfig />
        </TabPanel>
      </AppBar>
    </>
  );
};

export default TabsConfig;
