import React from 'react'
import { useNavigate } from 'react-router-dom';
import {
  Button,
} from '@mui/material';

import {
  makeStyles
} from '@mui/styles';


const useStyles = makeStyles({
  floatRight: {
    float: 'right'
  }
});

const NewComplementBill = ({ btnText, originPosition }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/new-complement-bill');
  }

  return (
    <>
      <Button variant="contained"
        color='info'
        // disabled
        onClick={handleRedirect}
        className={originPosition && classes.floatRight}>
        {btnText}</Button>

    </>
  )
}

export default NewComplementBill