import React from "react";
import {
  DialogActions,
  DialogContent,
  Box,
  Stack,
  Paper,
  CardMedia,
  CardActionArea,
  Typography,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import logoCard from "../../../img/visa-mastercard.png";
import logoOxxo from "../../../img/oxxo.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SelectPayRegister = ({
  handleClickPayCard,
  handleClickPayOxxo,
  handlepay,
}) => {
  return (
    <>
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={3} m="16px">
            <Item>
              <CardActionArea
                sx={{ display: "flex" }}
                onClick={() => handleClickPayCard()}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component="div" variant="h6">
                      Pago con Tarjeta
                    </Typography>
                  </CardContent>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width: 100 }}
                  image={logoCard}
                  alt="Tarjeta"
                />
              </CardActionArea>
            </Item>
            <Item>
              <CardActionArea
                sx={{ display: "flex" }}
                onClick={() => handleClickPayOxxo()}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component="div" variant="h6">
                      Pago con Oxxo
                    </Typography>
                  </CardContent>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width: 100 }}
                  image={logoOxxo}
                  alt="Oxxo"
                />
              </CardActionArea>
            </Item>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        {/* <Grid item xs={12} sm={12} md={12} xl={12}>
          <Button onClick={() => handlepay()}>
            BasicData
          </Button>
        </Grid> */}
      </DialogActions>
    </>
  );
};

export default SelectPayRegister;
