import { useState, useEffect } from 'react';

import { axiosGetProdServCodes } from '../api/prodServCodes';


export const useFetchProdServCodes = (search) => {

    const [state, setState] = useState({
        data: [],
    });

    useEffect(() => {

        axiosGetProdServCodes(search).then(response => {
            setState({ data: response.data.data });
        });

    }, [search]);

    return state;

}

