import React, { useState } from "react";
import {
  bimesterInit,
  getBimesterByNumber,
  getMonthByNumber,
} from "../../../utils/formats";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

const FormGlobalDataNewBill = ({ activeSwitch, bill, setBill }) => {
  const [dataGlobal, setDataGlobal] = useState({
    periodicity: "",
    months: "",
    year: new Date().getFullYear(),
  });
  const [months, setMonths] = useState([]);

  const handlePeriodicity = ({ target }) => {
    setDataGlobal({
      ...dataGlobal,
      periodicity: target.value,
      months: "",
    });

    if (target.value === "two_months") {
      setMonths([
        {
          val: "13",
          text: "Enero-Febrero",
        },
        {
          val: "14",
          text: "Marzo-Abril",
        },
        {
          val: "15",
          text: "Mayo-Junio",
        },
        {
          val: "16",
          text: "Julio-Agosto",
        },
        {
          val: "17",
          text: "Septiembre-Octubre",
        },
        {
          val: "18",
          text: "Noviembre-Diciembre",
        },
      ]);
    } else {
      setMonths([
        {
          val: "01",
          text: "Enero",
        },
        {
          val: "02",
          text: "Febrero",
        },
        {
          val: "03",
          text: "Marzo",
        },
        {
          val: "04",
          text: "Abril",
        },
        {
          val: "05",
          text: "Mayo",
        },
        {
          val: "06",
          text: "Junio",
        },
        {
          val: "07",
          text: "Julio",
        },
        {
          val: "08",
          text: "Agosto",
        },
        {
          val: "09",
          text: "Septiembre",
        },
        {
          val: "10",
          text: "Octubre",
        },
        {
          val: "11",
          text: "Noviembre",
        },
        {
          val: "12",
          text: "Diciembre",
        },
      ]);
    }
  };
  const handleMonth = ({ target }) => {
    setDataGlobal({
      ...dataGlobal,
      months: target.value,
    });
  };
  useEffect(() => {
    setBill({
      ...bill,
      global: dataGlobal,
      use: "S01",
      type: "I",
      customer_id: {
        id: "123abc",
        legal_name: "PUBLICO EN GENERAL",
        tax_id: "XAXX010101000",
        tax_system: "123",
      },
    });
  }, [dataGlobal]);

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="600"
        marginBottom={"1%"}
        marginTop={"2%"}
      >
        Información para factura global
      </Typography>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl
            variant="filled"
            fullWidth
            required={activeSwitch == true ? true : false}
            margin="dense"
          >
            <InputLabel id="bill-type-label">
              Periodicidad factura global
            </InputLabel>
            <Select
              labelId="bill-type-label"
              id="bill-global-periodicity"
              value={dataGlobal.periodicity}
              onChange={handlePeriodicity}
              label="Periodicidad factura global"
            >
              <MenuItem value={"day"}>Diario</MenuItem>
              <MenuItem value={"week"}>Semanal</MenuItem>
              <MenuItem value={"fortnight"}>Quincenal</MenuItem>
              <MenuItem value={"month"}>Mensual</MenuItem>
              <MenuItem value={"two_months"}>Bimestral</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl
            variant="filled"
            fullWidth
            required={activeSwitch == true ? true : false}
            margin="dense"
          >
            <InputLabel id="bill-type-label">
              Mes/Bimestre factura global
            </InputLabel>
            <Select
              labelId="bill-global-month-label"
              id="bill-global-month"
              value={dataGlobal.months}
              onChange={handleMonth}
              label="Mes/Bimestre factura global"
            >
              {months.map((m) => {
                return <MenuItem value={m.val}>{m.text}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            autoFocus
            margin="dense"
            id="bill-global-year"
            name="global_year"
            label="Año"
            type="text"
            variant="filled"
            disabled
            value={dataGlobal.year}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormGlobalDataNewBill;
