import React from 'react'
import {
  Grid,
} from '@mui/material'

import BaseCard from '../../components/General/BaseCard';
import TabsConfig from '../../components/Config/TabsConfig';

const ConfigPage = () => {
  return (
    <Grid container spacing={0}>
      <BaseCard title="CONFIGURACIÓN">
        <Grid container>
          <Grid item xs={12} lg={12}>
            <TabsConfig />
          </Grid>
        </Grid>
      </BaseCard>
    </Grid>

  )
}

export default ConfigPage