import React from 'react';
import {
    Card,
    Button,
    Grid,
    Typography
} from '@mui/material';

import { Link } from "react-router-dom";

const ValidatePending = () => {

    return (
     
        <Grid container justifyContent="center" alignItems="center">
              
            <Card variant="outlined" 
            sx={{
                p: 4,
                textAlign: "center",
                width: "100%",
                mt: '5vh'
              }}>

                <Typography variant='h3' sx={{p:2}}>Completa tu información para poder empezar</Typography>
                <Button variant="contained" component={Link}
                    to="/dashboard"  sx={{ m:4}}>
                    Ver Pendientes
                </Button>
                        
            </Card>
                        
        </Grid>

    )
}

export default ValidatePending
