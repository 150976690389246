import React, { useState } from "react";
import { Button, Grid, Typography, Card, Box } from "@mui/material";
import { useNavigate } from "react-router";
import { Cancel, Check } from "@mui/icons-material";
import DialogConfirmMassSendBills from "./DialogConfirmMassSendBills";

const ButtonsMassSendBills = ({ sendBills, sendEmails, isClient }) => {
  const navigate = useNavigate();
  const [openConfirmMassModal, setOpenConfirmMassModal] = useState(false);
  //Cancelar Factura Masiva
  const handleCancel = () => {
    navigate("/bills");
  };

  const validatebillData = () => {
    let isNotNull = true;

    if (sendBills.length <= 0 || (isClient === "0" && sendEmails.length <= 0)) {
      isNotNull = false;
    }
    return isNotNull;
  };

  //open confirm fatura masiva
  const handlerConfirmMassModal = () => {
    setOpenConfirmMassModal(!openConfirmMassModal);
  };
  return (
    <>
      <Grid container justifyContent="center">
        <Card variant="outlined" sx={{ p: 0 }}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid
                item
                sm={6}
                lg={6}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  display="flex"
                  alignitems="center"
                  variant="contained"
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="error"
                  onClick={handleCancel}
                >
                  <Typography display="flex" alignItems="center">
                    <Cancel />
                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Cancelar
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sm={6}
                lg={6}
                xs={12}
                // display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  display="flex"
                  alignitems="center"
                  variant="contained"
                  onClick={() => {
                    handlerConfirmMassModal();
                  }}
                  disabled={!validatebillData()}
                  sx={{ mt: { xs: 1, sm: 0, lg: 0 } }}
                  color="success"
                >
                  <Typography display="flex" alignItems="center">
                    <Check />

                    <Typography
                      component="span"
                      fontWeight="500"
                      sx={{
                        color: (theme) =>
                          `${
                            theme.palette.mode === "dark"
                              ? theme.palette.grey.A200
                              : "#fffff"
                          }`,
                        ml: 1,
                      }}
                    >
                      Verificar
                    </Typography>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>

        <DialogConfirmMassSendBills
          handlerConfirmMassModal={handlerConfirmMassModal}
          openConfirmMassModal={openConfirmMassModal}
          sendBills={sendBills}
          sendEmails={sendEmails}
          handleCancel={handleCancel}
          isClient={isClient}
        />
      </Grid>
    </>
  );
};

export default ButtonsMassSendBills;
