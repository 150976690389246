import React, { useEffect } from 'react'
import { FormControlLabel, Switch } from '@mui/material'

const SwitchGlobalNewBill = ({ activeSwitch, setActiveSwitch, bill, setBill }) => {

    useEffect(() => {
        if (activeSwitch === true) {
            setBill({ ...bill, global: [],  })
        } else {

            if (bill['global']) {

                setBill(prevbill => {
                    delete prevbill['global'];
                    
                    return prevbill;
                });
                setBill({ ...bill, 
                use: '',
                type: '',
                customer_id: ''});
            }
        }
    }, [activeSwitch])

    return (
        <>
            <FormControlLabel
                value={activeSwitch}
                control={<Switch color='warning' />}
                onClick={() => setActiveSwitch(!activeSwitch)}
                label="Generar Factura General"
                labelPlacement="start"
            />

        </>
    )
}

export default SwitchGlobalNewBill