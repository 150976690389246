import React, { useState, useEffect } from 'react'
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Chip,
} from '@mui/material';

import relationship from '../../utils/relationship_bills.json';
import { validateUuid } from '../../utils/validations';
import { UUIDCustom } from '../../utils/formats';
import SwitchReletedDocuments from './SwitchReletedDocuments';
import { AddBox } from '@mui/icons-material';

const RelatedBillNewBill = ({ setComplementBill, complementBill }) => {
  const [relatedBillData, setRelatedBillData] = useState({
    relationship: '',
    documents: [],
  });
  const [documents, setDocuments] = useState([]);
  const [value, setValue] = useState('');
  const [activeSwitch, setActiveSwitch] = useState(false);

  useEffect(() => {
    setRelatedBillData({ ...relatedBillData, documents: documents });
  }, [documents]);

  useEffect(() => {
    if(activeSwitch === true){
     
      if(relatedBillData.documents.length >= 1) {
        setComplementBill({...complementBill,  related_documents: relatedBillData ,
          relation: true});
      }else{
        setComplementBill({...complementBill, relation: false});
      }
    }
  }, [relatedBillData])

  const handleChangeRetionship = ({ target }) => {
    setRelatedBillData({ ...relatedBillData, relationship: target.value });
  };

  const handleAddUuid = () => {
    let inputValue = document.getElementById('add-uuid').value;
    if (validateUuid(inputValue)) {
      setDocuments(prev => [...prev, inputValue]);
      document.getElementById('add-uuid').value = '';
    } else {
      document.getElementById('add-uuid').focus();
    }
  };

  const handleDeleteChip = (element) => {
    const newArr = documents.filter(e => e !== element);
    setDocuments(newArr);
  };

  const handleChangeDocuments = ({ target }) => {
    setValue(target.value);
  };

  return (
    <>
      <SwitchReletedDocuments
        activeSwitch={activeSwitch}
        setActiveSwitch={setActiveSwitch}
        bill={complementBill}
        setBill={setComplementBill}
        setRelatedBillData={setRelatedBillData}
        setValue={setValue}
        setDocuments={setDocuments}
      />
      {activeSwitch &&
        <>
          <Typography variant="h6" fontWeight="600" marginBottom={'1%'} marginTop={'2%'}>
            Información de Relación de Facturas
          </Typography>
          <Divider style={{ marginBottom: "20px" }} />
          <Grid container spacing={1}>

            <Grid item xs={12} sm={4} xl={4}>
              <FormControl
                fullWidth
                variant="filled"
                required
                margin="dense">
                <InputLabel id="relationship-label">Clave de relación</InputLabel>
                <Select
                  name="relationship"
                  label="Clave de relación"
                  type="text"
                  placeholder='Clave de relación del catálogo del SAT'
                  onChange={handleChangeRetionship}
                  value={relatedBillData.relationship}>
                  {relationship.map(related => (
                    <MenuItem key={related.id} value={related.code}>
                      {`${related.code} - ${related.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={7} xl={7}>
              <TextField
                margin="dense"
                label="Folio fiscal (UUID)"
                type="text"
                id="add-uuid"
                placeholder='Folio fiscal (UUID) de factura relacionada.'
                name='value'
                variant="filled"
                required
                fullWidth
                size='small'
                value={value}
                onChange={handleChangeDocuments}
                error={!validateUuid(value) && value !== ''}
                InputProps={{
                  inputComponent: UUIDCustom
                }}
              />
              <Grid container spacing={1}>
                {documents.map((e, index) => (
                  <Grid item key={index}>
                    <Chip key={index} label={e} color={'primary'} onDelete={() => handleDeleteChip(e)} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item sm={1} xl={1}>
              <IconButton color="primary" aria-label="add to uuid" onClick={handleAddUuid} >
                <AddBox />
              </IconButton>

            </Grid>

          </Grid>
        </>
      }
    </>
  )
}

export default RelatedBillNewBill