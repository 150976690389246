import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//Listar facturas
export const axiosGetBillsFacturaApi = async (page = 1, search = "") => {
  const key = localStorage.getItem("api_key");
  let url = `${API_HOST}/api/bills/show/${page}`;
  search.length === 0
    ? (url = `${API_HOST}/api/bills/show/${page}`)
    : (url = `${API_HOST}/api/bills/show/${page}/${search}`);

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};

//ver factura por id
export const axiosShowIdBillsFacturaApi = async (billId) => {
  const url = `${API_HOST}/api/bills/show-id`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    invoice_id: billId,
    key: key,
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};

//Cancelar factura
export const axiosDeleteBillsFacturaApi = async (bill) => {
  const key = localStorage.getItem("api_key");

  let url = `${API_HOST}/api/bills/cancelInvoice`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
    idInvoice: bill.idInvoice,
    motive: bill.motive,
    substitution: bill.substitution,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
//Crear factura
export const axiosStoreBillFacturaApi = async (bill) => {
  let url = `${API_HOST}/api/bills/createInvoiceClient`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
    bill: bill,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Ver PDF de factura
export const axiosViewPDF = async (billId) => {
  let url = `${API_HOST}/api/bills/downloadInvoice/${billId}`;
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };
  const headersConfig = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Enviar factura a correo registrado del cliente
export const axiosSendBillFacturaApi = async (billId) => {
  let url = `${API_HOST}/api/bills/sendInvoiceClient/${billId}`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Enviar factura a multiples correos
export const axiosSendBillMultiple = async (billId, toSend) => {
  const url = `${API_HOST}/api/bills/sendBillMultipleEmails`;
  const key = localStorage.getItem("api_key");
  let data = new FormData();

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  data.append("idInvoice", billId);
  data.append("emails", JSON.stringify(toSend));
  data.append("key", key);

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Consultar factura por Rango Reporte
export const axiosGetBillData = async (bill) => {
  const url = `${API_HOST}/api/bills/getBillsRangeReport`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data = {
    bill: bill,
    key: key,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

//Consultar facturas por Rango
export const getBillsMasive = async (page = 1, search = "") => {
  const key = localStorage.getItem("api_key");
  let url = `${API_HOST}/api/bills/getBillsMasive/${page}`;
  search.length === 0
    ? (url = `${API_HOST}/api/bills/getBillsMasive/${page}`)
    : (url = `${API_HOST}/api/bills/getBillsMasive/${page}/${search}`);

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data = {
    key: key,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
//Consultar acuse de cancelacion de  factura
export const axiosCancellationReceiptXml = async (billId) => {
  const url = `${API_HOST}/api/bills/getCancellationReceiptXml/${billId}`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data = {
    key: key,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

export const axiosCancellationReceiptPdf = async (billId) => {
  const url = `${API_HOST}/api/bills/getCancellationReceiptPdf/${billId}`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data = {
    key: key,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

//Crear factura en ambiente test y mostrar pdf
export const axiosCreatePreviousInvoice = async (
  bill,
  test_api_key,
  key_live
) => {
  let url = `${API_HOST}/api/bills/createPreviousInvoice`;
  const key = test_api_key;

  const headersConfig = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data = {
    key: key,
    bill: bill,
    key_live: key_live,
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
