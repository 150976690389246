import React, { useState, useContext, useEffect } from 'react'
import {
  Alert,
  AlertTitle,
  Grid,
} from '@mui/material';
import CardDataFiscalConfig from './FiscalConfig/CardDataFiscalConfig';
import CardCSDDataConfig from './FiscalConfig/CardCSDDataConfig';
import { AuthContext } from '../../auth/AuthContext';

const FiscalConfig = () => {
  const { user, setUser } = useContext(AuthContext);
  const [mutableFiscalData, setMutableFiscalData] = useState({});

  
  const getFiscalUser = () => {
    setMutableFiscalData(
      {
        social_reason: user?.social_reason,
        password_csd: user?.password_csd,
        has_cer_file: user?.has_cer_file,
        has_key_file: user?.has_key_file,
        expire_csd: user?.expire_csd,
        tax_id: '',
        legal_name: '',
        tax_system: '',
        cer_file_data: '',
        key_file_data: '',
        zip: '',
        street: '',
        exterior: '',
        support_email: '',
        phone: '',
        website: '',
      }
    )
  };

  useEffect(() => {
    getFiscalUser();
  }, [])

  //actualizar despues de cambios
  useEffect(() => {
    getFiscalUser();
  }, [user])

  const [show, setShow] = useState({
    stamp_pass: "",
    showStamp_pass: false
  });

  const handleChange = prop => event => {
    setMutableFiscalData({ ...mutableFiscalData, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item lg={12} md={12} xs={12}>
          <Alert severity='info' sx={{ m: 2 }}>
            <AlertTitle>  Información de tu empresa principal  </AlertTitle>
            Aqui puedes visualizar e editar la toda la información fiscal de tu empresa principal con la cual iniciaste tu registro.
            para modificar información de una empresa secundaria podras realizarlo desde el apartado de empresas </Alert>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>

          <CardCSDDataConfig
            mutableFiscalData={mutableFiscalData}
            setMutableFiscalData={setMutableFiscalData}
            handleChange={handleChange}
            show={show}
            setShow={setShow}
            user={user}
            setUser={setUser}
          />
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <CardDataFiscalConfig
            mutableFiscalData={mutableFiscalData}
            setMutableFiscalData={setMutableFiscalData}
            handleChange={handleChange} />
        </Grid>

      </Grid>
    </>
  )
}

export default FiscalConfig