import React from 'react'
import {
    Card,
    Grid,
    Typography,
    Button,
    TextField,
} from '@mui/material';
import AutocompleteLada from '../../General/AutocompleteLada';

const CardContactDataConfig = ({ mutableUserData, setMutableUserData }) => {

    const onChange = e => {
        setMutableUserData({ ...mutableUserData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Card variant="outlined" sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight="600" sx={{ mb: 3 }}>
                    Datos de contacto
                </Typography>

                <Grid container spacing={2} onChange={onChange}>

                    <Grid item xs={12} sm={12} md={12} xl={12} >
                        <TextField
                            margin="dense"
                            id="user-name"
                            name="name"
                            label="Nombre completo"
                            type="text"
                            placeholder='Escribe Nombre'
                            variant="filled"
                            fullWidth
                            required
                            onChange={onChange}
                            value={mutableUserData?.name}
                            helperText='Nombre de usuario para identificar tu cuenta.'
                            inputProps={{ "aria-label": "Nombre completo" }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <AutocompleteLada formData={mutableUserData}
                            setFormData={setMutableUserData} d={true} />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} xl={8}>
                        <TextField
                            margin="dense"
                            id="user-phone"
                            name="phone"
                            type="text"
                            required
                            placeholder='Escribe Teléfono'
                            variant="filled"
                            fullWidth
                            label="Teléfono"
                            value={mutableUserData.phone}
                            helperText='Numero telefonico sin Lada.'
                            inputProps={{ "aria-label": "Teléfono" }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <TextField
                            margin="dense"
                            id="user-email"
                            name="email"
                            labe="Correo electrónico"
                            type="text"
                            placeholder='Escribe Correo'
                            variant="filled"
                            fullWidth
                            label="Correo electrónico"
                            value={mutableUserData.email}
                            helperText="Recuerda usar un correo válido de preferencia Gmail, Outlook o afín."
                            inputProps={{ "aria-label": "Correo electrónico" }}
                        />
                    </Grid>
                   

                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginTop: "30px" }}
                        fullWidth
                    // onClick={handlerTogglePassword}
                    >
                        Actualizar
                    </Button>
                </Grid>

            </Card>
        </>
    )
}

export default CardContactDataConfig