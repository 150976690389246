import React from 'react'
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { axiosDeleteClientFacturaApi } from '../../api/axiosClients';

const DeleteClients = ({ client, handleToggle, isOpen, triggerAxiosPaginate }) => {
  const deleteClientHandler = () => {

    axiosDeleteClientFacturaApi(client.id).then(response => {
      if (response.status === 200) {
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            handleToggle();
            triggerAxiosPaginate(1);
          }, 15);
        } else {
          toast.warning(response.data.message);
          handleToggle();
        }
      } else {
        toast.warning(response.data.message);
        handleToggle();
      }
    }).catch(err => {
      toast.error("Ocurrió un error al hacer la petición.");
    });
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`ELIMINAR A ${client.legal_name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Si el cliente seleccionado cuenta con alguna factura realizada por Facturalite no podrá eliminarse, en caso contrario
          una vez que elimine al cliente podrá agregarlo en cualquier momento. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={deleteClientHandler} variant='contained' >
          Eliminar
        </Button>
        <Button onClick={handleToggle} autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteClients