import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";

import BaseCard from "../../components/General/BaseCard";

import SearchBills from "../../components/Bills/SearchBills";
import NewBills from "../../components/Bills/NewBills";
import TableBills from "../../components/Bills/TableBills";
import { toast } from "react-toastify";
import { axiosGetBillsFacturaApi } from "../../api/axiosBills";
import NewComplementBill from "../../components/Bills/NewComplementBill";
import DialogMassBillingBills from "../../components/Bills/MassBillingBills";
import DialogMassSendBills from "../../components/Bills/MassSendBills";

const BillsPage = () => {
  const [loading, setLoading] = useState(true);
  const [bills, setBills] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const triggerAxiosPaginate = (page = 1, search) => {
    setLoading(true);
    // api
    axiosGetBillsFacturaApi(page, search)
      .then((response) => {
        let {
          data: { data: data },
        } = response;
        if (response.data.status) {
          setLoading(false);
          setPage(data.page);
          setTotalPages(data.total_pages);
          setBills(data.data);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error("Error en el servidor");
        }
        setLoading(false);
        return err;
      });
  };

  return (
    <Grid container>
      <BaseCard title="FACTURAS">
        <Grid container justifyContent={"flex-end"}>
          <Grid item pr={1}>
            <DialogMassBillingBills
              originPosition={true}
              btnText={"Facturación masiva"}
            />
          </Grid>
          <Grid item>
            <DialogMassSendBills
              originPosition={true}
              btnText={"Envio masivo"}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2 }} />
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Grid item xs={12} lg={4}>
            <SearchBills
              triggerAxiosPaginate={triggerAxiosPaginate}
              search={search}
              setSearch={setSearch}
            />
          </Grid>

          <Grid item xs={12} lg={5} container justifyContent={"flex-end"}>
            <Grid item pr={1}>
              <NewComplementBill
                originPosition={true}
                btnText={"Crear Complemento de Factura"}
                triggerAxiosPaginate={triggerAxiosPaginate}
              />
            </Grid>
            <Grid item>
              <NewBills
                originPosition={true}
                btnText={"Crear Factura"}
                triggerAxiosPaginate={triggerAxiosPaginate}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={12}>
            <TableBills
              loading={loading}
              bills={bills}
              totalPages={totalPages}
              page={page}
              search={search}
              triggerAxiosPaginate={triggerAxiosPaginate}
            />
          </Grid>
        </Grid>
      </BaseCard>
    </Grid>
  );
};

export default BillsPage;
