import React, { useState, useEffect, useContext } from "react";
import { Grid, Box } from "@mui/material";

import BaseCard from "../../components/General/BaseCard";
import FormDataNewBill from "../../components/Bills/NewBill/FormDataNewBill";
import ConceptsNewBill from "../../components/Bills/NewBill/ConceptsNewBill";
import ButtonsNewBill from "../../components/Bills/NewBill/ButtonsNewBill";
import ListReceptorDataBill from "../../components/Bills/NewBill/ListReceptorDataBill";
import ValidatePending from "../../components/General/ValidatePending";
import { axiosPending } from "../../api/axiosPending";
import { isTotallyEmpty } from "../../utils/validations";
import { toast } from "react-toastify";
import BackdropLoader from "../../layouts/BackdropLoader";
import SwitchGlobalNewBill from "../../components/Bills/NewBill/SwitchGlobalNewBill";
import FormGlobalDataNewBill from "../../components/Bills/NewBill/FormGlobalDataNewBill";
import { AuthContext } from "../../auth/AuthContext";
import RelatedBillNewBill from "../../components/Bills/RelatedBillNewBill";

const NewBillsPage = () => {
  const { user } = useContext(AuthContext);
  const [bill, setBill] = useState({
    customer_id: "",
    items: "",
    payment_method: "",
    payment_form: "",
    type: "",
    use: "",
    currency: "MXN",
    exchange: "",
    related_documents: "",
    date: "",
    series: "",
    folio_number: "",
    relation_type: "",
    relational_bill_id: "",
    regime: "",
    emitter_regime: "",
    pdf_custom_section: "",
    relation: true,
  });

  const [activeSuccess, setActiveSuccess] = useState(true);
  const [pending, setPending] = useState(false);
  const [carga, setCarga] = useState(true);
  const [activeSwitch, setActiveSwitch] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    axiosPending()
      .then((response) => {
        let {
          data: { data: data },
        } = response;
        if (data) {
          Object.keys(data).forEach((e) => {
            if (isTotallyEmpty(data[e])) {
              setPending(true);
            }
          });
        }
        setCarga(false);
      })
      .catch((err) => {
        toast.error("Error de red", err);
        setCarga(false);
      });
  }, []);

  useEffect(() => {
    if (
      bill.customer_id === "" ||
      bill.customer_id === undefined ||
      bill.date === "" ||
      bill.type === "" ||
      bill.series === "" ||
      (!user?.folio_check && bill.folio_number === "") ||
      !bill.relation ||
      bill.payment_method === "" ||
      bill.payment_form === "" ||
      bill.use === "" ||
      bill.items.length === 0 ||
      (bill.currency !== "MXN" && bill.exchange === "")
        ? true
        : false ||
          (bill.global &&
            (!bill.global.months ||
              !bill.global.year ||
              !bill.global.periodicity))
        ? true
        : false
    ) {
      setActiveSuccess(true);
    } else {
      setActiveSuccess(false);
    }
  }, [bill]);

  return (
    <>
      <Grid container spacing={0}>
        <BaseCard title="NUEVA FACTURA">
          {carga ? (
            <BackdropLoader loading={carga} />
          ) : pending ? (
            <ValidatePending />
          ) : (
            <>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item container justifyContent={"end"} mr={2}>
                  <SwitchGlobalNewBill
                    activeSwitch={activeSwitch}
                    setActiveSwitch={setActiveSwitch}
                    bill={bill}
                    setBill={setBill}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <ListReceptorDataBill />
                </Grid>
                {activeSwitch && (
                  <Grid item xs={12} lg={12}>
                    <FormGlobalDataNewBill
                      activeSwitch={activeSwitch}
                      bill={bill}
                      setBill={setBill}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={12}>
                  <FormDataNewBill
                    activeSwitch={activeSwitch}
                    bill={bill}
                    setBill={setBill}
                    activeFolio={user?.folio_check === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <ConceptsNewBill bill={bill} setBill={setBill} />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <RelatedBillNewBill
                    complementBill={bill}
                    setComplementBill={setBill}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </BaseCard>

        <Box
          sx={{
            position: "fixed",
            right: "15%",
            left: "20%",
            display: "flex",
            bottom: "1%",
            alignItems: "center",
          }}
        >
          <ButtonsNewBill
            bill={bill}
            activeSuccess={activeSuccess}
            setActiveSuccess={setActiveSuccess}
            user={user}
          />
        </Box>
      </Grid>
    </>
  );
};

export default NewBillsPage;
