import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//Crear un producto
export const axiosCreateProductFacturaApi = async (product) => {
  let url = `${API_HOST}/api/product/create`;
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
    product: product,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = axios.post(url, data, headersConfig);

  return response;
};
//buscar y listar productos
export const axiosGetProductFacturaApi = async (page = 1, search = "") => {
  const key = localStorage.getItem("api_key");
  let url = `${API_HOST}/api/product/show/${page}`;
  search.length === 0
    ? (url = `${API_HOST}/api/product/show/${page}`)
    : (url = `${API_HOST}/api/product/show/${page}/${search}`);

  //headers
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  //acomodo de info body
  let data = {
    key: key,
  };

  let response = axios.post(url, data, headersConfig);
  return response;
};
//eliminar productos
export const axiosDeleteProductFacturaApi = async (id) => {
  let url = `${API_HOST}/api/product/delete/${id}`;
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let response = await axios.post(url, data, headersConfig);
  return response;
};
//Consultar producto po id
export const axiosShowProductIDFacturaApi = async (id) => {
  let url = `${API_HOST}/api/product/showId/${id}`;
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
//Editar producto
export const axiosEditProductFacturaApi = async (product) => {
  let url = `${API_HOST}/api/product/update`;
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
    product: product,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
//buscar productos (autocomplete)
export const axiosSeachProductsFacturaApi = async (search) => {
  let url = `${API_HOST}/api/product/show`;
  search.length === 0
    ? (url = `${API_HOST}/api/product/show`)
    : (url = `${API_HOST}/api/product/search/${search}`);
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
