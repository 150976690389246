import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box
} from '@mui/material';
import BaseCard from '../../components/General/BaseCard';
import ListReceptorDataBill from '../../components/Bills/NewBill/ListReceptorDataBill';
import FormDataNewComplementBill from '../../components/Bills/NewComplementsBill/FormDataNewComplementBill';
import ComplementsNewComplementBill from '../../components/Bills/NewComplementsBill/ComplementsNewComplementBill';
import ButtonsNewComplementsBill from '../../components/Bills/NewComplementsBill/ButtonsNewComplementsBill';
import ValidatePending from '../../components/General/ValidatePending';
import { axiosPending } from '../../api/axiosPending';
import { isTotallyEmpty } from '../../utils/validations';
import { toast } from 'react-toastify';
import BackdropLoader from '../../layouts/BackdropLoader';
import RelatedBillNewBill from '../../components/Bills/RelatedBillNewBill';

const NewComplementBillPage = () => {
    const [complementBill, setComplementBill] = useState({
        type: 'P',
        customer_id: '',
        complements: '',
        date: '',
        series: '',
        folio_number: '',
        pdf_custom_section: '',
        relation: true,
    });

    //control de states pendientes
    const [pending, setPending] = useState(false);
    const [carga, setCarga] = useState(true);

    // ComponentDidMount
    useEffect(() => {

        axiosPending()
            .then(response => {

                let { data: { data: data } } = response;
                if (data) {
                    Object.keys(data).forEach((e) => {
                        if (isTotallyEmpty(data[e])) {
                            setPending(true);
                        }
                    });
                }
                setCarga(false);
            }).catch(err => {

                toast.error("Error de red", err);
                setCarga(false);
            });

    }, []);

    return (
        <>
            <Grid container spacing={0}>
                <BaseCard title="NUEVO COMPLEMENTO DE FACTURA">
                    {carga ? (
                        <BackdropLoader loading={carga} />
                    ) : (pending ? (
                        <ValidatePending />
                    ) : (
                        <Grid container justifyContent={'space-between'} alignItems='center' >
                            <Grid item xs={12} lg={12}>
                                <ListReceptorDataBill />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <FormDataNewComplementBill
                                    complementBill={complementBill}
                                    setComplementBill={setComplementBill}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <ComplementsNewComplementBill
                                    complementBill={complementBill}
                                    setComplementBill={setComplementBill}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <RelatedBillNewBill
                                    complementBill={complementBill}
                                    setComplementBill={setComplementBill}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </BaseCard>
                <Box
                    sx={{
                        position: 'fixed',
                        right: '15%',
                        left: '20%',
                        display: 'flex',
                        bottom: '1%',
                        alignItems: "center",
                    }}>

                    <ButtonsNewComplementsBill
                        complementBill={complementBill}
                    />
                </Box>
            </Grid>
        </>
    )
}

export default NewComplementBillPage