import React from "react";

import { toast } from "react-toastify";
import {
  axiosCancellationReceiptXml,
  axiosCancellationReceiptPdf,
} from "../../api/axiosBills";
import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  Stack,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import XML from "../../img/archivoXML.png";
import PDF from "../../img/archivoPDF.png";
import { acusePDF } from "../../utils/scriptsPDF/acusePDF";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  justifyContent: "center",
  color: theme.palette.text.secondary,
}));

const CancellationReceiptBills = ({
  openReceiptModal,
  handlerCancellationReceipt,
  billInfo,
}) => {
  const handleDownloadXml = () => {
    if (Object.entries(billInfo).length >= 1) {
      axiosCancellationReceiptXml(billInfo.id)
        .then((response) => {
          const blob = new Blob([response.data.data], {
            type: "application/xml",
          });

          const fileURL = URL.createObjectURL(blob);
          const tempLink = document.createElement("a");
          tempLink.href = fileURL;
          tempLink.setAttribute("download", `Acuse-${billInfo.id}.xml`);
          tempLink.click();

          window.open(fileURL);

          return response;
        })
        .catch((err) => {
          toast.error(err.response.data.message);

          return err;
        });
    }
  };

  const handleDownloadPdf = () => {
    if (Object.entries(billInfo).length >= 1) {
      axiosCancellationReceiptPdf(billInfo.id)
        .then((response) => {
          if (response.data.status === true) {
            acusePDF(response.data.data, billInfo.id);
          }
          return response;
        })
        .catch((err) => {
          toast.error("No se pudo generar el PDF");
          return err;
        });
    }
  };

  return (
    <>
      <Dialog
        open={openReceiptModal}
        onClose={handlerCancellationReceipt}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          {`Descarga de Acuse de Cancelación de Factura ${billInfo?.series} - ${billInfo?.folio_number}`}
        </DialogTitle>

        <DialogContent>
          <Box sx={{ width: "100%", mt: 2 }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Item>
                <CardActionArea onClick={() => handleDownloadXml()}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 60 }}
                      image={XML}
                      alt="XML"
                    />
                    <CardContent>
                      <Typography component="div" variant="h6">
                        Descargar XML
                      </Typography>
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Item>
              <Item>
                <CardActionArea onClick={handleDownloadPdf}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 60 }}
                      image={PDF}
                      alt="Tarjeta"
                    />
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component="div" variant="h6">
                        Descargar PDF
                      </Typography>
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Item>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlerCancellationReceipt} autoFocus>
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancellationReceiptBills;
