import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  MenuItem,
  Menu,
  Tooltip,
  Divider,
  Select,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import FacturaliteW from "../../img/FacturaliteW.svg";
import BackdropLoader from "../BackdropLoader";
import { logoutApi } from "../../api/auth";
import { APP_TEST, TOKEN } from "../../utils/constant";
import { AuthContext } from "../../auth/AuthContext";
import { axiosCountCompany } from "../../api/axiosCompany";
import { isTotallyEmpty } from "../../utils/validations";
import { capitalizeFirst } from "../../utils/formats";
import { axioGetAvatarPic } from "../../api/userInfo";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MyAppBar = ({ open, handleDrawerOpen }) => {
  const { user, setUser } = useContext(AuthContext);
  let token = localStorage.getItem(TOKEN);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [countCompany, setCountCompany] = useState(0);
  const [company, setCompany] = useState({
    api_key: APP_TEST === "true" ? user?.test_api_key : user?.live_api_key,
    legal_name: user?.legal_name,
    id_organization: user?.id_organization,
    tax_id: user?.tax_id,
  });
  const [companys, setCompanys] = useState([]);
  const def = localStorage.getItem("api_key");
  const [image, setImage] = useState(localStorage.getItem("image"));
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePerfil = () => {
    handleCloseUserMenu();
    navigate("/config");
  };

  const handleLogout = () => {
    setLoading(true);

    handleCloseUserMenu();
    logoutApi()
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          localStorage.removeItem(TOKEN);
          localStorage.removeItem("api_key");
          setUser({ name: "user" });
          navigate("/auth/login");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    axiosCountCompany()
      .then((res) => {
        setLoading(false);
        setCountCompany(res.data.count);
        if (res.data.count >= 1) {
          setCompanys(res.data.company);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });

    if (!image) {
      axioGetAvatarPic()
        .then((res) => {
          let img = res.data.image;
          localStorage.setItem("image", img);
          setImage(img);
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
  }, []);

  const onChange = (event) => {
    setLoading(true);

    setCompany({ ...company, [event.target.name]: event.target.value });
    const filterCompany = companys.filter(
      (item) =>
        event.target.value === item.live_api_key ||
        event.target.value === item.test_api_key
    );

    localStorage.setItem("api_key", event.target.value);

    setUser({
      ...user,
      id_organization: filterCompany[0].id_facturapi,
      legal_name: filterCompany[0].legal_name,
      test_api_key: filterCompany[0].test_api_key,
      live_api_key: filterCompany[0].live_api_key,
      tax_system: filterCompany[0].tax_system,
      tax_id: filterCompany[0].tax_id,
      folio_check: filterCompany[0].folio_check,
      folio_init: filterCompany[0].folio_init,
    });

    setTimeout(() => {
      setLoading(false);
      navigate("/dashboard");
    }, 15);
  };

  useEffect(() => {
    setImage(localStorage.getItem("image"));
  }, [user]);

  return (
    <>
      {loading && <BackdropLoader loading={loading} />}

      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ minWidth: "-webkit-fill-available" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              mr: 3,
              flexGrow: 1,
              display: { xs: "none", md: "grid" },
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" textAlign="center">
              <img src={FacturaliteW} alt="Facturalite White" width="55%" />
            </Typography>
          </Box>
          {user && (
            <>
              <Box
                sx={{
                  mt: 1,
                  mr: 3,
                  flexGrow: 1,
                  display: { xs: "grid", md: "grid" },
                  maxWidth: { xs: "220px" },
                  justifyContent: "end",
                }}
              >
                {countCompany > 1 ? (
                  !isTotallyEmpty(def) && (
                    <Select
                      labelId="select-company-label"
                      id="select-company"
                      name="api_key"
                      value={company.api_key}
                      defaultValue={def}
                      fullWidth
                      margin="dense"
                      onChange={onChange}
                      label="Empresa Actual"
                      sx={{
                        color: "white",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      variant="standard"
                    >
                      {companys?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={
                            APP_TEST === "true"
                              ? option.test_api_key
                              : option.live_api_key
                          }
                        >
                          {option.legal_name} - {option.tax_id}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                ) : (
                  <>
                    {!isTotallyEmpty(user?.legal_name) ? (
                      <>
                        {" "}
                        <Typography textAlign="right">
                          {user?.legal_name}
                        </Typography>
                        <Typography textAlign="right">
                          {user?.tax_id}
                        </Typography>
                      </>
                    ) : (
                      <Typography textAlign="right">{user?.name}</Typography>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: 3 }}>
                {user?.has_avatar && image ? (
                  <Avatar src={`data:image/png;base64,${image}`} alt="Avatar" />
                ) : (
                  user?.name && <Avatar src={user?.name[0]} alt="Avatar" />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography textAlign="center" variant="subtitle2">
                Usuario
              </Typography>
              <Typography textAlign="center" variant="h5" margin={1}>
                {capitalizeFirst(user?.name)}
              </Typography>
              <Divider />
              <MenuItem key="perfil" onClick={handlePerfil}>
                <Typography textAlign="center"> Perfil </Typography>
              </MenuItem>

              <MenuItem key="cerrrar" onClick={handleLogout}>
                <Typography textAlign="center"> Cerrar sesión </Typography>
              </MenuItem>
              <Divider />
              <Typography textAlign="center">Facturalite 2022</Typography>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MyAppBar;
