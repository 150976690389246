import React, { useState, useContext } from 'react'
import {
  Typography,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  DialogContent,
  DialogActions,
  Box
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../auth/AuthContext';
// axios api
import BackdropLoader from '../../layouts/BackdropLoader';
import { signIn, setStorageToken, isUserLogedApi } from '../../api/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  //espera
  const [loading, setLoading] = useState(false);

  const [credentials, setCredentials] = useState(
    //initial obj
    {
      email: '',
      password: ''
    }
  );

  const [show, setShow] = useState({
    password: "",
    showPassword: false,
  });
  //obtenemos los datos email, password
  const handlerCredentialsChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }

  //View Password
  const handleClickShow = () => {

    setShow({ ...show, showPassword: !show.showPassword });

  };
  const handleMouseDown = event => {
    event.preventDefault();
  };
  // Enviamos los datos al axios del login
  const login = (e) => {

    setLoading(true);

    e.preventDefault();

    signIn(credentials)
      .then(response => {

        if (response.data) {
          let jwt = `${response.data.token_type} ${response.data.access_token}`;

          let tokenStored = setStorageToken(jwt); //function to save token
          localStorage.setItem('api_key', response.data.api_key);
          if (tokenStored === true) {
            setToken(isUserLogedApi());
            navigate('/');
          }
        }
        setLoading(false);
      }).catch(err => {

        if (err.response.status === 401) {
          toast.warning(err.response.data.message);
        }
        else if (err.response.status !== 500) {
          toast.warning("Error de Red");
        }
        else {
          toast.warning("Error en el servidor");
        }

        setLoading(false);
      });

  }

  return (

    <>
      {loading &&
        <BackdropLoader loading={loading} />
      }

      <Typography sx={{ fontSize: 22, textAlign: 'center' }} color="text.secondary" gutterBottom>
        Iniciar Sesión
      </Typography>
      <DialogContent sx={{ p: 2 }}>

        <TextField
          label="Correo"
          variant='filled'
          fullWidth
          margin="normal"
          name="email"
          placeholder='Escribe el correo con el que realizaste tu registro'
          onChange={handlerCredentialsChange}
          value={credentials.email}
        />
        <TextField
          label="Contraseña"
          variant='filled'
          fullWidth
          margin="normal"
          name="password"
          placeholder='Escribe la Contraseña'
          onChange={handlerCredentialsChange}
          value={credentials.password}
          type={show.showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    handleClickShow(true)
                  }
                  onMouseDown={handleMouseDown} edge="end" >
                  {show.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />


      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', }}>
        {/* login */}
        <Button
          size="small"
          variant="contained"
          color="primary"
          className="button"
          onClick={login}  >
          Ingresar
        </Button>
      </DialogActions >
      {/* resgistro */}
      <Box sx={{ justifyContent: 'center', pt: 2 }}>

        <Typography variant="body2" align='center'>
          ¿Aún no tienes una cuenta?
          <Typography
            component={Link}
            to="/auth/register"
            fontWeight="500"
            sx={{
              textDecoration: "none",
              fontWeight: "550",
              color: "primary.light",
            }}>
            , registrarme.
          </Typography>
        </Typography>

        {/* password */}
        <Typography variant="body2" align='center' p={2}>
          {'¿Olvidaste tu contraseña?'}
        </Typography>
      </Box>

    </>

  );
}

export default Login