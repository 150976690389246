import React from 'react'
import {
  Grid, Typography,
} from '@mui/material'
import BaseCard from '../../components/General/BaseCard';
import CardShop from '../../components/Shop/CardShop';

import Shopitems from "./data";
import CardInfoSubscriptionShop from '../../components/Shop/CardInfoSubscriptionShop';

const ShopPage = () => {

  return (
    <Grid container spacing={0}>
      <BaseCard title="TIENDA">
        <Grid container spacing={0} justifyContent="center" >
          <Grid item xs={12} sm={12} lg={12} >
            <CardInfoSubscriptionShop />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} sx={{ m: 2 }}>
            <Typography variant='h4' textAlign={'center'} >
              Nuestros Planes
            </Typography>
          </Grid>
          {Shopitems.map((plan, index) => (
            <Grid item xs={12} sm={3} lg={3} key={plan.id} >
              <CardShop plan={plan} />
            </Grid>
          ))}

        </Grid>
      </BaseCard>
    </Grid>
  )
}

export default ShopPage