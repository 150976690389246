import React, { useState, useEffect } from 'react'
import {
    TextField,
    MenuItem,
    Grid,
    Divider,
    FormControl,
    InputLabel,
    Select,
    Paper,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { Theme } from '@mui/material/styles';
import { UUIDCustom } from '../../../utils/formats';
import { validateUuid } from '../../../utils/validations';

const RelatedDocumentsNewComplementsBill = ({ keyId, hiddenDocument, setRelatedDocuments }) => {
    const [relatedData, setRelatedData] = useState({
        numberOfDocument: keyId,
        uuid: '',
        amount: '',
        installment: '',
        last_balance: '',
        taxes: '',
    });

    const [taxes, setTaxes] = useState({
        iva: '',
        isr: '',
        ieps: 0,
    });
    // Adding Concept to array of concepts on useEffect hook 2021

    const handleChange = ({ target }) => {
        setRelatedData({ ...relatedData, [target.name]: target.value });
    };

    const handleChangeTaxes = ({ target }) => {
        setTaxes({ ...taxes, [target.name]: target.value });
    }

    useEffect(() => {

        setRelatedDocuments(prevRelatedDocuments => {
            let newArr = [];
            let isNew = true;
            for (let i = 0; i < prevRelatedDocuments.length; i++) {

                if (prevRelatedDocuments[i].numberOfDocument === relatedData.numberOfDocument) {

                    isNew = false;
                    newArr.push(relatedData);

                } else {
                    newArr.push(prevRelatedDocuments[i]);
                }
            }

            if (isNew) {
                newArr.push(relatedData);
            }

            return newArr;
        });

    }, [relatedData])



    useEffect(() => {
        setRelatedData({ ...relatedData, taxes: taxes });
    }, [taxes]);

    return (
        <>
            <Paper sx={{ p: 2, m:2 }} elevation={4}>

                <Grid container spacing={1} >
                    <Grid item xs={12} sm={3} xl={3}>
                        <TextField
                            margin="dense"
                            name="uuid"
                            label="UUID del comprobante de ingreso"
                            type="text"
                            placeholder='Folio fiscal ó UUID del comprobante de ingreso relacionado.'
                            variant="filled"
                            required
                            fullWidth
                            onChange={handleChange}
                            helperText='Folio fiscal ó UUID del comprobante de ingreso relacionado'
                            error={!validateUuid(relatedData.uuid) && relatedData.uuid !== ''}
                            value={relatedData.uuid}
                            InputProps={{
                                inputComponent: UUIDCustom
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                        <TextField
                            margin="dense"
                            name="amount"
                            label="Cantidad del pago"
                            type="text"
                            placeholder='Correspondiente al comprobante relacionado, usando el método de pago indicado en este elemento del arreglo de pagos.'
                            helperText='Cantidad del pago correspondiente al comprobante relacionado'
                            onChange={handleChange}
                            value={relatedData.amount}
                            variant="filled"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                        <TextField
                            margin="dense"
                            name="installment"
                            label="Número de parcialidad"
                            type='number'
                            placeholder='Número de parcialidad del pago.'
                            helperText='Número de parcialidad del pago.'
                            onChange={handleChange}
                            value={relatedData.installment}
                            variant="filled"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                        <TextField
                            margin="dense"
                            name="last_balance"
                            label="Cantidad pendiente"
                            type="number"
                            placeholder='Cantidad que estaba pendiente por pagar antes de recibir este pago.'
                            helperText='Cantidad que estaba pendiente por pagar antes de recibir este pago.'
                            onChange={handleChange}
                            value={relatedData.last_balance}
                            variant="filled"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider textAlign="left">Impuestos federales</Divider>
                    </Grid>
                    <Grid item xs={3}>
                    <TextField
                            margin="dense"
                            name="base"
                            label="Base"
                            type="number"
                            placeholder='Base utilizada para el cálculo del impuestos.'
                            helperText='Base utilizada para el cálculo del impuestos.'
                            onChange={handleChange}
                            value={relatedData.base}
                            variant="filled"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant='filled' margin="dense"
                            fullWidth>
                            <InputLabel id="percent-iva-label">IVA %</InputLabel>
                            <Select
                                labelId="percent-iva-label"
                                id="percent-iva"
                                name="iva"
                                onChange={handleChangeTaxes}
                                fullWidth
                                defaultValue={.16}
                                margin="dense"
                                value={taxes.iva}
                                label="IVA %" >
                                <MenuItem value={.16}>16.00%</MenuItem>
                                <MenuItem value={.08}>8.00%</MenuItem>
                                <MenuItem value={0.0}>0%</MenuItem>
                                <MenuItem value="0.0">
                                    <em>
                                        Sin Iva
                                    </em>
                                </MenuItem>
                                <MenuItem value="Exempt">
                                    <em>
                                        Exento
                                    </em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant='filled'
                            margin="dense"
                            fullWidth>
                            <InputLabel id="percent-isr-label">Retención  ISR %</InputLabel>
                            <Select
                                labelId="percent-isr-label"
                                id="percent-isr"
                                name="isr"
                                fullWidth
                                label="Retención  ISR %"
                                margin="dense"
                                value={taxes.isr}
                                defaultValue={"0"}
                                onChange={handleChangeTaxes}
                            >
                                <MenuItem value="0">
                                    <em>Sin ISR</em>
                                </MenuItem>
                                <MenuItem value={0.10}>10.00%</MenuItem>
                                <MenuItem value={0.0125}>1.25%</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="outlined-basic"
                            margin="dense"
                            label="IEPS"
                            name="ieps"
                            type="number"
                            variant='filled'
                            fullWidth
                            value={taxes.ieps}
                            onChange={handleChangeTaxes}
                            inputProps={{ maxLength: 4 }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default RelatedDocumentsNewComplementsBill