import React from "react";

import { Navigate } from "react-router-dom";
import { isUserLogedApi } from "../api/auth";

const PublicRoute = ({ children }) => {
  let auth = isUserLogedApi();

  return auth ? <Navigate to="/" /> : children;
};

export default PublicRoute;
