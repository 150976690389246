import React from 'react'
import {
  InputBase,
  IconButton,
  Paper
} from '@mui/material'

import { Search } from '@mui/icons-material';

const SearchBills = ({ triggerAxiosPaginate, search, setSearch }) => {
  const handleSearch = e => {
    e.preventDefault();
    triggerAxiosPaginate(1, search);
  };

  const onChange = (event) => {
    setSearch(event.target.value);
    setTimeout(() => {

      if (event.target.value === '') {
        triggerAxiosPaginate();
      }
    }, 10);
  };

  return (
    <>
      <Paper
        sx={{ m: 1, p: '2px 4px', display: 'flex', alignItems: 'center', }} >

        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder='Buscar Factura'
          inputProps={{ 'aria-label': 'search Facturas' }}
          value={search}
          onChange={onChange}
        />
        <IconButton onClick={handleSearch}  disabled={search.length <= 0} sx={{ p: '10px' }} aria-label="search">
          <Search />
        </IconButton>

      </Paper>
    </>
  )
}

export default SearchBills