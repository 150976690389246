import React, { useState, useEffect, useContext } from "react";
import BaseCard from "../../components/General/BaseCard";
import { Grid, Box, Typography, Divider } from "@mui/material";
import CheckListMassSendBills from "../../components/Bills/MassSendBills/CheckListMassSendBills";

import { toast } from "react-toastify";
import { getBillsMasive } from "../../api/axiosBills";
import SearchBills from "../../components/Bills/SearchBills";
import ButtonsMassSendBills from "../../components/Bills/MassSendBills/ButtonsMassSendBills";
import SelectedEmailsMassSendBills from "../../components/Bills/MassSendBills/SelectedEmailsMassSendBills";

const MassSendBillsPage = () => {
  const [loading, setLoading] = useState(true);
  const [bills, setBills] = useState([]);
  const [sendEmails, setSendEmails] = useState([]);
  const [sendBills, setSendBills] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [countBills, setCountBills] = useState(0);
  const [isClient, setIsClient] = useState("0");
  const triggerAxiosPaginate = (page = 1, search) => {
    setLoading(true);
    // api
    getBillsMasive(page, search)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          setPage(response.data.page);
          setTotalPages(response.data.total_pages);
          setBills(response.data.data);
          setCountBills(response.data.total_data);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error(err?.response.data.message);
        } else {
          toast.error("Error en el servidor");
        }
        setLoading(false);
        return err;
      });
  };
  // ComponentDidMount
  useEffect(() => {
    triggerAxiosPaginate(page);
  }, []);
  return (
    <>
      <Grid container spacing={0}>
        <BaseCard title="ENVIO MASIVO DE FACTURAS">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h6"
                fontWeight="600"
                marginBottom={"1%"}
                marginTop={"2%"}
              >
                Selección de Correos
              </Typography>
              <Divider style={{ marginBottom: "20px" }} />
              <SelectedEmailsMassSendBills
                sendEmails={sendEmails}
                setSendEmails={setSendEmails}
                setIsClient={setIsClient}
                isClient={isClient}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                variant="h6"
                fontWeight="600"
                marginBottom={"1%"}
                marginTop={"2%"}
              >
                Selección de Facturas
              </Typography>
              <SearchBills
                triggerAxiosPaginate={triggerAxiosPaginate}
                search={search}
                setSearch={setSearch}
              />
              <Divider style={{ marginBottom: "20px" }} />
              <CheckListMassSendBills
                bills={bills}
                selectedRows={sendBills}
                setSelectedRows={setSendBills}
                totalPages={totalPages}
                loading={loading}
                page={page}
                search={search}
                countBills={countBills}
                triggerAxiosPaginate={triggerAxiosPaginate}
              />
            </Grid>
          </Grid>
        </BaseCard>
        <Box
          sx={{
            position: "fixed",
            right: "15%",
            left: "20%",
            display: "flex",
            bottom: "1%",
            alignItems: "center",
          }}
        >
          <ButtonsMassSendBills
            sendBills={sendBills}
            sendEmails={sendEmails}
            isClient={isClient}
          />
        </Box>
      </Grid>
    </>
  );
};

export default MassSendBillsPage;
