import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

export const moneyFormat = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Disable date from 3 days before or more
export const dateFormat3Days = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 4);

  const startSeconds = Date.parse(startDate);

  return (date) => {
    return Date.parse(date) < startSeconds;
  };
};

export const yearFrom5YearsAgo = () => {
  let year = new Date();
  year.setFullYear(year.getFullYear() - 5);

  return year.getFullYear();
};

export const getMonthByNumber = (n) => {
  let months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return months[n - 1];
};

export const bimesterInit = () => {
  const mes = new Date().getMonth() + 1;

  if (mes === 1 || mes === 2) {
    return 13;
  } else if (mes === 3 || mes === 4) {
    return 14;
  } else if (mes === 5 || mes === 6) {
    return 15;
  } else if (mes === 7 || mes === 8) {
    return 16;
  } else if (mes === 9 || mes === 10) {
    return 17;
  } else if (mes === 11 || mes === 12) {
    return 18;
  }
};

export const getBimesterByNumber = (n) => {
  let bimester = [
    "Enero - Febrero",
    "Marzo - Abril",
    "Mayo - Junio",
    "Julio - Agosto",
    "Septiembre - Octubre ",
    "Noviembre - Diciembre",
  ];

  return bimester[n - 1];
};
export const getBase64 = (file) => {
  return new Promise(function (resolve) {
    let render = new window.FileReader();

    render.onloadend = function () {
      resolve(render.result);
    };
    render.readAsDataURL(file);
  });
};

export function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export const getCurrentHour = () => {
  let date = new Date();

  let hour = date.getHours();

  return hour;
};

export const getNumberPagesFromBase64Pdf = (base64) => {
  return new Promise((resolve, reject) => {
    const binaryPdf = atob(base64);
    const length = binaryPdf.length;
    const arrayBuffer = new ArrayBuffer(length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < length; i++) {
      uintArray[i] = binaryPdf.charCodeAt(i);
    }

    const fileBlob = new Blob([uintArray], { type: "application/pdf" });

    const reader = new FileReader();

    reader.readAsBinaryString(fileBlob);

    // The number of pages
    let count = 0;
    reader.onloadend = () => {
      count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
      resolve(count);
    };
  });
};

export const formatUnixToDate = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const day = date.getDate();
  const month = date.getMonth();
  const hours = date.getHours();
  const year = date.getFullYear();
  const minutes = "0" + date.getMinutes();

  const formattedTime =
    day + "/" + month + "/" + year + "    " + hours + ":" + minutes.substr(-2);

  return formattedTime;
};

export const formatDateLetter = (date) => {
  date = new Date(date);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (month < 10) {
    date = `${day}-0${month}-${year} ${hours}:${minutes}`;
  } else {
    date = `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return date;
};

export const typeMovement = [
  {
    value: "I",
    label: "Ingreso",
  },
  {
    value: "E",
    label: "Egreso",
  },
];

export const modoMovement = [
  {
    value: "R",
    label: "Recibido",
    conexion: "E",
  },
  {
    value: "E",
    label: "Emitido",
    conexion: "I",
  },
];

export const typeMovementLetter = (type) => {
  if (type === "I") {
    return "Ingreso";
  } else {
    return "Egreso";
  }
};

export const modeMovementLetter = (mode) => {
  if (mode === "E") {
    return "Emitido";
  } else {
    return "Recibido";
  }
};

export const months = [
  {
    id: "01",
    month: "01",
  },
  {
    id: "02",
    month: "02",
  },
  {
    id: "03",
    month: "03",
  },
  {
    id: "04",
    month: "04",
  },
  {
    id: "05",
    month: "05",
  },
  {
    id: "06",
    month: "06",
  },
  {
    id: "07",
    month: "07",
  },
  {
    id: "08",
    month: "08",
  },
  {
    id: "09",
    month: "09",
  },
  {
    id: "10",
    month: "10",
  },
  {
    id: "11",
    month: "11",
  },
  {
    id: "12",
    month: "12",
  },
];

export const registerYear = () => {
  let date = new Date();
  let yearCurrent = date.getFullYear();
  let yearEnd = date.getFullYear() + 10;
  let years = [];
  for (var i = yearCurrent; i <= yearEnd; i++) {
    years.push(i);
  }

  return years;
};

export const capitalizeFirst = (str) => {
  const arr = str.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const str2 = arr.join(" ");

  return str2;
};

export const UUIDCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="########-####-####-####-############"
      definitions={{
        "#": /[0-9A-Za-z]/,
      }}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const CardCustom = React.forwardRef(function CardCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000 0000 0000 0000"
      definitions={{
        "#": /[1-16]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

CardCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const CvvCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000"
      definitions={{
        "#": /[1-3]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      isnumericstring
    />
  );
});

export const expiration = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00"
      definitions={{
        "#": /[1-2]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      isnumericstring
    />
  );
});

export const oldFiveYear = () => {
  let date = new Date();
  let year = date.setFullYear(date.getFullYear() - 5);

  return year;
};
