import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NewProductForm from "./NewProductForm";
import { axiosCreateProductFacturaApi } from "../../api/axiosProducts";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  floatRight: {
    float: "right",
  },
});

const NewProducts = ({
  btnText,
  originPosition,
  triggerAxiosPaginate,
  getSearchProducts,
  n = false,
}) => {
  const classes = useStyles();
  // UX / UI states
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(initialState());
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setProduct(initialState());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkIfEmpty = (val) => {
    if (val === "" || val === null) return false;
    return true;
  };

  const handleStoreProduct = (e) => {
    e.preventDefault();
    if (!checkIfEmpty(product.description)) {
      toast.warning(
        "Completa la descripción del bien o servicio como aparecerá en la factura."
      );
    } else if (!checkIfEmpty(product.prod_serv_id)) {
      toast.warning("Completa la clave de producto/servicio.");
    } else if (!checkIfEmpty(product.price)) {
      toast.warning("Completa el precio por unidad del bien o servicio.");
    } else if (!checkIfEmpty(product.riva)) {
      toast.warning("Completa la selección de la retención de IVA%.");
    } else if (!checkIfEmpty(product.isr)) {
      toast.warning("Completa la selección de la retención de ISR%.");
    } else if (!checkIfEmpty(product.taxability)) {
      toast.warning("Completa la selección del ObjetoImp");
    } else {
      axiosCreateProductFacturaApi(product)
        .then((response) => {
          if (response.data.status === false) {
            toast.warning(response.data.message);
            setLoading(false);
          } else {
            toast.success(response.data.message);
            setTimeout(() => {
              handleClose();
              if (n === false) {
                triggerAxiosPaginate(1);
              } else {
                getSearchProducts();
              }
              setProduct(initialState());
            }, 10);
          }
          setLoading(false);
        })
        .catch((err) => {
          // if (err.response.status === 400) {
          //     toast.warning(err.response.data.message);

          // } else {

          toast.warning("Error en la red");
          // }
        });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className={originPosition && classes.floatRight}
      >
        {btnText}
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="dialog-title-add-client"
        aria-describedby="dialog-title-add-description"
      >
        <DialogTitle id="dialog-title-add-client"> {btnText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-title-add-description">
            Ingresa los datos del nuevo producto o servicio
          </DialogContentText>

          <NewProductForm product={product} setProduct={setProduct} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleStoreProduct}
          >
            {!loading ? (
              "Guardar"
            ) : (
              <CircularProgress size={28} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function initialState() {
  return {
    description: "",
    price: "",
    tax_included: true,
    taxability: "02",
    unit_code_id: "",
    prod_serv_id: "",
    sku: "",
    iva: "",
    riva: "",
    isr: "",
    ieps: "0",
    prodServCodeText: "",
    unitCodeText: "",
  };
}

export default NewProducts;
