import React, { useState, useEffect } from "react";
import { theme } from "./utils/theme";
import { AuthContext } from "./auth/AuthContext";
import AppRouter from "./route/AppRouter";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { handlerGetAditionalData } from "./api/auth";
import { TOKEN } from "./utils/constant";
import { axiosCountCompany } from "./api/axiosCompany";

const App = () => {
  const [user, setUser] = useState({ name: "user" });
  let isLogged = false;
  const [token, setToken] = useState(localStorage.getItem(TOKEN));
  token == null ? (isLogged = false) : (isLogged = true);

  const [companys, setCompanys] = useState([]);

  useEffect(() => {
    if (token !== null) {
      axiosCountCompany()
        .then((res) => {
          if (res.data.count >= 1) {
            setCompanys(res.data.company);
          }
        })
        .catch((err) => {
          return err;
        });

      handlerGetAditionalData()
        .then((response) => {
          let { data } = response;

          setUser({
            ...user,
            id: data.id,
            name: data.name,
            legal_name: data.legal_name,
            tax_id: data.tax_id,
            email: data.email,
            phone: data.phone,
            country_code: data.country_code,
            bells: data.bells,
            has_cer_file: data.has_cer_file,
            has_key_file: data.has_key_file,
            has_avatar: data.has_avatar,
            expire_csd: data?.expire_csd,
            municipality: data.municipality,
            state_id: data.state_id,
            password_csd: data.password_csd,
            id_organization: data.id_organization,
            test_api_key: data.key_test_facturapi,
            live_api_key: data.key_live_facturapi,
            roles_id: data.roles_id,
            folio_check: data.folio_check,
            folio_init: data.folio_init,
          });
        })
        .catch((err) => {
          return err;
        });
    }
  }, [token]);

  useEffect(() => {
    if (companys.length > 1) {
      const key = localStorage.getItem("api_key");

      const filterCompany = companys?.filter(
        (item) => key === item.live_api_key || key === item.test_api_key
      );

      setUser({
        ...user,
        id_organization: filterCompany[0].id_facturapi,
        legal_name: filterCompany[0].legal_name,
        test_api_key: filterCompany[0].test_api_key,
        live_api_key: filterCompany[0].live_api_key,
        tax_system: filterCompany[0].tax_system,
        tax_id: filterCompany[0].tax_id,
        folio_check: filterCompany[0].folio_check,
        folio_init: filterCompany[0].folio_init,
      });
    }
  }, [companys]);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken }}>
      <ThemeProvider theme={theme}>
        <AppRouter />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
