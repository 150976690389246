import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import SignInSignUp from '../pages/SignInSignUp/SignInSignUp';
import Register from '../pages/SignInSignUp/Register';
import Login from '../pages/SignInSignUp/Login';

const SignInSignUpRoutes = () => {

    return (
        <div>
            <Routes>
                <Route path="" element={<SignInSignUp />} >
                    <Route path="" element={<Navigate to="login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                </Route>
            </Routes>
        </div>
    )
}

export default SignInSignUpRoutes