import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";

import CardContactDataConfig from "./UserAccountConfig/CardContactDataConfig";
import CardAvatarDataConfig from "./UserAccountConfig/CardAvatarDataConfig";
import { AuthContext } from "../../auth/AuthContext";

const UserAccountConfig = () => {
  const { user, setUser } = useContext(AuthContext);

  const [mutableUserData, setMutableUserData] = useState({
    name: user?.legal_name ? user?.legal_name : "",
    phone: user?.phone ? user?.phone : "",
    email: user?.email ? user?.email : "",
    has_avatar: user?.has_avatar ? user?.has_avatar : "",
    image: user?.has_avatar ? localStorage.getItem("image") : "",
    country_code: user?.country_code ? user?.country_code : "",
    folio_check: user?.folio_check ? user?.folio_check : 0,
    folio_init: user?.folio_init ? user?.folio_init : "",
  });

  return (
    <>
      <Grid container spacing={0}>
        <Grid item lg={4} md={12} xs={12}>
          <CardAvatarDataConfig
            mutableUserData={mutableUserData}
            setMutableUserData={setMutableUserData}
          />
        </Grid>
        <Grid item lg={8} md={12} xs={12}>
          <CardContactDataConfig
            mutableUserData={mutableUserData}
            setMutableUserData={setMutableUserData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserAccountConfig;
