import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//Crear complemento de pago
export const axiosStoreComplentBillFacturaApi = async (bill) => {
  let url = `${API_HOST}/api/bills/createComplementInvoiceClient`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
    bill: bill,
  };

  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
