import { Avatar, Button, Collapse, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Radio, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { axiosGetUserPaymentData } from '../../../api/axiosConekta';
import Visa from '../../../img/visa.png';
import Mastercard from '../../../img/mastercard.png';
import DialogAddCard from '../../Card/DialogAddCard';

const SelectCardPayments = ({ setSelectedPayment, selectedPayment, handleChange, setLoading, loading }) => {
    const [payments, setPayments] = useState([]);
    const [openAddCard, setOpenAddCard] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        handleGetCard();
    }, []);

    // Refresh info Cards
    const handleGetCard = () => {

        axiosGetUserPaymentData()
            .then(response => {
                setLoading(false);
                if (response?.data.status === true) {

                    if (response.data.conekta_info.total > 0) {

                        let processedPayments = response.data.conekta_info.data
                            .filter(payment => payment.type.includes("card"));

                        if (processedPayments.length > 0) {
                            setPayments(processedPayments);
                            processedPayments.filter(item => item.default === true).map(payment => {
                                setSelectedPayment(payment.id);
                            })
                        }
                    }
                }
            }).catch(err => {
                if (err?.response?.status === 500) {
                    setLoading(false);
                    toast.error("Error al consultar los datos de conekta.");
                }
            });
    };

    const handleOpenAddCard = () => {
        setOpenAddCard(!openAddCard);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <>
            {loading ?
                <Skeleton /> :
                payments.length !== 0 &&
                payments.filter((card, index) => index <= 1).map(card => (
                    <ListItem key={card.id}>
                        <ListItemAvatar>
                            {card.brand === "visa" ?
                                (<Avatar alt="Tarjeta" src={Visa} />)
                                : (<Avatar alt="Tarjeta" src={Mastercard} />)
                            }
                        </ListItemAvatar>
                        <ListItemText
                            primary={card.brand.charAt(0).toUpperCase() + card.brand.slice(1)}
                            secondary={"**** " + card.last4}
                        />

                        <ListItemSecondaryAction>
                            <Radio
                                checked={selectedPayment === card.id}
                                onChange={handleChange}
                                value={card.id}
                                name="radio-button-type-pay"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))
            }

            {loading ?
                <Skeleton /> :
                payments.length >= 3 &&
                <>
                    <Typography
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        variant="body2"
                        color="text.secondary"
                        align='center'>
                        Seleccionar otra Tarjeta
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        {payments.filter((card, index) => index >= 2).map((card) => (
                            <ListItem key={card.id}>

                                <ListItemAvatar>
                                    {card.brand === "visa" ? (
                                        <Avatar alt="Tarjeta" src={Visa} />
                                    ) : (
                                        <Avatar alt="Tarjeta" src={Mastercard} />
                                    )
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={card.brand.charAt(0).toUpperCase() + card.brand.slice(1)}
                                    secondary={"**** " + card.last4}
                                />

                                <ListItemSecondaryAction>
                                    <Radio
                                        checked={selectedPayment === card.id}
                                        onChange={handleChange}
                                        value={card.id}
                                        name="radio-button-type-pay"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </Collapse>
                </>
            }

            <Button variant="contained" color="primary" fullWidth
                onClick={handleOpenAddCard}>
                Nueva Tarjeta
            </Button>
            <DialogAddCard
                setOpen={setOpenAddCard}
                open={openAddCard}
                handleGetCard={handleGetCard}
            />
        </>
    )
}
export default SelectCardPayments