
import React from 'react'
import Particles from "react-tsparticles";

// import { loadBigCirclesPreset } from "tsparticles-preset-big-circles";

export default function TParticles() {

  return (
    <div>
      <Particles
        options={{
          backgroundMode: {
            enable: true,
            zIndex: -1
          },
          background: {
            color: "#233B63"
          },
          fpsLimit: 300,
          interactivity: {
            detectsOn: "window",
            events: {
              onClick: { enable: true, mode: "push" },
              resize: true
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.3,
                opacity: 1,
                size: 4,
                speed: 1
              },
              attract: {
                distance: 200,
                duration: 0.4,
                easing: "ease-out-quad",
                factor: 1,
                maxSpeed: 50,
                speed: 1
              },
              bounce: {
                distance: 200
              },
              grab: { distance: 400, line_linked: { opacity: 0.5 } },
              push: { particles_nb: 4 },
            }
          },
          particles: {
            color: {
              value: "#ffff",
              animation: {
                h: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  sync: true,
                },
                s: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  sync: true,

                },
                l: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  sync: true,

                },
              },
            },
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
            },
            collisions: {
              bounce: {
                horizontal: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
                vertical: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
              },
              enable: false,
              mode: "bounce",
              overlap: {
                enable: true,
                retries: 0,

              },
            },
            life: {
              count: 0,
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,

                },
                value: 0,
                sync: false,
              },
              duration: {
                random: {
                  enable: false,
                  minimumValue: 0.0001,
                },
                value: 0,
                sync: false,
              },
            },

            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.4,
              frequency: 1,
              triangles: {
                enable: false,
                frequency: 1,
              },
              width: 1,
              warp: false,
            },
            move: {
              angle: {
                offset: 0,
                value: 90,
              },
              attract: {
                enable: false, distance: 200,
                rotateX: 600, rotateY: 1200, decay: 0,
              },
              direction: "none",
              drift: 0,
              enable: true,
              outMode: "out",
              random: false,
              size: false,
              straight: false,
              speed	:	5,

              gravity: {
                acceleration: 15.81,
                enable: false,
                inverse: false,
                maxSpeed: 90,
              },
              outModes: {
                default: "out",
                bottom: "out",
                left: "out",
                right: "out",
                top: "out",
              }

            },
            number: { 
              density: { enable: true, area: 800, factor	:	1000}, 
            value: 60 },
            opacity: {
              random: true,
              value: 0.5
            },
            shape: {
              type: "circle"
            },
            size: {
              random: true,
              value: {
                min: 2,
                max: 5,

              }
            }
          },
          detectRetina: true
        }}
      />
    </div>
  );
}