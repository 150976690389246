import React, { useState, useEffect } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,

    Box, Divider, Grid, Button, Skeleton
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { toast } from 'react-toastify';
import { axiosPending } from '../../api/axiosPending';
import { isTotallyEmpty } from '../../utils/validations';
import { Link } from 'react-router-dom';

const PendingsDashboard = () => {
    const [pending, setPending] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        axiosPending()
            .then(res => {
                setPending(res.data.data);
                setLoading(false);
            }).catch(err => {
                toast.error('Ha ocurrido un problema al obtener tus pendientes.');
                // setLoading(false);
            });

    }, []);

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent={'center'} bgcolor={'#233B63'} color={'#fff'}>
                <h2>Ayudanos a completar tu información y comienza a facturar </h2>
            </Box>
            <Divider />
            {loading ? (
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            ) : (pending?.principal === 1 ? (

                isTotallyEmpty(pending?.has_cer_file) ||
                    isTotallyEmpty(pending.has_key_file) ||
                    isTotallyEmpty(pending?.legal_check) ||
                    isTotallyEmpty(pending?.has_avatar) ||
                    isTotallyEmpty(pending?.bells) ||
                    isTotallyEmpty(pending?.clients) ||
                    isTotallyEmpty(pending?.products) ? (
                    <>
                        {(isTotallyEmpty(pending.has_cer_file) || isTotallyEmpty(pending.has_key_file)) &&
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header" >
                                    <Typography variant="h5">Certificado de Sello Digital (CSD) </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes Subir la clave y los archivos .cer y .key del Certificado de Sello Digital (CSD)  para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography component={Link}
                                                    to="/config"
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.legal_check) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="h5">Información Fiscal</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes Actualizar y verificar que tú información y domicilio fiscal es el correcto, para poder facturar.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography component={Link}
                                                    to="/config"
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.has_avatar) &&
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header">
                                    <Typography variant="h5">Personalización de cuenta</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes Subir el logotipo de tu empresa para que sea colocado en el PDF y en los correos que se envían al cliente.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography component={Link}
                                                    to={"/config"}
                                                    state={{ tab: 0 }}
                                                    fontWeight="500"

                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.active) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Plan</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Aun no cuentas con una suscripción activa, es necesario relizar el pago para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    component={Link}
                                                     to={"/shop"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.bells) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Timbres</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes contar con por lo menos un timbre para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    // component={Link}
                                                    // to={"/config"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.clients) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Clientes</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes contar con por lo menos un Cliente para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    component={Link}
                                                    to={"/clients"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.products) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Productos y Servicios</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes contar con por lo menos un Producto o Servicio para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    component={Link}
                                                    to={"/products-services"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </>
                ) : (
                    <Accordion >
                        <AccordionDetails>
                            <Grid container justifyContent='center'>
                                <Typography variant="h4" margin={3} >
                                    Todo en orden Disfruta de nuestros servicios
                                </Typography>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                )) : (
                isTotallyEmpty(pending?.files_csd) ||
                    isTotallyEmpty(pending?.legal_check) ||
                    isTotallyEmpty(pending?.has_avatar) ||
                    isTotallyEmpty(pending?.bells) ||
                    isTotallyEmpty(pending?.clients) ||
                    isTotallyEmpty(pending?.products)? (
                    <>
                        {isTotallyEmpty(pending?.files_csd) &&
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header" >
                                    <Typography variant="h5">Certificado de Sello Digital (CSD) </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes Subir la clave y los archivos .cer y .key del Certificado de Sello Digital (CSD)  para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography component={Link}
                                                    to="/business"
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.legal_check) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="h5">Información Fiscal</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes Actualizar y verificar que tú información y domicilio fiscal es el correcto, para poder facturar.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography component={Link}
                                                    to="/business"
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.has_avatar) &&
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header">
                                    <Typography variant="h5">Personalización de cuenta</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes Subir el logotipo de tu empresa para que sea colocado en el PDF y en los correos que se envían al cliente.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography component={Link}
                                                    to={"/business"}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                         {isTotallyEmpty(pending?.clients) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Clientes</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes contar con por lo menos un Cliente para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    component={Link}
                                                    to={"/clients"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.products) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Productos y Servicios</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes contar con por lo menos un Producto o Servicio para poder facturar.
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    component={Link}
                                                    to={"/products-services"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {isTotallyEmpty(pending?.bells) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header" >
                                    <Typography variant="h5">Plan y Timbres</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                Debes contar con por lo menos un timbre para poder facturar.
                                            
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                <Typography
                                                    // component={Link}
                                                    // to={"/config"}
                                                    // state={{ tab: 0 }}
                                                    fontWeight="500"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                    }}>
                                                    Ir a ello
                                                </Typography>
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </>
                ) : (

                    <Accordion >
                        <AccordionDetails>
                            <Grid container justifyContent='center'>
                                <Typography variant="h4" margin={3} >
                                    Todo en orden Disfruta de nuestros servicios
                                </Typography>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                ))

            )}

        </>
    )
}

export default PendingsDashboard