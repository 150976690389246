import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";

//Validate RFC
export const axiosValidateRFC = async (rfc) => {
  const url = `${API_HOST}/api/client/validateRfc/${rfc}`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };
  let response = await axios.post(url, data, headersConfig);
  return response;
};
//Crear cliente
export const axiosCreateClientFacturaApi = async (client) => {
  let url = `${API_HOST}/api/client/create`;
  const key = localStorage.getItem("api_key");

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };

  let data = {
    key: key,
    client: client,
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
//Buscar y listar clientes
export const axiosGetClientsFacturaApi = async (page = 1, search = "") => {
  const key = localStorage.getItem("api_key");

  let url = `${API_HOST}/api/client/show/${page}`;
  search.length === 0
    ? (url = `${API_HOST}/api/client/show/${page}`)
    : (url = `${API_HOST}/api/client/show/${page}/${search}`);

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };
  let response = await axios.post(url, data, headersConfig);

  return response;
};
//Buscar cliente por id
export const axiosShowClientIDFacturaApi = async (id) => {
  let url = `${API_HOST}/api/client/showId/${id}`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };

  let response = await axios.post(url, data, headersConfig);

  return response;
};
//Editar Cliente
export const axiosEditClientFacturaApi = async (client) => {
  const key = localStorage.getItem("api_key");
  let url = `${API_HOST}/api/client/edit`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
    client: client,
  };
  let response = await axios.post(url, data, headersConfig);

  return response;
};
//eliminar cliente
export const axiosDeleteClientFacturaApi = async (id) => {
  let url = `${API_HOST}/api/client/delete/${id}`;
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios.post(url, data, headersConfig);

  return response;
};
//buscar y listar clientes
export const axiosSearchClientsFacturaApi = async (search) => {
  let url = `${API_HOST}/api/client/show`;
  search.length === 0
    ? (url = `${API_HOST}/api/client/show`)
    : (url = `${API_HOST}/api/client/search/${search}`);
  const key = localStorage.getItem("api_key");
  let data = {
    key: key,
  };
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let response = await axios
    .post(url, data, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
//mostrar todos los clientes
export const axiosAllClientsFacturaApi = async (page = 1, search = "") => {
  const key = localStorage.getItem("api_key");
  let url = `${API_HOST}/api/client/show-all-clients/${page}`;
  search.length === 0
    ? (url = `${API_HOST}/api/client/show-all-clients/${page}`)
    : (url = `${API_HOST}/api/client/show-all-clients/${page}/${search}`);

  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };
  let response = await axios.post(url, data, headersConfig);

  return response;
};
