import React, { useState, useEffect } from 'react'
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Typography
} from '@mui/material'
import { toast } from 'react-toastify';
import { axiosGetInfoFiscal } from '../../../api/fiscal';
import { axiosGetRegimes } from '../../../api/regimes';

const ListReceptorDataBill = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [regimes, setRegimes] = useState([]);

    // ComponentDidMount
    useEffect(() => {

        axiosGetRegimes()
            .then(response => {
                setRegimes(response.data.data);
            })
            .catch(err => {
                return err;
            });
    }, []);

    useEffect(() => {

        getFiscalData();

    }, []);

    const getFiscalData = () => {
        axiosGetInfoFiscal()
            .then(response => {
                let { data } = response;
                if (data.status) {
                    toast.success(data.message);

                    let legal = data.data.legal;
                    setData({
                        ...data,
                        legal_name: legal.legal_name ? legal.legal_name : '',
                        tax_id: legal.tax_id ? legal.tax_id : '',
                        tax_system: legal.tax_system ? legal.tax_system : '',
                        zip: legal.address.zip ? legal.address.zip : '',
                        street: legal.address.street ? legal.address.street : '',
                        exterior: legal.address.exterior ? legal.address.exterior : '',
                        support_email: legal.support_email ? legal.support_email : '',
                        phone: legal.phone ? legal.phone : '',
                        website: legal.website ? legal.website : '',
                    });
                    setLoading(false);
                } else {
                    toast.warning(response.data.message);

                }
            })
            .catch(err => {
                toast.error(err.response.data.message);
                return err;
            });
    };
    return (
        <>
            <Typography variant="h6" fontWeight="600" marginBottom={'1%'} marginTop={'2%'}>
                Información Fiscal del Emisor
            </Typography>
            <Divider style={{ marginBottom: "10px" }} />

            <List>

                <ListItem>
                    <Grid container>
                        <Grid item xs={12} md={2}>
                            <ListItemText
                                primary="RFC"
                                secondary={loading ? <Skeleton variant="text" sx={{ mr: 2 }} /> : data.tax_id}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ListItemText
                                primary="Razón Social"
                                secondary={loading ? <Skeleton variant="text" sx={{ mr: 2 }} /> : data.legal_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}  >

                            <ListItemText
                                primary="Regimen"
                                secondary={loading ? <Skeleton variant="text" sx={{ mr: 2 }} /> :
                                    Object.values(regimes?.filter(option =>
                                        (option.code === data.tax_system)).map(regime =>
                                            regime.code + ' - ' + regime.regimen))}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ListItemText
                                primary="Codigo postal"
                                secondary={loading ? <Skeleton variant="text" sx={{ mr: 2 }} /> : data.zip}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ListItemText
                                primary="Calle"
                                secondary={loading ? <Skeleton variant="text" sx={{ mr: 2 }} /> : data.street}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ListItemText
                                primary="No. Exterior"
                                secondary={loading ? <Skeleton variant="text" sx={{ mr: 2 }} /> : data.exterior}
                            />
                        </Grid>
                    </Grid>
                </ListItem>
            </List>

        </>
    )
}

export default ListReceptorDataBill