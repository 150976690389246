import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CardNewPaymentConfig from "./PaymentConfig/CardNewPaymentConfig";
import CardListPaymentConfig from "./PaymentConfig/CardListPaymentConfig";
import { axiosGetUserPaymentData } from "../../api/axiosConekta";
import { toast } from "react-toastify";
const PaymentConfig = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    handleGetCard();
  }, []);
  // Refresh info Cards
  const handleGetCard = () => {
    axiosGetUserPaymentData()
      .then((response) => {
        if (response?.data.status === true) {
          if (response.data.conekta_info.total > 0) {
            let processedPayments = response.data.conekta_info.data.filter(
              (payment) => payment.type.includes("card")
            );
            setPayments(processedPayments);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 401) {
          toast.error("No se ha iniciado una sesión o registro.");
        }
        if (err?.response?.status === 500) {
          setLoading(false);
          toast.error("No se logro obtener el listado de metodos de pago");
        }
      });
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <CardNewPaymentConfig handleGetCard={handleGetCard} />
        </Grid>
        <Grid item xs={12} sx={{ m: 2 }}>
          <CardListPaymentConfig
            payments={payments}
            loading={loading}
            handleGetCard={handleGetCard}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentConfig;
