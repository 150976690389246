import React, { useState } from 'react'
import {
    Typography,
    Grid,
    TextField,
    IconButton,
    Button,
    InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { isTotallyEmpty } from "../../utils/validations";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from '@mui/lab';
import { axiosCSDCompanyFacturaApi } from '../../api/axiosCompany';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { formatDateLetter } from '../../utils/formats';

const useStyles = makeStyles(() => ({

    fileInput: {
        display: 'none',
    },

}));

const ArchiveCSDBusiness = ({ businessEdit, setBusinessEdit, handleChange, loadingArchive, setLoadingArchive }) => {
    const classes = useStyles();
    const [activeViewEdit, setActiveViewEdit] = useState(false);

    const [show, setShow] = useState({
        stamp_pass: "",
        showStamp_pass: false
    });

    const handleClickShow = pwd => {

        setShow({ ...show, showStamp_pass: !show.showStamp_pass });

    };

    const handleMouseDown = event => {
        event.preventDefault();
    };
    const handlefiles = e => {
        let files = e.target.files[0];
        if (!isTotallyEmpty(files)) {
            setBusinessEdit({ ...businessEdit, [e.target.name]: files });
        }

    }
    const handleAddCSD = () => {
        setLoadingArchive(true);
        axiosCSDCompanyFacturaApi(businessEdit.password_csd, businessEdit.cer_file, businessEdit.key_file, businessEdit.id_facturapi)
            .then(response => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        setLoadingArchive(false);
                        setBusinessEdit({...businessEdit, files_csd: 1 });
                    }, 10);
                } else {
                    toast.warning(response.data.message);
                    setLoadingArchive(false);
                }
            })
            .catch(err => {

                if (err.response.status === 401) {
                    toast.warning(err.response.data.message);
                } else {
                    toast.warning("Error en el servidor");
                }

                setLoadingArchive(false);
                return err;
            });
    };

    const handleViewEdit = () => {
        setActiveViewEdit(!activeViewEdit);
    }
    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} xs={12}>

                    <Typography variant="h6" fontWeight="600" marginBottom={"5%"}>
                        CSD (Sellos Digitales)
                    </Typography>

                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="input-with-icon-textfield"
                        name="password_csd"
                        label="Contraseña de Sello Digital"
                        variant="filled"
                        helperText="Clave para manipulacion de facturas"
                        value={businessEdit?.password_csd}
                        type={
                            show.showStamp_pass ? "text" : "password"
                        }
                        onChange={handleChange("password_csd")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle stamp_pass visibility"
                                        onClick={() =>
                                            handleClickShow(false)}
                                        onMouseDown={handleMouseDown} edge="end" >
                                        {show.showStamp_pass ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {
                    businessEdit.files_csd === 1 && activeViewEdit === false
                        ? (
                            <>
                                <Grid container item xs={12} sm={12} md={12} xl={12} justifyContent="center">

                                    <Typography variant="h6" fontWeight="600" >
                                        Sellos Digitales cargados
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} justifyContent="center">

                                    <AdminPanelSettingsIcon color='info'
                                        sx={{ width: '6em', height: '6em' }} />
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} xl={12} justifyContent="center">
                                    <Typography color="primary">
                                        Fecha de expiración:
                                    </Typography>
                                    <Typography color="primary">
                                        {' ' + formatDateLetter(businessEdit.expire_csd)}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} xl={12} justifyContent="center">

                                    <Button variant='contained' onClick={() => handleViewEdit()}>
                                        Editar CSD
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid container item xs={12} sm={12} md={12} xl={12} justifyContent="center" sx={{ m: '32px 2px' }}>
                                    <input
                                        accept=".cer"
                                        className={classes.fileInput}
                                        id="contained-button-file-cer"
                                        name="cer_file"
                                        type="file"
                                        onChange={e => handlefiles(e)}
                                    />
                                    <label htmlFor="contained-button-file-cer">
                                        <Button
                                            variant="outlined"
                                            color={!businessEdit?.cer_file?.name ? ("primary") : ("secondary")}
                                            component="span"
                                            sx={{ mr: 2, overflow: 'hidden', maxWidth: '543px' }}
                                        >
                                            {!businessEdit?.cer_file?.name ? (
                                                "ARCHIVO CER"
                                            ) : (
                                                businessEdit?.cer_file?.name
                                            )}
                                        </Button>
                                        <label htmlFor="" >
                                            <Typography color="primary"
                                                sx={{ display: "block" }}>
                                                Cargar Archivo .cer
                                            </Typography>
                                        </label>
                                    </label>
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} xl={12} justifyContent="center" sx={{ m: '15px 2px' }}>
                                    <input
                                        accept=".key"
                                        className={classes.fileInput}
                                        id="contained-button-file-key"
                                        name="key_file"
                                        type="file"
                                        onChange={e => handlefiles(e)}
                                    />
                                    <label htmlFor="contained-button-file-key">
                                        <Button
                                            variant="outlined"
                                            color={!businessEdit?.key_file?.name ? ("primary") : ("secondary")}
                                            component="span"

                                            sx={{ mr: 2, overflow: 'hidden', maxWidth: '543px' }}
                                        >
                                            {!businessEdit?.key_file?.name ? (
                                                "ARCHIVO KEY"
                                            ) : (
                                                businessEdit?.key_file?.name
                                            )}
                                        </Button>
                                        <label htmlFor="">

                                            <Typography color="primary"
                                                sx={{ display: "block" }}>
                                                Cargar Archivo .key
                                            </Typography>
                                        </label>
                                    </label>
                                </Grid>
                                <Grid container item xs={12} justifyContent="flex-end">

                                    <LoadingButton
                                        loading={loadingArchive}
                                        onClick={() => handleAddCSD()}
                                        disabled={businessEdit.key_file === '' || businessEdit.cer_file === '' || isTotallyEmpty(businessEdit.password_csd)}
                                        color="primary" variant='contained'>
                                        Guardar CSD
                                    </LoadingButton>
                                </Grid>
                            </>
                        )}
            </Grid>
        </>
    )
}

export default ArchiveCSDBusiness