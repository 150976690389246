import axios from "axios";
import { TOKEN } from "../utils/constant";
import { API_HOST } from "../utils/constant";

//consultar pasos pendientes para correcto uso de la plataforma
export const axiosPending = async () => {
  let url = `${API_HOST}/api/user/pending`;
  const key = localStorage.getItem("api_key");
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  let data = {
    key: key,
  };
  let response = await axios.post(url, data, headersConfig);
  return response;
};
