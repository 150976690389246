import { API_HOST, TOKEN } from "../utils/constant";
import axios from "axios";
// Si no se hace una función se estanca y envia un JWT pasado
// Si se hace aqui como variable, se estanca con el valor de ese momento
// MUY IMPORTANTE, NO OLVIDAR!

//consultar claves de unidad
export const axiosGetUnitCodes = async (search) => {
  let url = `${API_HOST}/api/unit-codes`;
  search.length === 0
    ? (url = `${API_HOST}/api/unit-codes`)
    : (url = `${API_HOST}/api/unit-codes/${search}`);
  // let url = `${API_HOST}/api/tools/unitCode`;
  // let url = `${API_HOST}/api/unit-codes/${search}`;
  const headersConfig = {
    headers: {
      Authorization: localStorage.getItem(TOKEN),
    },
  };
  // getting axios response on variable
  let response = await axios
    .get(url, headersConfig)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return response;
};
