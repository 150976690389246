import React from "react";
import { Box, Typography } from "@mui/material";
const Footer = () => {
  return (
    <Box sx={{ p: 2, textAlign: "center" }}>
      <Typography>
        © 2022 Todos los derechos reservados por facturalite.com.mx{" "}
      </Typography>
    </Box>
  );
};

export default Footer;
