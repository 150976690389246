import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  useMediaQuery,
  Typography,
  Link,
} from "@mui/material";
import { formatDateLetter } from "../../utils/formats";
import { axiosGetRegimes } from "../../api/regimes";
import { useTheme } from "@emotion/react";
import paymentModes from "../../utils/payment_modes.json";
import { axiosGetCfdiUses } from "../../api/cfdiUses";
import { useGetLists } from "../../hooks/useGetLists";

const ViewBills = ({
  openViewModal,
  handlerBillViewModal,
  billInfo,
  viewLoading,
}) => {
  const [regimes, setRegimes] = useState([]);
  const [cfdiUses, setCfdiUses] = useState([]);
  const { getCfdi, getRegimes } = useGetLists();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  // ComponentDidMount
  useEffect(() => {
    getRegimes(setRegimes);
    getCfdi(setCfdiUses);
  }, []);

  return (
    <Dialog
      open={openViewModal}
      onClose={handlerBillViewModal}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      fullWidth={true}
      maxWidth={"mg"}
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={6}>
            {`Visualización de Factura ${billInfo?.folio_number} - ${billInfo?.series}`}
          </Grid>
          <Grid item xs={3} container justifyContent={"flex-end"}>
            <Grid item xs={6}>
              <Typography variant="h5" component="div" sx={{ mr: 2 }}>
                {viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  "$ " + billInfo.total
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                sx={{
                  backgroundColor:
                    billInfo.status === "valid"
                      ? billInfo.cancellation_status === "pending"
                        ? billInfo.cancellation_status === "rejected"
                          ? billInfo.cancellation_status === "expired"
                            ? (theme) => theme.palette.warning.light
                            : (theme) => theme.palette.warning.light
                          : (theme) => theme.palette.secondary.light
                        : (theme) => theme.palette.success.light
                      : (theme) => theme.palette.error.light,
                  color: "#ffff",
                  borderRadius: "8px",
                  pl: "3px",
                  pr: "3px",
                }}
                size="medium"
                label={
                  billInfo.status === "valid"
                    ? billInfo.cancellation_status === "pending"
                      ? billInfo.cancellation_status === "rejected"
                        ? billInfo.cancellation_status === "expired"
                          ? "Expirada"
                          : "Cancelación rechazada"
                        : "Pendiente"
                      : "Timbrada"
                    : "Cancelada"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider textAlign="center" sx={{ mt: 2 }}>
          <Chip label="Comprobante" />
        </Divider>
        <ListItem>
          <Grid item xs={12} lg={3}>
            <ListItemText
              primary="Folio Fiscal"
              secondary={
                viewLoading ? <Skeleton variant="text" /> : billInfo?.uuid
              }
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ListItemText
              primary="Fecha de creación"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  formatDateLetter(billInfo?.created_at)
                )
              }
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ListItemText
              primary="Num. certificado"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  billInfo?.stamp?.sat_cert_number
                )
              }
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <ListItemText
              primary="Fecha de certificación"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  formatDateLetter(billInfo?.stamp?.date)
                )
              }
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={12}>
            <ListItemText
              primary="Url de Verificación"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  <Link href={billInfo.verification_url} target="_blank">
                    {billInfo.verification_url}
                  </Link>
                )
              }
              sx={{ overflow: "auto" }}
            />
          </Grid>
        </ListItem>
      </DialogTitle>

      <DialogContent>
        <List>
          <Divider textAlign="center">
            <Chip label="Receptor" />
          </Divider>
          <ListItem>
            <ListItemText
              primary="RFC"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  billInfo.customer?.tax_id
                )
              }
            />
            <ListItemText
              primary="Razón Social"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  billInfo.customer?.legal_name
                )
              }
            />
            <ListItemText
              primary="Régimen Fiscal"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  Object.values(
                    regimes
                      ?.filter(
                        (option) =>
                          option.code === billInfo.customer?.tax_system
                      )
                      .map((regime) => regime.code + " - " + regime.regimen)
                  )
                )
              }
            />
          </ListItem>

          <Divider textAlign="center">
            <Chip label="Conceptos" />
          </Divider>
          {billInfo?.items?.map((bill, index) => (
            <ListItem key={index}>
              <ListItemText
                primary="Clave Prod Serv"
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    bill.product?.product_key
                  )
                }
              />
              <ListItemText
                primary="SKU"
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    bill?.product?.sku
                  )
                }
              />
              <ListItemText
                primary="Concepto"
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    bill?.product?.description
                  )
                }
              />
              <ListItemText
                primary="Cant."
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    bill?.quantity
                  )
                }
              />
              <ListItemText
                primary="Unidad"
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    bill?.product?.unit_key
                  )
                }
              />
              <ListItemText
                primary="Precio Unitario"
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    bill?.product?.price
                  )
                }
              />
            </ListItem>
          ))}
          <Divider textAlign="center">
            <Chip label="Pago" />
          </Divider>
          <ListItem>
            {billInfo.payment_form && (
              <ListItemText
                primary="Forma de pago"
                secondary={
                  viewLoading ? (
                    <Skeleton variant="text" sx={{ mr: 2 }} />
                  ) : (
                    paymentModes
                      .filter((option) => option.code === billInfo.payment_form)
                      .map((payment) => payment.code + " - " + payment.name)
                  )
                }
              />
            )}
            <ListItemText
              primary="Método de pago"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : billInfo.payment_method === "PUE" ? (
                  "PUE (Pago en Una sola Exhibición)"
                ) : (
                  "PPD (Pago en Parcialidades o Diferido) "
                )
              }
            />
            <ListItemText
              primary="Uso de CFDI"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  cfdiUses
                    .filter((option) => option.code === billInfo.use)
                    .map((use) => use.code + " - " + use.name)
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Tipo"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : billInfo.type === "I" ? (
                  "Ingreso"
                ) : billInfo.type === "P" ? (
                  "Complemento"
                ) : (
                  "Egreso"
                )
              }
            />

            <ListItemText
              primary="Estatus"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : billInfo.status === "valid" ? (
                  "Valida"
                ) : (
                  "Cancelada"
                )
              }
            />
            <ListItemText
              primary="Estatus de Cancelación"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : billInfo.cancellation_status === "none" ? (
                  "Sin cancelar"
                ) : billInfo.cancellation_status === "pending" ? (
                  "Pendiente"
                ) : billInfo.cancellation_status === "rejected" ? (
                  "Rechazada"
                ) : (
                  "Aceptada"
                )
              }
            />
            <ListItemText
              primary="Total"
              secondary={
                viewLoading ? (
                  <Skeleton variant="text" sx={{ mr: 2 }} />
                ) : (
                  "$ " + billInfo.total
                )
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerBillViewModal} autoFocus>
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewBills;
