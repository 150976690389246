import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Grid,
  DialogActions,
  DialogContent,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import {
  months,
  registerYear,
  CardCustom,
  CvvCustom,
  expiration,
} from "../../../utils/formats";
import { toast } from "react-toastify";
import conektaHelper from "./../../../utils/conektaHelper";
import { size } from "lodash";
import { AuthContext } from "../../../auth/AuthContext";
import { axiosGetPlans } from "../../../api/userInfo";
import { Payment } from "@mui/icons-material";
import { axiosRegisterPaymentCard } from "../../../api/axiosConekta";
import { isUserLogedApi } from "../../../api/auth";

const CardPayRegister = ({ handleClickPaySelect, handlepay }) => {
  const { setToken } = useContext(AuthContext);
  const [plans, setPlans] = useState([]);
  const [payLoading, setPayLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = false;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    axiosGetPlans()
      .then((response) => {
        setPlans(response.data.data);
      })
      .catch((err) => {
        toast.error("Error al cargar Planes", err);
      });
  }, []);

  const [formConekta, setFormConekta] = useState({
    cardHolder: "",
    cardNumber: "",
    cardCvv: "",
    cardMonth: "",
    cardYear: "",
    plan: "",
  });

  const successResponseHandler = async (token) => {
    axiosRegisterPaymentCard(token, formConekta.plan)
      .then((response) => {
        if (response.data.status === false) {
          toast.warning(response.data.message);
          setPayLoading(false);
        } else {
          toast.success("Metodo de pago y suscripción agregado correctamente.");
          setTimeout(() => {
            setFormConekta({
              cardHolder: "",
              cardNumber: "",
              cardCvv: "",
              cardMonth: "",
              cardYear: "",
              plan: "",
            });

            setToken(isUserLogedApi());
            setPayLoading(false);
          }, 10);
        }
      })
      .catch((err) => {
        toast.error("Error en el servidor");
        setPayLoading(false);
        return err;
      });
  };

  const errorResponseHandler = (error) => {
    toast.error(error.message_to_purchaser);
    setPayLoading(false);
    return error;
  };

  const onSubmit = (e) => {
    conektaHelper.initConekta();
    e.preventDefault();

    if (size(formConekta.cardNumber) < 16) {
      toast("Ingresa una tarjeta valida", { icon: "⚠️" });
    } else {
      setPayLoading(true);
      let req = conektaHelper.tokenize(
        formConekta,
        successResponseHandler,
        errorResponseHandler
      );
    }
  };

  const onChange = (e) => {
    setFormConekta({ ...formConekta, [e.target.name]: e.target.value });
  };

  return (
    <>
      <DialogContent sx={{ p: 2 }}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <TextField
              id="filled-select-currency"
              select
              label="Selecciona Un Plan"
              value={formConekta.plan}
              onChange={onChange}
              name="plan"
              variant="filled"
              fullWidth
              required
              size="small"
            >
              {plans.map((option) => (
                <MenuItem key={option.conekta_name} value={option.id}>
                  {option.name} - ${option.price}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="cardHolder"
              label="Nombre del Tarjetahabiente"
              variant="filled"
              value={formConekta.cardHolder}
              data-conekta="card[name]"
              size="small"
              onChange={onChange}
              margin="normal"
              autoComplete="off"
              error={
                formConekta.cardHolder !== ""
                  ? !conektaHelper.validateName(formConekta.cardHolder)
                  : false
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="cardNumber"
              label="Número de Tarjeta"
              variant="filled"
              data-conekta="card[number]"
              value={formConekta.cardNumber}
              size="small"
              onChange={onChange}
              margin="normal"
              autoComplete="off"
              error={
                formConekta.cardNumber !== ""
                  ? !conektaHelper.validateCardNumber(formConekta.cardNumber)
                  : false
              }
              InputProps={{
                inputComponent: CardCustom,
                endAdornment: (
                  <InputAdornment position="end">
                    <Payment />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                select
                name="cardMonth"
                label="Mes de expiración"
                variant="filled"
                size="small"
                data-conekta="card[exp_month]"
                margin="normal"
                value={formConekta.cardMonth}
                error={
                  formConekta.cardMonth !== ""
                    ? !conektaHelper.validateMonth(formConekta.cardMonth)
                    : false
                }
                onChange={onChange}
              >
                {months.map((option) => (
                  <MenuItem key={option.id} value={option.month}>
                    {option.month}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                select
                name="cardYear"
                label="Año de expiración"
                size="small"
                variant="filled"
                data-conekta="card[exp_year]"
                margin="normal"
                value={formConekta.cardYear}
                InputProps={{
                  inputComponent: expiration,
                }}
                onChange={onChange}
                error={
                  formConekta.cardYear !== ""
                    ? !conektaHelper.validateYear(formConekta.cardYear)
                    : false
                }
              >
                {registerYear().map((option) => (
                  <MenuItem key={option.index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                name="cardCvv"
                label="cvv"
                variant="filled"
                size="small"
                autoComplete="off"
                margin="normal"
                data-conekta="card[cvc]"
                value={formConekta.cardCvv}
                onChange={onChange}
                InputProps={{
                  inputComponent: CvvCustom,
                }}
                error={
                  formConekta.cardCvv !== ""
                    ? !conektaHelper.validateCvc(formConekta.cardCvv)
                    : false
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Box sx={{ position: "relative", textAlign: "center" }}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Button
              id="Aceptar"
              variant="contained"
              color="primary"
              type="submit"
              onClick={onSubmit}
              sx={{ m: 3, mb: 1 }}
              disabled={
                formConekta.cardHolder === "" ||
                formConekta.cardNumber === "" ||
                formConekta.cardCvv === "" ||
                formConekta.cardMonth === "" ||
                formConekta.cardYear === "" ||
                formConekta.plan === "" ||
                payLoading
              }
            >
              {!payLoading ? (
                "Continuar"
              ) : (
                <CircularProgress color="secondary" />
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Button onClick={() => handleClickPaySelect()}>
              Elegir otro metodo de pago
            </Button>
          </Grid>
        </Box>

        {/* <Grid item xs={12} sm={12} md={12} xl={12}>
          <Button onClick={() => handlepay()}>
            BasicData
          </Button>
        </Grid> */}
      </DialogActions>
    </>
  );
};

export default CardPayRegister;
