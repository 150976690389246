import React from 'react'
import DialogReportBills from '../Bills/DialogReportBills'
import {
    Grid,
} from '@mui/material';

const GridSelectReports = () => {


    return (
        <Grid item container justifyContent='space-evenly' alignItems='center'  >
            <Grid item xs={3}>
                <DialogReportBills />
            </Grid>

        </Grid>
    )
}

export default GridSelectReports