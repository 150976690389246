import React from 'react'
import {
  Card,
  Chip,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import 'animate.css';

const CardShop = ({ plan }) => {
  return (

    <Card sx={{
      textAlign: 'center', overflow: 'unset', position: 'relative',
      backgroundColor: '#f4f4f4',
      height: '350px', display: 'flex', flexDirection: 'column', m: 2
    }}
      className="animate__animated animate__backInUp">
      <Chip
        sx={{
          bgcolor: (theme) => theme.palette.warning.main,
          mt: -2,
          color: '#fff',
          fontSize: '14px',
          display: plan.badge,
          maxWidth: '140px',
          position: 'absolute',
          left: '0',
          right: '0',
          ml: 'auto',
          mr: 'auto',
        }}
        label="Popular" />
      <Typography variant="h5" sx={{ mt: 3, color: (theme) => theme.palette.primary.main, }} >
        {plan?.name}
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ mt: 3, fontSize: '40px', fontWeight: '600', color: (theme) => theme.palette.primary.dark }}>
          <Typography variant="h5" sx={{   }} >
          <sup style={{ fontSize: '15px', color: (theme) => theme.palette.primary.main, }}>$</sup>
            {plan?.price}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '15px', color: 'GrayText', fontWeight: '400', ml: 1 }}>
          Pago Con Tarjeta
        </Typography>
        {/* <Box sx={{ mt: 3, fontSize: '20px', fontWeight: '500' }}>
          <sup style={{ fontSize: '15px' }}>$</sup>
          {plan?.oxxo_price}
        </Box>
        <Typography sx={{ fontSize: '14px', color: 'grey.A200', fontWeight: '400', ml: 1 }}>
          Pago con Oxxo
        </Typography> */}
      </Box>
      <Box sx={{ mt: 2, }}>
        <List>
          {plan.beneficios?.map((benefi, index) => (

            <ListItem sx={{ p: 0, pt: 1 }} key={index}>
              <ListItemText sx={{ textAlign: 'center' }} primary={benefi} />
            </ListItem>
          ))}
        </List>
      </Box>
      {/* {plan?.restriccion &&
        <Typography
          sx={{ fontSize: '14px', color: 'grey.A200', fontWeight: '400', ml: 1, mt: '-14px' }}>
          {plan?.restriccion}
        </Typography>
      } */}
      {/* <Button
        variant="contained"
        size={plan.btnsize}
        sx={{ width: '100%', mb: 2, background: plan.btncolor, marginTop: 'auto' }}
      // onClick={() => {
      //   handleOpenPay(plan);
      // }} 
      >
        Comprar
      </Button> */}

    </Card>

  )
}

export default CardShop